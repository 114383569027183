import React from 'react'

function SkillTagCard({ name }) {
  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  return (
    <div className="flex items-center justify-center gap-[9.016px] sm:gap-[11.203px] px-[13.5px] sm:px-[16.8px] py-[4.508px] sm:py-[5.6px] bg-[#1E3E85] rounded-[7.498px]">
      <p className="text-white text-start font-inter text-[10.819px] sm:text-[13.444px] not-italic font-semibold leading-[18.031px] sm:leading-[22.406px] whitespace-nowrap">
        {name}
      </p>
    </div>
  )
}

export default SkillTagCard
