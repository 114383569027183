//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

//custom components
import CandidateModal from 'views/employer/candidates/components/CandidateModal'

export default function CandidateDialog({
  isOpen,
  closeModal,
  data,
  handleInvite,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-full sm:max-w-[90%] md:max-w-[790px] h-[80vh] sm:h-auto rounded-2xl transform overflow-visible text-left align-middle transition-all bg-white">
              <CandidateModal
                closeModal={closeModal}
                data={data}
                handleInvite={handleInvite}
              />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
