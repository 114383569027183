//packages
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

//custom components
import Button from 'components/Button'

//assets
import EmptyMarkPng from 'assets/images/empty-status-candidates.png'

//redux & api
import { getUser } from 'app/store/profileSlice'
import Dropdown from 'components/dropdown/Dropdown'
import { PAGINATION_LIST } from 'app/utils/list'

export default function EmptyScreen({
  tab,
  sort,
  numberPerPage,
  setNumberPerPage,
  sortData,
  setSort,
}) {
  const profile = useSelector(getUser)
  const navigate = useNavigate()
  const navigateToFindCandidatesPage = () => {
    navigate(`/${profile.type}/find-candidates`)
  }
  return (
    <div className="pb-[68px] pt-0">
      <div className="flex gap-5 mt-0 mb-[32px] min-w-full justify-between ">
        <div className="flex items-center space-x-3">
          <div className="text-[#808191] font-semibold text-sm">View</div>
          <div className="w-[70px]">
            <Dropdown
              data={PAGINATION_LIST}
              label=""
              value={numberPerPage}
              onChange={(data) => {
                setNumberPerPage(data)
              }}
              className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
              parentClassName="w-full"
              labelClassName="text-[#1E3E85] font-semibold text-sm"
            />
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div className="text-[#808191] font-semibold text-sm">Sort by</div>
          <div className="w-[100px]">
            <Dropdown
              data={sortData}
              value={sort}
              className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
              parentClassName="w-full"
              labelClassName="text-[#1E3E85] font-semibold text-sm"
              onChange={setSort}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <img src={EmptyMarkPng} />
      </div>
      <h6 className="text-[#808191] text-[20px] font-inter leading-[24px] text-center mt-[50px]">
        {`You have no ${sort.name !== 'All' ? sort.name : ''} ${tab.id}`}
      </h6>
      <div className="flex justify-center mt-[58px]">
        <Button
          variant="primary"
          className="px-[79px] py-[18px]"
          onClick={navigateToFindCandidatesPage}
        >
          Find Candidates
        </Button>
      </div>
    </div>
  )
}
