import React from 'react'
import VideoCard from './VideoCard'
import TextCard from './TextCard'
import whyRRCard from 'assets/img/why.png'
import TalentFromEveryCornerCard from './TalentFromEveryCornerCard'
import InversedTextCard from './InversedTextCard'
import TheRightFitTextCard from './TheRightFitTextCard'

// Images
import HomeImageLoyid from 'assets/home/images/loyid.png'
import HomeImageTanveer from 'assets/home/images/tanveer.png'
import HomeImageJulius from 'assets/home/images/julius.png'
import HomeImageHellen from 'assets/home/images/hellen.png'
import HomeImageSerena from 'assets/home/images/serena.png'
import HomeImageLeul from 'assets/home/images/leul.png'
import HomeImageHazel from 'assets/home/images/hazel.png'
import HomeImagePablo from 'assets/home/images/pablo.png'
import HomeImageAna from 'assets/home/images/ana.png'

// Videos
import HomeVideoLoyid from 'assets/home/videos/loyid-optimised.mp4'
import HomeVideoTanveer from 'assets/home/videos/tanveer-optimised.mp4'
import HomeVideoJulius from 'assets/home/videos/julius-optimised.mp4'
import HomeVideoHellen from 'assets/home/videos/hellen-optimised.mp4'
import HomeVideoSerena from 'assets/home/videos/serena-optimised.mp4'
import HomeVideoLeul from 'assets/home/videos/leul-optimised.mp4'
import HomeVideoHazel from 'assets/home/videos/hazel-optimised.mp4'
import HomeVideoPablo from 'assets/home/videos/pablo-optimised.mp4'
import HomeVideoAna from 'assets/home/videos/ana-optimised.mp4'

export default function HeroSection() {
  return (
    <div className="flex items-center justify-center gap-[9.37px] relative top-0 h-auto mx-6 mb-[62.87px] sm:mb-[67px] mt-[0px] z-0 overflow-hidden">
      <div className="">
        <VideoCard
          img={HomeImageLoyid}
          name="Loyid"
          job="Sales Dev Rep"
          location="Ethiopia"
          videoId="1"
          vid={HomeVideoLoyid}
        />
        <TextCard
          upperText="- Hiring Now"
          lowerText="Without Borders"
          color="#FFC628"
          upperTextColor="#503047"
        />
        <VideoCard
          img={HomeImageTanveer}
          name="Tanveer"
          job="Sales Field"
          location="India"
          videoId="2"
          vid={HomeVideoTanveer}
        />
      </div>
      <div>
        <TheRightFitTextCard
          color="#A755FF"
          lowerText="Find It"
          upperText="- Wherever talent is,"
        />
        <VideoCard
          img={HomeImageJulius}
          name="Julius"
          job="IOS Developer"
          location="Kenya"
          videoId="3"
          vid={HomeVideoJulius}
        />
        <TalentFromEveryCornerCard
          color="#503047"
          lowerText="Talent from Every Corner"
        />
      </div>
      <div>
        <VideoCard
          img={HomeImageHellen}
          name="Hellen"
          job="Customer Service"
          location="Costa Rica"
          videoId="4"
          vid={HomeVideoHellen}
        />
        <InversedTextCard
          color="#FF8E47"
          lowerText="Skip Boring Resumes"
          upperText="Show Them"
          upperTextColor="#FFF6FA"
        />
        <VideoCard
          img={HomeImageSerena}
          name="Serena"
          job="Sales"
          location="U.S.A"
          videoId="5"
          vid={HomeVideoSerena}
        />
      </div>
      <div>
        <TextCard
          upperText="- Watch Candidates"
          lowerText="Pre-Screen Candidates"
          color="#003BC5"
          upperTextColor="#D3C1CE"
        />
        <VideoCard
          img={HomeImageLeul}
          name="Leul"
          job="Engineer"
          location="Ethiopia"
          videoId="6"
          vid={HomeVideoLeul}
        />
        <TextCard
          upperText="- With Remote Recruit"
          lowerText={
            <>
              Talent is <br /> Talent
            </>
          }
          color="#F05673"
          upperTextColor="#802265"
        />
      </div>
      <div>
        <VideoCard
          img={HomeImageHazel}
          name="Hazel"
          job="Sales Prof"
          location="Zimbabwe"
          videoId="8"
          vid={HomeVideoHazel}
        />
        <VideoCard
          img="https://remoterecruit-public.b-cdn.net/images/pabloImg.png"
          name="Pablo"
          job="Film Maker"
          location="Spain"
          videoId="7"
          vid={HomeVideoPablo}
        />
        <div className="mt-[9.89px]">
          <img src={whyRRCard} alt="Why Remote Recruit Card" />
        </div>
      </div>
      <div>
        <TextCard
          upperText="- Global Talent"
          lowerText={
            <>
              Global <br /> Impact
            </>
          }
          color="#EC7B34"
          upperTextColor="#D3C1CE"
        />
        <VideoCard
          img={HomeImageAna}
          name="Ana"
          job="Cold Calling"
          location="Bangladesh"
          videoId="9"
          vid={HomeVideoAna}
        />
        <TextCard
          upperText="- Breaking barriers"
          lowerText={
            <>
              Building <br /> Careers
            </>
          }
          color="#A04EF8"
          upperTextColor="#503047"
        />
      </div>
    </div>
  )
}
