import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//custom components
import ProgressBar from 'components/ProgressBar'
import { fetchChatsNo, getChatsNo } from 'app/store/profileSlice'

export default function GetPremiumCard({ getPremium, user }) {
  const dispatch = useDispatch()
  const chatsNo = useSelector(getChatsNo)

  const fetchChatsLength = useCallback(() => {
    const param = {
      userID: user.id,
    }
    dispatch(fetchChatsNo(param))
  }, [])

  useEffect(() => {
    if (user) {
      fetchChatsLength()
    }
  }, [user])

  const inviteLimitNo = user?.inviteLimitNo ? user.inviteLimitNo : 0
  return (
    <div className="bg-[url('assets/images/premium-card-mobile.png')] md:bg-[url('assets/images/premium-card-bg2.png')] pt-[20px] md:pt-5 px-[30px] md:px-7.5 pb-[30px] md:pb-7 bg-cover rounded-2xl w-full">
      <h5 className="text-[20px] md:text-2xl leading-[30px] md:leading-9 font-semibold text-white">
        Basic
      </h5>
      <div className="mt-[10px] md:mt-4 space-y-[4px] md:space-y-4">
        <h6 className="font-inter font-bold leading-[24px] md:leading-6 text-[14px] md:text-sm text-white">
          Free Messages
        </h6>
        <div className="w-full flex items-center justify-between space-x-[13px]">
          <ProgressBar
            className="w-full md:w-30"
            percent={((chatsNo > 5 ? 5 : chatsNo) / 5) * 100}
          />
          <div className="font-inter leading-[17px] md:leading-4 text-[14px] md:text-sm text-white font-bold">
            {chatsNo > 5 ? 5 : chatsNo}/5
          </div>
        </div>
      </div>
      <div className="mt-[10px] md:mt-4 space-y-[4px] md:space-y-4">
        <h6 className="font-inter font-bold leading-[24px] md:leading-6 text-[14px] md:text-sm text-white">
          Free Invites
        </h6>
        <div className="w-full flex items-center justify-between space-x-[13px]">
          <ProgressBar
            className="w-full md:w-30"
            percent={(inviteLimitNo / 5) * 100}
          />
          <div className="font-inter leading-[17px] md:leading-4 text-[14px] md:text-sm text-white font-bold">
            {inviteLimitNo}/5
          </div>
        </div>
      </div>
      <button
        onClick={getPremium}
        className="bg-yellow-200 font-inter mt-[30px] md:mt-5 w-full font-bold py-[11px] md:py-3 rounded-2xl leading-[20px] md:leading-5 text-black-100 text-[14px] md:text-sm"
      >
        Upgrade
      </button>
    </div>
  )
}
