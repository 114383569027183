import React from 'react'

function SuggestedTag({ name }) {
  return (
    <div className="flex justify-center items-center gap-[3.68px] py-[4.42px] pl-[8.83px] pr-[7.36px] rounded-[7.374px] bg-[#bbbbbbba] ">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
        >
          <path
            d="M3.53241 1.22192V6.61097M0.837891 3.91645H6.22693"
            stroke="white"
            strokeWidth="0.769863"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="text-white text-center font-inter text-[8.833px] not-italic font-semibold leading-[14.721px]">
        {name}
      </div>
    </div>
  )
}

export default SuggestedTag
