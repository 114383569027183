import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createChat } from 'app/services/chat.service'

export const createUserChat = createAsyncThunk(
  'chats/createChat',
  async (data) => {
    const response = await createChat(data)
    return response.data
  }
)

const initialState = {
  userChats: [],
}

const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createUserChat.fulfilled, (state, action) => {
      state.userChats = [...state.userChats, action.payload]
    })

    //Clear data after user logout
    builder.addCase('auth/LogOut', (state) => {
      state.userChats = []
    })
  },
})

export const {} = chatSlice.actions

export default chatSlice.reducer
