//packages
import React, { useCallback, useEffect, useState } from 'react'
import { redirect, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FadeLoader } from 'react-spinners'
import { useFormik } from 'formik'
import * as yup from 'yup'

//custom components
import { AnimateSpinIcon } from 'components/Icons'
import AuthService from 'app/services/auth.service'
import AuthCard from 'components/Card/AuthCard'
import {
  VerifyIcon,
  CircleCheckedIcon,
  Right,
  PencilIcon,
} from 'components/Icons'
//redux-api
import { getProfile, setProfile } from 'app/store/profileSlice'
import { createUser } from 'app/services/chat.service'
import TextField from 'components/Input/TextField'
import TickIcon from 'components/Icons/TickIcon'
import { Helmet } from 'react-helmet'

export default function Verification() {
  const [loading, setLoading] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const [verified, setVerified] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let [searchParams] = useSearchParams()
  const userId = searchParams.get('userId')
  const userIdVerify = localStorage.getItem('verifId')
  const initialRegEmail = localStorage.getItem('regEmail')

  const signUpData = JSON.parse(localStorage.getItem('user'))
  // console.log('signupdata', signUpData)

  const initialValues = {
    resetEmail: initialRegEmail,
  }

  const autoLogin = async () => {
    let result = await getProfile(userId)
    const user = result.data.user
    if (!localStorage.getItem('user')) {
      const params = {
        username: user.id,
        secret: user.id,
        first_name: user.type,
        last_name: user.name,
        custom_json: JSON.stringify({ image: user.avatar }),
      }
      createUser(params)
    }
    localStorage.setItem('user', JSON.stringify(result.data.user))
    dispatch(setProfile(result.data.user))
    if (result.data.user.type === 'contractor') {
      navigate(`/auth/employee/register`)
    } else {
      navigate(`/auth/employer/register`)
    }
  }

  const validationSchema = [
    yup.object().shape({
      resetEmail: yup
        .string()
        .email('Enter a valid email address')
        .required('You must enter your email'),
    }),
  ]

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema[0],
    onSubmit: (values) => {
      console.log(values)
      handleResend(values.resetEmail)
    },
  })

  const backgroundAutoLogin = async () => {
    let result = await getProfile(userIdVerify)
    localStorage.setItem('user', JSON.stringify(result.data.user))
    dispatch(setProfile(result.data.user))
    if (result.data.user.type === 'contractor') {
      navigate(`/auth/employee/register`)
    } else {
      navigate(`/auth/employer/register`)
    }
  }

  useEffect(() => {
    if (!signUpData) {
      return redirect('/auth/signup')
    }
  }, [signUpData])

  useEffect(() => {
    if (verified) {
      autoLogin()
    }
  }, [verified])

  useEffect(() => {
    let isMounted = true

    // Periodically check verification status
    const intervalId = setInterval(async () => {
      if (!isMounted) {
        clearInterval(intervalId) // Clear the interval if the component is unmounted
        return
      }

      // Make an API request to check verification status
      try {
        const result = await getProfile(userIdVerify)
        const isUserVerified = result.data.user.verified

        if (isUserVerified) {
          backgroundAutoLogin()
        }

        console.log('Im checking verification status', isUserVerified)
      } catch (error) {
        // Handle errors
        console.log(error)
      }
    }, 3000) // Check every 3 seconds

    return () => {
      isMounted = false // Set isMounted to false when unmounting
      clearInterval(intervalId) // Clear the interval when unmounting
    }
  }, [userIdVerify])

  useEffect(() => {
    const token = searchParams.get('token')
    if (userId && token) {
      setLoading(true)
      AuthService.verify({ token: token, id: userId })
        .then((res) => {
          toast.success('Verified successfully.')
          setVerified(true)
          localStorage.setItem('token', res.token)
        })
        .catch((err) => {
          console.log('debug: err', err)
          toast.error('Verify failed.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [searchParams])

  const handleResend = (email) => {
    console.log(email)
    setLoadingResend(true)
    AuthService.resendVerifyLink({
      id: userIdVerify ? userIdVerify : userId,
      email: email,
    })
      .then((res) => {
        toast.success('Link resent successfully. Please check your email')
      })
      .catch((err) => {
        console.log(err)
        toast.error('Ooops! There is an error with resend verification link.')
      })
      .finally(() => {
        setLoadingResend(false)
      })
  }

  return (
    <>
      <Helmet>
        <title>Verify Your Remote Recruit Account - Remoterecruit.com</title>
        <meta
          name="description"
          content="Complete your account verification to start utilizing all features of Remote Recruit."
        />
      </Helmet>

      <AuthCard className="w-116 p-[48px]">
        {verified ? (
          <div>
            <div className="flex items-center space-x-4">
              <VerifyIcon />
              <h3 className="text-black-100 -tracking-[1px] leading-[36px] md:leading-13 text-[24px] md:text-m4xl font-semibold">
                Verified
              </h3>
            </div>

            <p className="opacity-80 leading-[18px] md:leading-5 text-[12px] md:text-[16px] text-gray-300 md:text-black-100 mt-[10px] md:mt-5">
              You have successfully verified your account.
            </p>
            <p className="opacity-80 leading-[18px] md:leading-5 text-[12px] md:text-[16px] text-gray-300 md:text-black-100 mt-[4px] md:mt-2">
              We are now logging you in
            </p>
            <div className="flex justify-center mt-[72px] mb-[50px]">
              <FadeLoader color="#52B4DA" />
            </div>
          </div>
        ) : (
          <div>
            <h3 className="text-black-100 -tracking-[1px] leading-[36px] md:leading-13 text-[24px] md:text-m4xl font-semibold">
              Thank you!
            </h3>
            {!userId && (
              <p className="opacity-80 leading-[18px] md:leading-5 text-[12px] md:text-[16px] text-gray-300 md:text-black-100 mt-[10px] md:mt-5">
                Check your email and click the link to verify your account and
                finish your profile.
              </p>
            )}

            <div>
              {loading ? (
                <p className="text-black-100 md:text-black-100 opacity-80 leading-[16px] md:leading-5 text-[14px] md:text-[16px] mt-[40px]">
                  we are logging you in, please don't close browser
                </p>
              ) : (
                <p className="text-black-100 md:text-black-100 opacity-80 leading-[16px] md:leading-5 text-[14px] md:text-[16px] mt-[40px] mb-[20px]">
                  We sent a verification message to the email address below. If
                  you haven't received it, check spam, make sure the email below
                  is correct, or resend. Feel free to edit the email address by
                  clicking on the pencil icon.
                </p>
              )}
              <div className="relative">
                <TextField
                  name="resetEmail"
                  id="resetEmail"
                  placeholder="email@yourdomain.com"
                  type="email"
                  disabled={disabled}
                  onChange={formik.handleChange}
                  value={formik.values.resetEmail}
                  error={
                    formik.touched['resetEmail'] && formik.touched['resetEmail']
                      ? formik.errors['resetEmail']
                      : ''
                  }
                />
                <div
                  className="absolute top-[15px] right-[15px] cursor-pointer"
                  onClick={() => setDisabled(!disabled)}
                >
                  {!disabled ? <TickIcon /> : <PencilIcon />}
                </div>
              </div>
              <button
                disabled={loadingResend}
                type="submit"
                onClick={formik.handleSubmit}
                className="bg-blue-100 flex justify-center rounded-2xl text-[14px] leading-5 text-center text-white font-bold w-full py-4.5 mt-8 disabled:opacity-50"
              >
                Resend
                {loadingResend && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
              </button>
            </div>
          </div>
        )}
      </AuthCard>
    </>
  )
}
