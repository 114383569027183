import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { CrossIcon } from 'components/Icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  upload: {
    border: '2px solid #1E3E85',
    borderRadius: '20px',
  },
}))

export default function UploadButtons({
  handleUploadChange,
  cancelUpload,
  fileName,
  disabled,
}) {
  const classes = useStyles()
  return (
    <div>
      {/* <div class="text-gray-300 leading-[16px] text-[12px] md:text-xs font-medium mb-3">
        *Resume should be on pdf, doc, or docx format only
      </div> */}
      <div className="px-[15px] md:px-8 max-w-full flex justify-between border-[2px] border-solid border-blue-100 rounded-2xl h-[56px] md:h-[56px] items-center">
        {fileName ? (
          <div className="flex-1 flex items-center max-w-[calc(100%-160px)] md:w-auto">
            <div className="whitespace-nowrap truncate flex-1">{fileName}</div>
            <div onClick={cancelUpload}>
              <CrossIcon className="text-black-100 cursor-pointer" />
            </div>
          </div>
        ) : (
          <div className="flex items-center font-medium leading-[16px] text-black-100 opacity-50 text-[12px] md:text-sm">
            Upload your resume here
          </div>
        )}
        <input
          className={classes.input}
          id="contained-button-file"
          type="file"
          disabled={disabled}
          onChange={handleUploadChange}
          name="resume"
          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
        />
        <label htmlFor="contained-button-file">
          {fileName ? (
            <div className="w-[80px] md:w-[124px] cursor-pointer flex justify-center bg-green-100 rounded-2xl leading-5 text-sm font-bold text-white py-[10px]">
              Change
            </div>
          ) : (
            <div className="cursor-pointer w-[80px] md:w-[124px] flex justify-center bg-green-100 rounded-2xl leading-5 text-sm font-bold text-white py-[10px] disabled:opacity-50">
              Upload
            </div>
          )}
        </label>
      </div>
    </div>
  )
}
