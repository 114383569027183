//packages
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import StripePaymentForm from 'components/Forms/StripePaymentForm'
import PaymentForm from 'components/Forms/PaypalPaymentForm'
import { toast } from 'react-toastify'

//custom components
import Checkbox from 'components/Checkbox'

//api & redux
import { getPaypalToken, getClientSecret } from 'app/services/auth.service'
import { getUser, updateEmployerPlan } from 'app/store/profileSlice'

//assets
import Paypal from 'assets/img/Paypal.png'
import ActiveIcon from 'assets/images/active-icon.png'
import InActiveIcon from 'assets/images/inactive-icon-free-card.png'
import clsx from 'clsx'

export default function Upgrade() {
  const navigate = useNavigate()
  const [method, setMethod] = useState('stripe')
  const [clientToken, setClientToken] = useState(null)
  const [stripeClientToken, setStripeClientToken] = useState('')

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  useEffect(() => {
    const fetchStripeClientToken = async () => {
      let result = await getClientSecret()
      setStripeClientToken(result?.data?.paymentIntent?.client_secret)
    }
    fetchStripeClientToken()
  }, [])

  const fetchClientToken = useCallback(async () => {
    let response = await getPaypalToken()
    setClientToken(response.data.client_token)
  }, [setClientToken])

  useEffect(() => {
    fetchClientToken()
  }, [fetchClientToken])

  const onPaymentComplete = async () => {
    try {
      const param = {
        userId: profile.id,
        premium: true,
      }
      const resultAction = await dispatch(updateEmployerPlan(param))
      if (updateEmployerPlan.fulfilled.match(resultAction)) {
        toast.success('Upgraded Account Successfully')
      } else {
        if (resultAction.payload) {
          toast.error(
            'Upgrade account failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Upgrade account failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      toast.error('There is error when upgrading your account.')
    }
  }

  return (
    <div className="md:overflow-auto md:h-screen w-full pb-[70px] md:pb-[139px]">
      <div className="w-full max-w-4xl mx-auto">
        <div className="text-black-100 py-12 mx-[15px] md:mx-auto max-w-[1075px]">
          <h1 className="text-[24px] md:text-[48px] font-semibold font-poppins">
            Upgrade Account
          </h1>
        </div>
        <div className="flex justify-center mx-[15px] md:mx-auto max-w-[1075px]">
          <div className="bg-white rounded-3xl shadow-setting md:min-w-[694px]">
            <div className="relative py-10 px-16">
              <h3 className="md:text-center text-black-100 text-[18px] md:text-2xl font-medium leading-[24px] md:leading-9 mb-14">
                You are going to upgrade your current subscription to a Premium
                plan.
              </h3>
              <div className="grid md:grid-cols-2 gap-x-[5px] gap-y-[20px] h-full">
                <div className="">
                  <div className="rounded-2xl pt-5 px-8 h-full border-[2px] border-gray-400 border-solid pb-12">
                    <div className="membership-option">
                      <h4 className="tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold text-blue-100">
                        BASIC
                      </h4>
                      <h5 className="leading-[19px] text-[16px] font-bold md:mt-[9px] text-green-100">
                        Free
                      </h5>
                      <ul
                        className={clsx(
                          'space-y-5 md:space-y-3 py-4 mt-[20px] md:mt-4 mb-[24px] md:mb-0',
                          method === 'basic' ? 'text-white' : 'text-[#11142D]'
                        )}
                      >
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="15"
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                                fill="#1E3E85"
                              />
                            </svg>
                            <span>1 Active Job</span>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="15"
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                                fill="#1E3E85"
                              />
                            </svg>
                            <span>Basic Placement</span>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-start gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[19px] h-[15px] flex-shrink-0 "
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                                fill="#1E3E85"
                              />
                            </svg>
                            <span>Up to 24-hour delay to job posts</span>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                          <div className="flex items-start gap-1">
                            {' '}
                            {/* Use `items-start` to align items at the top */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[19px] h-[15px] flex-shrink-0 "
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                                fill="#1E3E85"
                              />
                            </svg>
                            <div className="flex-grow">
                              {' '}
                              {/* Allow text to grow and wrap naturally */}
                              View first 25 applicants that have applied to your
                              job
                            </div>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                          <div className="flex items-start gap-1">
                            {' '}
                            {/* Use `items-start` to align items at the top */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[19px] h-[15px] flex-shrink-0 "
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                                fill="#1E3E85"
                              />
                            </svg>
                            <div className="flex-grow">
                              {' '}
                              {/* Allow text to grow and wrap naturally */}
                              Send 5 free messages to job seekers
                            </div>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                          <div className="flex items-start gap-1">
                            {' '}
                            {/* Use `items-start` to align items at the top */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[19px] h-[15px] flex-shrink-0 "
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                                fill="#1E3E85"
                              />
                            </svg>
                            <div className="flex-grow">
                              {' '}
                              {/* Allow text to grow and wrap naturally */}
                              Send up to 5 invites to job seekers
                            </div>
                          </div>
                        </li>
                        {/* <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M14.1437 1.98348C14.177 1.79822 14.1237 1.60762 13.999 1.46701C13.7011 1.12313 13.3279 0.853218 12.9081 0.677954C12.4603 0.498681 11.9584 0.51068 11.5199 0.711942C10.315 1.26774 8.89157 2.24139 7.50011 3.45955C6.10861 2.24132 4.68511 1.26761 3.47824 0.71126C3.03974 0.509996 2.53792 0.497337 2.09007 0.677272C1.67156 0.852535 1.29903 1.12245 1.00113 1.46433C0.876508 1.60628 0.823188 1.79687 0.856514 1.98215C0.890502 2.17275 1.01046 2.33735 1.18106 2.428C2.74384 3.25303 4.24395 4.19206 5.66885 5.23634C3.07052 8.02933 1.359 11.1376 0.847864 14.0052H0.848531C0.834536 14.0838 0.87852 14.1611 0.95316 14.1904C0.972487 14.1964 0.99248 14.1997 1.01314 14.2004C1.07112 14.2004 1.1251 14.1704 1.15442 14.1204C2.85849 11.3194 5.00108 8.80964 7.50011 6.68713C9.99915 8.80964 12.1418 11.3187 13.8458 14.1198C13.8751 14.1697 13.9291 14.2004 13.9871 14.1997C14.0077 14.1997 14.0277 14.1964 14.0471 14.1897C14.1217 14.1611 14.1657 14.0838 14.1517 14.0051C13.6399 11.1369 11.9299 8.02932 9.33071 5.23633L9.33138 5.23566C10.7569 4.19136 12.2564 3.25305 13.8192 2.428C13.9898 2.33736 14.1097 2.17342 14.1437 1.98348Z"
                                fill="#C83F3F"
                              />
                            </svg>
                            <span>Ai Interviewer</span>
                          </div>
                        </li> */}
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M14.1437 1.98348C14.177 1.79822 14.1237 1.60762 13.999 1.46701C13.7011 1.12313 13.3279 0.853218 12.9081 0.677954C12.4603 0.498681 11.9584 0.51068 11.5199 0.711942C10.315 1.26774 8.89157 2.24139 7.50011 3.45955C6.10861 2.24132 4.68511 1.26761 3.47824 0.71126C3.03974 0.509996 2.53792 0.497337 2.09007 0.677272C1.67156 0.852535 1.29903 1.12245 1.00113 1.46433C0.876508 1.60628 0.823188 1.79687 0.856514 1.98215C0.890502 2.17275 1.01046 2.33735 1.18106 2.428C2.74384 3.25303 4.24395 4.19206 5.66885 5.23634C3.07052 8.02933 1.359 11.1376 0.847864 14.0052H0.848531C0.834536 14.0838 0.87852 14.1611 0.95316 14.1904C0.972487 14.1964 0.99248 14.1997 1.01314 14.2004C1.07112 14.2004 1.1251 14.1704 1.15442 14.1204C2.85849 11.3194 5.00108 8.80964 7.50011 6.68713C9.99915 8.80964 12.1418 11.3187 13.8458 14.1198C13.8751 14.1697 13.9291 14.2004 13.9871 14.1997C14.0077 14.1997 14.0277 14.1964 14.0471 14.1897C14.1217 14.1611 14.1657 14.0838 14.1517 14.0051C13.6399 11.1369 11.9299 8.02932 9.33071 5.23633L9.33138 5.23566C10.7569 4.19136 12.2564 3.25305 13.8192 2.428C13.9898 2.33736 14.1097 2.17342 14.1437 1.98348Z"
                                fill="#C83F3F"
                              />
                            </svg>
                            <span>Video Cover Letter</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cursor-pointer md:p-[8px] md:border-[4px] md:border-blue-100 rounded-2xl border-solid pb-12">
                  <div className="rounded-2xl pt-5 px-8 h-full card-gradient">
                    <div className="membership-option">
                      <h4 className="tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold uppercase text-white">
                        Premium
                      </h4>
                      <h5 className="leading-[19px] text-[16px] font-bold md:mt-[9px] text-[#96F154]">
                        $79.99/month
                      </h5>
                      <div className="rounded-[5px] bg-[#FFC700] py-[5px] mt-[5px] ml-[-10px] pl-[10px] ">
                        <ul
                          className={clsx(
                            '',
                            method === 'premium'
                              ? 'text-white'
                              : 'text-[#323445]'
                          )}
                        >
                          {/* <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                            <div className="flex items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                              >
                                <path
                                  d="M5.88184 9.87441C7.99343 6.37189 13.7838 2.30408 16.7529 0.200195L17.6 0.761011C13.7881 3.98577 8.98786 7.91151 7.71721 12.2579C6.87013 12.5383 5.03472 13.5198 4.75237 13.8002C3.76409 11.6971 1.8206 8.72171 0.400002 7.86727C3.08359 4.71231 5.06928 8.22623 5.88184 9.87441Z"
                                  fill="#11142D"
                                />
                              </svg>
                              <span className="text-[#11142D]">
                                Ai Interviewer
                              </span>
                            </div>
                          </li> */}
                          <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                            <div className="flex items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                              >
                                <path
                                  d="M5.88184 9.87441C7.99343 6.37189 13.7838 2.30408 16.7529 0.200195L17.6 0.761011C13.7881 3.98577 8.98786 7.91151 7.71721 12.2579C6.87013 12.5383 5.03472 13.5198 4.75237 13.8002C3.76409 11.6971 1.8206 8.72171 0.400002 7.86727C3.08359 4.71231 5.06928 8.22623 5.88184 9.87441Z"
                                  fill="#11142D"
                                />
                              </svg>
                              <span className="text-[#11142D]">
                                Video Cover Letter
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <ul
                        className={clsx(
                          'space-y-4 py-0 mt-[20px] md:mt-4 mb-[22px] md:mb-0 text-white'
                        )}
                      >
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                                fill="#FFC628"
                              />
                            </svg>
                            <span>25 active jobs</span>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                                fill="#FFC628"
                              />
                            </svg>
                            <span>Premium placement</span>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                          <div className="flex items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                                fill="#FFC628"
                              />
                            </svg>
                            <span>Instant job posting</span>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                          <div className="flex items-start gap-1">
                            {/* Use `items-start` to align items at the top */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[18px] h-[14px] flex-shrink-0" /* Prevent SVG from shrinking */
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                                fill="#FFC628"
                              />
                            </svg>
                            <div className="flex-grow">
                              {' '}
                              {/* Allow text to grow and wrap naturally */}
                              View all applicants that have applied to your job
                            </div>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                          <div className="flex items-start gap-1">
                            {/* Use `items-start` to align items at the top */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[18px] h-[14px] flex-shrink-0" /* Prevent SVG from shrinking */
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                                fill="#FFC628"
                              />
                            </svg>
                            <div className="flex-grow">
                              {' '}
                              {/* Allow text to grow and wrap naturally */}
                              Unlimited Messages to job seekers
                            </div>
                          </div>
                        </li>
                        <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                          <div className="flex items-start gap-1">
                            {/* Use `items-start` to align items at the top */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-[18px] h-[14px] flex-shrink-0" /* Prevent SVG from shrinking */
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                            >
                              <path
                                d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                                fill="#FFC628"
                              />
                            </svg>
                            <div className="flex-grow">
                              {' '}
                              {/* Allow text to grow and wrap naturally */}
                              Unlimited invites to jobseekers
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[40px] md:mt-12">
                <p className="text-[14px] md:text-sm leading-[16px] md:leading-4 text-black-100 font-semibold">
                  Payment Method
                </p>
                <div className="flex space-x-[17px] mt-[20px] md:mt-8">
                  <div
                    onClick={() => {
                      setMethod((prev) => (prev === 'stripe' ? '' : 'stripe'))
                    }}
                    className="flex-1 md:flex-none border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl"
                  >
                    <Checkbox
                      onChange={(e) => {
                        // e.stopPropagation()
                        // setMethod((prev) =>
                        //   prev === 'stripe' ? '' : 'stripe'
                        // )
                      }}
                      value={method === 'stripe'}
                      label={
                        <div className="text-black-100 text-[12px] md:text-base font-medium md:mt-1">
                          Credit Card
                        </div>
                      }
                    />
                  </div>
                  <div
                    onClick={() => {
                      setMethod((prev) => (prev === 'paypal' ? '' : 'paypal'))
                    }}
                    className="flex-1 md:flex-none border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl"
                  >
                    <Checkbox
                      onChange={(e) => {
                        // e.stopPropagation()
                        // setMethod((prev) =>
                        //   prev === 'paypal' ? '' : 'paypal'
                        // )
                      }}
                      value={method === 'paypal'}
                      label={
                        <img
                          alt="paypal"
                          className="w-[71px] md:w-auto"
                          src={Paypal}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  {method === 'paypal' && (
                    <PaymentForm
                      clientToken={clientToken}
                      clientID={process.env.REACT_APP_PAYPAL_CLIENT_ID}
                      onPaymentComplete={onPaymentComplete}
                    />
                  )}
                  {method === 'stripe' && (
                    <StripePaymentForm
                      token={stripeClientToken}
                      onSuccessPaid={onPaymentComplete}
                      onBack={() => navigate('/employer/profile')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
