import UserServiceClient from 'app/clients/userService'
import axios from 'axios'
const API_URL = `${process.env.REACT_APP_BASE_URL}/api/`

const getUserById = (userId) => {
  return UserServiceClient.get(`/user/${userId}`)
}

const getAllUsers = () => {
  return UserServiceClient.get('/users')
}

export default {
  getUserById,
  getAllUsers,
}
