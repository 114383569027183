import React from 'react'
import { NavBar } from './components/NavBar'
import HeroSection from './components/HeroSection'
import Footer from './components/Footer'
import GetStartedSection from './components/GetStartedSection'
import Client from './components/Client'
import EarthSection from './components/EarthSection'
import CategorySection from './components/CategorySection'
import CarauselSection from './components/CarauselSection'
import { SearchSection } from './components/SearchSection'
import SearchResults from './components/SearchResults'
import { Helmet } from 'react-helmet'

function LatestHomePage({
  loading,
  searchKeywords,
  setSearchKeywords,
  suggestedTags,
  setSuggestedTags,
  filter,
  setFilter,
  numberPerPage,
  setNumberPerPage,
  currentPage,
  candidates,
  candidatesCount,
  query,
  setQuery,
  key,
  setKey,
  tagMapping,
  setTagMapping,
  handleChange,
  filteredValues,
  handleRemoveKeyword,
  onPageChange,
}) {
  return (
    <div className="bg-[#00091D] overflow-hidden">
      <Helmet>
        <title>
          Remote Recruit | Global Recruitment & Video-Based Hiring for Remote Jobs
        </title>
        <meta
          name="description"
          content="Discover Remote Recruit, the leading global recruitment platform that transforms hiring with video-based recruitment. Connect with top employers and talent for remote jobs, making job search and hiring more engaging and efficient."
        />
      </Helmet>
      {searchKeywords.length !== 0 ? (
        <>
          {!loading && candidates.length === 0 ? (
            <>
              <NavBar />
              <div className="flex justify-center items-center gap-[8.026px] absolute h-[570px] max-w-full top-[-218px] sm:top-[-148px] opacity-[0.2] overflow-hidden">
                <HeroSection />
              </div>
              <div
                className="hidden sm:block absolute w-3/4 h-[361px] sm:w-[1106px] sm:h-[942.706px] rounded-[1106px] bg-[#002477] ml-[28px] sm:ml-[62px] mr-auto"
                style={{ filter: 'blur(82.432px)' }}
              ></div>
              <SearchSection
                searchKeywords={searchKeywords}
                setSearchKeywords={setSearchKeywords}
                setFilter={setFilter}
                filter={filter}
                handleChange={handleChange}
                filteredValues={filteredValues}
                query={query}
                setQuery={setQuery}
                key={key}
                setKey={setKey}
                tagMapping={tagMapping}
                setTagMapping={setTagMapping}
                handleRemoveKeyword={handleRemoveKeyword}
                suggestedTags={suggestedTags}
                setSuggestedTags={setSuggestedTags}
                displayHomePageSuggestedTags={false}
              />
              <HeroSection />
              <CategorySection />
              <CarauselSection />
              <EarthSection />
              <Client />
              <GetStartedSection />
              <Footer />
            </>
          ) : (
            <SearchResults
              candidates={candidates}
              loading={loading}
              hideInviteBtn={true}
              filter={filter}
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={numberPerPage.name}
              numberPerPage={numberPerPage}
              setNumberPerPage={setNumberPerPage}
              candidatesCount={candidatesCount}
              hideResultsForText={true}
              searchKeywords={searchKeywords}
              setSearchKeywords={setSearchKeywords}
              setFilter={setFilter}
              handleChange={handleChange}
              filteredValues={filteredValues}
              query={query}
              setQuery={setQuery}
              key={key}
              setKey={setKey}
              tagMapping={tagMapping}
              setTagMapping={setTagMapping}
              handleRemoveKeyword={handleRemoveKeyword}
              suggestedTags={suggestedTags}
              setSuggestedTags={setSuggestedTags}
            />
          )}
        </>
      ) : (
        <>
          <NavBar />
          <div className="flex justify-center items-center gap-[8.026px] absolute h-[570px] w-full top-[-218px] sm:top-[-148px] opacity-[0.2]">
            <HeroSection />
          </div>
          <div
            className="hidden sm:block absolute w-3/4 h-[361px] sm:w-[1106px] sm:h-[942.706px] rounded-[1106px] bg-[#002477] ml-[28px] sm:ml-[62px] mr-auto overflow-hidden"
            style={{ filter: 'blur(82.432px)' }}
          ></div>
          <SearchSection
            searchKeywords={searchKeywords}
            setSearchKeywords={setSearchKeywords}
            setFilter={setFilter}
            filter={filter}
            handleChange={handleChange}
            filteredValues={filteredValues}
            query={query}
            setQuery={setQuery}
            key={key}
            setKey={setKey}
            tagMapping={tagMapping}
            setTagMapping={setTagMapping}
            handleRemoveKeyword={handleRemoveKeyword}
            displayHomePageSuggestedTags={true}
          />
          <HeroSection />
          <CategorySection
            searchKeywords={searchKeywords}
            setSearchKeywords={setSearchKeywords}
            setFilter={setFilter}
            filter={filter}
          />
          <CarauselSection />
          <EarthSection />
          <Client />
          <GetStartedSection />
          <Footer />
        </>
      )}
    </div>
  )
}

export default LatestHomePage
