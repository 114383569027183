import React from 'react'
import logo from 'assets/img/logo-updated.png'
import { Link } from 'react-router-dom'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon2,
  TiktokIcon,
  YoutubeIcon,
} from 'components/Icons'

function Footer() {
  return (
    <div className="relative bottom-0 w-full h-[386px] sm:h-[594px] bg-[#00113C] flex flex-col justify-center items-center">
      {/* <div className="w-full h-[153px] mb-[141px]">
        <p className="text-white text-center font-sans not-italic text-[40px] font-normal">
          Let's Connect and Make It Happen!
        </p>
        <p className="text-white text-center font-sans text-sm not-italic font-normal opacity-80 self-stretch">
          Have questions or need support? Reach out, and we’ll help you bridge
          the gap between talent and <br /> opportunity.
        </p>
        <div className="flex justify-center items-end">
          <a href="mailto:team@remoterecruit.com">
            <button className="w-[125px] h-[34px] bg-[#F7F9FB] mt-[15px] color-[#00113C] px-[5px] py-[6px] rounded-[4px] shrink-0">
              👋 Contact Us
            </button>
          </a>
        </div>
      </div> */}

      <div className="w-[320.708px] sm:w-[954.667px] border-t border-[#FFF] mt-[62.55px] sm:mt-[104px] mb-[79.6px] opacity-25"></div>

      <div className="flex flex-col sm:flex-row items-center justify-between sm:w-[1031px] sm:h-[50px] sm:mb-[78px]">
        <div>
          <Link
            href="/"
            className="flex w-[124px] h-[50px] sm:w-[79.702px] sm:h-[31.887px] items-center gap-2 mb-[36px] sm:mb-[0px]"
          >
            <img src={logo} alt="RemoteRecruit" />
          </Link>
        </div>
        <div className="text-white hover:text-white/80 text-center font-sans not-italic text-sm font-normal leading-[14px] mb-[36px] sm:mb-[0px]">
          <Link to="/privacy-policy">Privacy Policy</Link> |{' '}
          <Link to="/terms-of-service">Terms of Use</Link>
        </div>
        <div className="flex items-center gap-[36px]">
          <ul className="space-y-4">
            <li className="flex space-x-4">
              <a
                target="_blank"
                href="https://www.facebook.com/RRremoterecruitofficial/"
                className="hover:rotate-45 transition-transform duration-300 w-[39px] h-[39px] flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <FacebookIcon />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/remoterecruit/"
                className="hover:rotate-45 transition-transform duration-300 w-[39px] h-[39px] flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                href="https://x.com/remoterecruit01"
                className="hover:rotate-45 transition-transform duration-300 w-[39px] h-[39px] flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <TwitterIcon2 />
              </a>
              <a
                target="_blank"
                href="https://www.tiktok.com/@remoterecruit"
                className="hover:rotate-45 transition-transform duration-300 w-[39px] h-[39px] flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <TiktokIcon />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCBEwwmwrEa9lNWukkTXvHRw"
                className="hover:rotate-45 transition-transform duration-300 w-[39px] h-[39px] flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <YoutubeIcon />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/remoterecruit-com-official/about/"
                className="hover:rotate-45 transition-transform duration-300 w-[39px] h-[39px] flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <LinkedinIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="text-white text-center font-sans text-sm not-italic leading-[14px] font-normal mt-[25px] sm:mt-[0px]">
        © {new Date().getFullYear()} Remote Recruit All Rights Reserved.
      </p>
    </div>
  )
}

export default Footer
