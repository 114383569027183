import getUser from 'app/services/getUser'
import { useEffect, useState } from 'react'

export const useFetchMessageRecipientUser = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null)
  let recipientId
  try {
    if (chat) {
      recipientId = chat?.participants?.find((id) => id !== user?.id)
    }
  } catch (err) {
    console.log('Error is the foolllllll', err)
  }

  useEffect(async () => {
    if (recipientId) {
      await getUser.getUserById(recipientId).then((response) => {
        setRecipientUser(response.data)
      })
    }
  }, [recipientId])

  return { recipientUser }
}
