//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { useDispatch } from 'react-redux'
//custom components
import TextField from 'components/Input/TextField'
import Button from 'components/Button'
import { AnimateSpinIcon } from 'components/Icons'

//assets
import { useSelector } from 'react-redux'
import { getUser, setUserEmail } from 'app/store/profileSlice'

import { sendEmailCode, verifyEmailCode } from 'app/services/user.service'
import { checkEmailExists } from 'app/services/auth.service'

import EnterCode from 'components/Input/EnterCode'

const schema = yup.object().shape({
  newEmail: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
})

export default function UpdateEmailModal({ isOpen, closeModal }) {
  const [status, setStatus] = useState('send') // --> verify status
  const [verifying, setIsVerifying] = useState(false)
  const [code, setCode] = useState('')
  const profile = useSelector(getUser)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      newEmail: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values) => {
    formik.setSubmitting(true)
    try {
      const data = {
        email: values.newEmail,
      }

      const result = await checkEmailExists(data)
      if (result.data.existence) {
        toast.error('This email has already been registered!')
      } else {
        await sendEmailCode({ userId: profile.id, data })

        setStatus('verify')
      }
    } catch (err) {
      toast.error('There is error when sending email code. Please try again')
    } finally {
      formik.setSubmitting(false)
    }
  }

  const verifyCode = async () => {
    try {
      setIsVerifying(true)
      const data = {
        email: formik.values.newEmail,
        code,
      }
      const result = await verifyEmailCode({ userId: profile.id, data })
      if (result.status === 200) {
        dispatch(setUserEmail(formik.values.newEmail))
        onClose()
      }
    } catch (err) {
      console.log('debug: err', err)
      toast.error('Code is incorrect')
    } finally {
      setIsVerifying(false)
    }
  }

  const onClose = () => {
    formik.resetForm()
    setCode('')
    setStatus('send')
    closeModal()
  }

  // console.log('debug: status', status)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#18326A] bg-opacity-90">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[420px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white rounded-2xl pt-[40px] px-[40px] pb-[50px] space-y-5">
                  {status === 'send' ? (
                    <h3 className="text-black-100 text-[24px] font-semibold text-center mt-2.5">
                      Update Email
                    </h3>
                  ) : (
                    <h3 className="text-black-100 text-[24px] font-semibold text-center mt-2.5">
                      Verify Your Email
                    </h3>
                  )}
                  {status === 'send' ? (
                    <div className="space-y-5">
                      <div className="space-y-[10px]">
                        <TextField
                          label="New Email"
                          type="text"
                          name="newEmail"
                          id="newEmail"
                          error={
                            formik.touched['newEmail']
                              ? formik.errors['newEmail']
                              : ''
                          }
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="text-[#404256] text-[13px] leading-[22px] text-center font-inter font-medium">
                      Enter the code that has been sent to your email to verify
                      your email.
                    </div>
                  )}
                  {status === 'verify' && (
                    <EnterCode code={code} setCode={setCode} />
                  )}
                  {status === 'verify' ? (
                    <div>
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          verifyCode()
                        }}
                        variant="secondary"
                        className="w-full"
                        disabled={code.length !== 6 || verifying}
                      >
                        Verify
                        {verifying && (
                          <AnimateSpinIcon className="w-6 h-6 ml-2" />
                        )}
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          formik.handleSubmit()
                        }}
                        variant="secondary"
                        className="w-full"
                        disabled={formik.isSubmitting}
                      >
                        Submit
                        {formik.isSubmitting && (
                          <AnimateSpinIcon className="w-6 h-6 ml-2" />
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
