import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCandidates,
  getCandidates,
  getCandidatesCount,
} from 'app/store/candidateSlice'
import { PAGINATION_LIST } from 'app/utils/list'
import compareProfiles from 'app/utils/profileRank'
import useTagList from 'hooks/useTagList'
import tagService from 'app/services/tag.service'
import LatestHomePage from './LatestHomePage'

function LatestHomePageContainer() {
  const [loading, setLoading] = useState(false)
  const keywordsStored = JSON.parse(localStorage.getItem('filter_jobs_keywords')) ?? []
  const [searchKeywords, setSearchKeywords] = useState(keywordsStored)
  const [suggestedTags, setSuggestedTags] = useState([])
  const [filter, setFilter] = useState({})
  const dispatch = useDispatch()

  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem('can_page')
    return storedPage ? Number(storedPage) : 1
  })
  const candidates = useSelector(getCandidates)
  const candidatesCount = useSelector(getCandidatesCount)
  const abortControllerRef = React.useRef(null)

  const [query, setQuery] = useState('')
  const [key, setKey] = useState(null)
  const [tagMapping, setTagMapping] = useState({})
  const [mergedData, setMergedData] = useState([])
  const [skillsTags, , , jobTitleTags] = useTagList()

  useEffect(() => {
    const merged = [
      ...skillsTags?.map((v) => {
        const uniqueId = `skill-${v.id}`
        return { id: uniqueId, name: v.label, mainId: v.mainId }
      }),
      ...jobTitleTags?.map((v) => {
        const uniqueId = `job-${v.id}`
        return { id: uniqueId, name: v.label }
      }),
    ]

    const mapping = merged.reduce((acc, v) => {
      acc[v.id] = v
      return acc
    }, {})

    setMergedData(merged)
    setTagMapping(mapping)
  }, [skillsTags, jobTitleTags])

  const handleChange = useCallback(
    (value) => {
      setKey(value)
      setSearchKeywords([...searchKeywords, value])
      if (searchKeywords?.includes(value.id)) {
        const newKeywords = searchKeywords?.filter((v) => v !== value.id)
        setSearchKeywords(newKeywords)
      } else {
        const newKeywords = [...searchKeywords, value.id]
        setSearchKeywords(newKeywords)
      }
    },
    [searchKeywords]
  )

  const filteredValues = query === ''
    ? mergedData
    : mergedData.filter((v) =>
        v.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  const handleRemoveKeyword = useCallback(
    (key) => {
      const newKeywords = searchKeywords.filter((v) => v !== key)
      setSearchKeywords(newKeywords)
    },
    [searchKeywords]
  )

  const fetchList = useCallback(async () => {
    try {
      if (abortControllerRef.current && !_.isEmpty(filter)) {
        abortControllerRef.current.abort()
      }

      abortControllerRef.current = new AbortController()
      setLoading(true)
      
      let param = {
        offset: (currentPage - 1) * numberPerPage.name,
        limit: numberPerPage.name,
      }
      
      if (!_.isEmpty(filter)) {
        param = { ...param, filter }
      }

      if (searchKeywords.length > 0) {
        const modifiedArray = searchKeywords.map((item) =>
          item.replace(/^(skill-|job-)/, '')
        )
        param = { ...param, filter: { ...filter, skills: [...modifiedArray] } }
      }

      if (searchKeywords.length > 0) {
        const removeAutoScroll = localStorage.getItem('can_selected')
        if (removeAutoScroll) {
          localStorage.removeItem('can_selected')
        }
        await dispatch(
          fetchCandidates({
            ...param,
            signal: abortControllerRef.current.signal,
          })
        )
      }
    } catch (err) {
      console.log('debug: err', err)
    } finally {
      setLoading(false)
    }
  }, [filter, currentPage, numberPerPage, searchKeywords, dispatch])

  useEffect(() => {
    const removeAutoScroll = localStorage.getItem('can_selected')
    if (removeAutoScroll) {
      localStorage.removeItem('can_selected')
    }
    fetchList()
  }, [fetchList, currentPage])

  useEffect(() => {
    if (searchKeywords?.length === 0) {
      setFilter((prev) => {
        const temp = { ...prev }
        delete temp.skills
        return temp
      })
    } else {
      const actualKeywords = searchKeywords?.map((key) => {
        if (key.startsWith('skill-')) {
          return tagMapping[key]?.name
        } else {
          return key
        }
      })
      setFilter((prev) => ({
        ...prev,
        skills: actualKeywords,
      }))
    }
  }, [searchKeywords, tagMapping])

  const onPageChange = (param) => {
    localStorage.setItem('prev_can_page', currentPage)
    setCurrentPage(param)
    localStorage.setItem('can_page', param)
    const elementTop = document.getElementById('results-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
  }

  const sortedCandidates = [...candidates].sort(compareProfiles)

  const getRandomTags = (tagsArray, n) => {
    return tagsArray.slice(0, n)
  }

  useEffect(() => {
    const fetchSuggestedTags = async () => {
      const lastSelectedTagID = searchKeywords[searchKeywords.length - 1]
      const lastSelectedTag = tagMapping[lastSelectedTagID]
      const lastSelectedTagMainID = lastSelectedTag?.mainId
      
      if (searchKeywords.length > 0) {
        const suggestedTagsResponse = await tagService.getSuggestedTags(
          lastSelectedTagMainID
        )
        const randomlySelectedTags = getRandomTags(suggestedTagsResponse.data, 6)

        const filteredTags = randomlySelectedTags?.filter((tag) => {
          const normalizedSearchTags = searchKeywords.map((searchTag) =>
            searchTag.replace('skill-', '')
          )
          return tag !== lastSelectedTag && !normalizedSearchTags.includes(tag)
        })

        setSuggestedTags(filteredTags)
      }
    }

    fetchSuggestedTags()
  }, [searchKeywords, tagMapping])

  const containerProps = {
    loading,
    searchKeywords,
    setSearchKeywords,
    suggestedTags,
    setSuggestedTags,
    filter,
    setFilter,
    numberPerPage,
    setNumberPerPage,
    currentPage,
    candidates: sortedCandidates,
    candidatesCount,
    query,
    setQuery,
    key,
    setKey,
    tagMapping,
    setTagMapping,
    handleChange,
    filteredValues,
    handleRemoveKeyword,
    onPageChange
  }

  return <LatestHomePage {...containerProps} />
}

export default LatestHomePageContainer 