//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

//custom components
import Button from 'components/Button'
import { ModalCloseIcon } from 'components/Icons'

export default function NoJobPostModal({ isOpen, closeModal, openNewJobPost }) {
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#F7F8FC] bg-opacity-90" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-[ #1B1D21] bg-opacity-70">
            <div className="flex min-h-full items-center justify-center p-4 text-center relative">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                  <div className="bg-white py-[40px] md:py-16 px-[20px] md:px-7 md:pb-[70px] relative">
                    <div
                      className="absolute right-0 top-[10px] w-[60px] md:w-auto"
                      onClick={closeModal}
                    >
                      <ModalCloseIcon className="w-full text-green-100 md:text-black-100 hover:text-green-100 cursor-pointer" />
                    </div>
                    <h5 className="text-black-100 leading-[30px] md:leading-[36px] text-[20px] md:text-[24px] font-semibold text-center">
                      You have not created any jobs yet.
                    </h5>
                    <div className="mt-[30px]">
                      <Button
                        onClick={openNewJobPost}
                        variant="secondary"
                        className="w-2/6 mx-auto font-inter font-bold leading-5 text-sm text-white py-4.5"
                      >
                        Create Job Post
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
