//packages
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

//apis
import {
  addProjectToProfileAPI,
  deleteProjectAPI,
  editProjectAPI,
  editUserAPI,
} from 'app/services/auth.service'
import { updateUserPlan } from 'app/services/user.service'
import { getChats, getChatsCount } from 'app/services/chat.service'

import UserServiceClient from 'app/clients/userService'

const API_URL = `${process.env.REACT_APP_USERS_API_URL}`

export const getProfile = (userId) => {
  return UserServiceClient.get(`${API_URL}/user/${userId}`)
}

export const resetStates = () => (dispatch) => {
  return dispatch(resetState)
}

const initialState = {
  user: null,
  currentChatsNo: 0,
}

export const addProjectToProfile = createAsyncThunk(
  'profile/addProjectToProfile',
  async (data) => {
    const response = await addProjectToProfileAPI(data)
    return response.data
  }
)

export const editProject = createAsyncThunk(
  'profile/editProject',
  async (data) => {
    const response = await editProjectAPI(data)
    return response.data
  }
)

export const deleteProject = createAsyncThunk(
  'profile/deleteProject',
  async (data) => {
    const response = await deleteProjectAPI(data)
    return response.data
  }
)

export const updateUser = createAsyncThunk(
  'profile/updateUser',
  async (data) => {
    const response = await editUserAPI(data)
    return response.data
  }
)

export const updateEmployerPlan = createAsyncThunk(
  'profile/updateEmployerPlan',
  async (data) => {
    const response = await updateUserPlan(data)
    return response.data
  }
)

export const fetchChatsNo = createAsyncThunk(
  'profile/fetchChatsNo',
  async (data) => {
    const response = await getChatsCount(data)
    return response?.data
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.user = action.payload
    },
    setUserEmail: (state, action) => {
      if (state.user.email) {
        state.user.email = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProjectToProfile.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(editProject.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(updateEmployerPlan.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(fetchChatsNo.fulfilled, (state, action) => {
      if (_.isObject(action.payload)) {
        state.currentChatsNo = action.payload.count
      } else state.currentChatsNo = 0
    })

    //Clear data after user logout
    builder.addCase('auth/LogOut', (state) => {
      state.user = null
      state.currentChatsNo = 0
    })
  },
})

export const getUser = (state) => state.profile.user
export const getChatsNo = (state) => state.profile.currentChatsNo

export const { setProfile, setUserEmail } = profileSlice.actions

export default profileSlice.reducer
