import React from 'react'
import { MessageDownloadIcon } from 'components/Icons'
import { useMediaQuery } from 'react-responsive'

function NoChatSelected() {
  const isMobile = useMediaQuery({ maxWidth: 639 })
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen rounded-[24px] bg-[#FFF] p-10">
      <div className="flex items-center justify-center w-[150px] sm:w-[240px] h-[150px] sm:h-[240px] bg-[#F0F2F7] rounded-full mb-[50px] ">
        <MessageDownloadIcon size={isMobile ? 70 : 100} />
      </div>
      <div>
        <p className="text-[#11142D] text-center font-poppins text-[16px] sm:text-[24px] not-italic font-medium opacity-50 ">
          No Chat Selected. Please select a Chat!!
        </p>
      </div>
    </div>
  )
}

export default NoChatSelected
