import React, { useState } from 'react'
import JobIcon from 'assets/img/jobIcon.png'
import VideoPlayerModal from 'components/Dialog/VideoPlayerModal'
import { useMediaQuery } from 'react-responsive'
const { flag } = require('country-emoji')

function VideoCard({ name, avatar, video, job_title, country }) {
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 639 })

  const onVideo = () => {
    setShowVideoPlayerModal(true)
    console.log("I'm Clicked")
  }

  function truncateString(str, num) {
    if (str?.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  return (
    <div className="relative w-[126px] h-[122px] rounded-[4px] group overflow-hidden mb-[20px]">
      {/* Profile Image */}
      <img
        className="w-full h-full object-cover"
        src={
          avatar
            ? avatar
            : 'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/blank-profile.png'
        }
        alt={`${name}'s profile picture`}
      />

      {/* Hover Tint */}
      <div className="absolute inset-0 bg-[rgba(0,5,17,0.68)] opacity-0 transition-opacity duration-300 pointer-events-none"></div>

      {/* Play Button */}
      {video && (
        <div
          onClick={onVideo}
          // style={{
          //   background: 'rgba(255, 255, 255, 0.3)',
          // }}
          className="absolute inset-0 flex items-center justify-center opacity-100 transition-opacity duration-300"
        >
          <div className="py-[2.98px] sm:py-[5.98px] px-[3.47px] sm:px-[6.9px] bg-white bg-opacity-30 rounded-[6.229px] sm:rounded-[8px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[5px] sm:w-[10px] h-[6px] sm:h-[12px]"
              viewBox="0 0 34 40"
              fill="none"
            >
              <path
                d="M4.83245 0.222095C-1.45326 4.15668 -1.31355 37.1749 4.83245 39.7737C8.54038 41.3408 33.8284 27.9241 33.8284 20.6456C33.8284 13.0427 7.86586 -1.6765 4.83245 0.222095Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}

      {/* Bottom Gradient Info */}
      {/* <div
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 5, 17, 0.00) 0%, rgba(0, 11, 35, 0.27) 17.25%, rgba(0, 5, 17, 0.55) 48.5%)',
        }}
        className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-[9px] sm:p-[7px] text-white z-50"
      >
        <div className="flex items-center justify-between mt-1">
          <div className="flex items-center gap-[2.62px] sm:gap-1">
            <img
              src={JobIcon}
              alt="Role Icon"
              className="w-[14.602px] h-[14.602px] sm:w-[10px] sm:h-[10px]"
            />
            <span className="text-white text-center font-manrope text-[9.873px] sm:text-[7px] not-italic font-medium leading-normal whitespace-nowrap">
              {isMobile ? job_title : truncateString(job_title, 52)}
            </span>
          </div>
          <div className="flex items-center gap-[2.62px] sm:gap-1">
            <img
              src={JobIcon}
              alt="Role Icon"
              className="w-[14.602px] h-[14.602px] sm:w-[10px] sm:h-[10px]"
            />
            <span className="text-white text-center font-manrope text-[9.873px] sm:text-[7px] not-italic font-medium leading-normal whitespace-nowrap">
              {isMobile ? country : truncateString(country, 52)}
            </span>
          </div>
        </div>
      </div> */}
      <VideoPlayerModal
        isOpen={showVideoPlayerModal}
        closeModal={() => setShowVideoPlayerModal(false)}
        url={video}
      />
    </div>
  )
}

export default VideoCard
