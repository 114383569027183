import React from 'react'

function TextCard({ upperText, lowerText, color, upperTextColor }) {
  return (
    <div
      className="flex flex-col justify-center items-start gap-[2.602px] h-[82.757px] w-[124px] py-[12.5px] px-[9.89px] rounded-[6.246px] mt-[5.63px] sm:gap-[4.128px] sm:h-[131.266px] sm:w-[198.138px] sm:py-[19.8px] sm:px-[15.7px] sm:rounded-[9.907px] sm:mt-[9.89px]"
      style={{ background: color, color: upperTextColor }}
    >
      <p className="text-[8.328px] sm:text-[12.932px] text-center font-manrope not-italic leading-[9.993px] sm:leading-[14.5px] font-light tracking-[-.083px] sm:tracking-[-.129px]">
        {upperText}
      </p>
      <p className="max-w-[164px] sm:w-[164px] text-white text-[22.901px]  sm:text-[24px] not-italic font-normal sm:font-bold leading-[20.863px] sm:leading-[21.36px] font-Oswald">
        {lowerText}
      </p>
    </div>
  )
}

export default TextCard
