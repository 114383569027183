import React from 'react'

function ChatBubble({
  country,
  jobPosition,
  avatar,
  flag,
  highlightField,
  highlightStyle,
  bgColor, // Pass dynamic background color as a prop
}) {
  const getDynamicStyle = (field) =>
    highlightField === field ? highlightStyle : {}

  return (
    <div className="flex gap-[3.389px] sm:gap-[8.99px]">
      {/* Avatar */}
      <div className="w-[21.23px] h-[21.23px] sm:w-[56px] sm:h-[56px] rounded-[21.23px] sm:rounded-[56px] bg-white flex items-end justify-center">
        <img
          src={avatar}
          alt={`${jobPosition} avatar`}
          className="w-full h-full object-cover rounded-full"
        />
      </div>

      {/* Chat bubble */}
      <div
        className="flex justify-center items-center gap-[2.607px] sm:gap-[6.914px] rounded-[6.257px] sm:rounded-[24.593px] px-[3.91px] py-[3.91px] sm:px-[14.4px] sm:py-[10.4px]"
        style={{ backgroundColor: bgColor || '#0DA6A6' }} // Default color if no bgColor is provided
      >
        <p className="text-black-100 text-center font-manrope text-[5.214px] sm:text-[16.827px] font-normal not-italic leading-[6.257px] sm:leading-[16.593px]">
          <span style={getDynamicStyle('jobPosition')}>{jobPosition}</span>{' '}
          <span style={getDynamicStyle('country')}>{country}</span>
        </p>
        <img
          src={flag}
          alt={`${country} flag`}
          className="w-[7.542px] h-[5.016px] sm:w-[16px] sm:h-[15px]"
        />
      </div>
    </div>
  )
}

export default ChatBubble
