import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import { Dialog, Transition } from '@headlessui/react';
import { useMediaQuery } from 'react-responsive';

import JobIcon from 'assets/img/jobIcon.png';

const VideoCard = ({
  name,
  job,
  location,
  vid,
  img,
  videoId,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isVideoReady, setIsVideoReady] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [showControls, setShowControls] = React.useState(false);

  const handleVideoClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <div className="relative w-[124.916px] h-[161.541px] sm:w-[194px] sm:h-[251px] mt-[8.125px]">
        <div 
          className="absolute inset-0 rounded-[5.232px] sm:rounded-[8.125px] overflow-hidden cursor-pointer"
          onClick={handleVideoClick}
          onMouseEnter={() => !isMobile && setShowControls(true)}
          onMouseLeave={() => !isMobile && setShowControls(false)}
        >
          {/* Thumbnail Image */}
          <img
            src={img}
            alt={`${name} speaking`}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
              isVideoReady && !isMobile ? 'opacity-0' : 'opacity-100'
            }`}
          />
          
          {/* Video Player - Only shown on desktop */}
          {!isMobile && (
            <div className={`absolute inset-0 transition-opacity duration-300 ${
              isVideoReady ? 'opacity-100' : 'opacity-0'
            }`}>
              <ReactPlayer
                url={vid}
                width="100%"
                height="100%"
                playing={true}
                loop
                muted
                playsinline
                controls={showControls}
                onReady={() => setIsVideoReady(true)}
                config={{
                  file: {
                    attributes: {
                      style: {
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      },
                      playsInline: true,
                      autoPlay: true,
                      muted: true,
                    }
                  }
                }}
              />
            </div>
          )}

          {/* Play Fullscreen Indicator */}
          <div className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 hover:bg-opacity-30 transition-opacity duration-300`}>
            <svg 
              className="w-8 h-8 text-white opacity-0 hover:opacity-100 transition-opacity duration-300" 
              fill="currentColor" 
              viewBox="0 0 20 20"
            >
              <path d="M3 8V6h2L3 4l2-2v2h2V2H3v2L1 2v2h2v2H1v2h4V6L3 8zm14 0V6h-2l2-2-2-2v2h-2V2h4v2l2-2v2h-2v2h2v2h-4V6l2 2zM3 12v2h2l-2 2 2 2v-2h2v2H3v-2l-2 2v-2h2v-2H1v-2h4v2L3 12zm14 0v2h-2l2 2-2 2v-2h-2v2h4v-2l2 2v-2h-2v-2h2v-2h-4v2l2-2z"/>
            </svg>
          </div>
        </div>

        <div
          style={{
            background: 'linear-gradient(180deg, rgba(0, 5, 17, 0.00) 0%, rgba(0, 11, 35, 0.27) 17.25%, rgba(0, 5, 17, 0.55) 48.5%)',
          }}
          className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-[9px] sm:p-4 text-white z-10"
        >
          <div className="flex items-center justify-between mt-1">
            <h3 className="text-white font-manrope text-[8.602px] sm:text-[13.358px] not-italic font-bold leading-normal">
              {name}
            </h3>
            <div className="flex items-center gap-[2.62px] sm:gap-1">
              <img
                src={`https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/${location?.toLowerCase().replace(/ /g, '+')}_flag.png`}
                alt={`${location} flag`}
                className="w-[8.602px] h-[8.602px] sm:w-[13.358px] sm:h-[13.358px]"
                loading="lazy"
              />
              <span className="text-white text-center font-manrope text-[5.365px] sm:text-[8.331px] not-italic font-medium leading-normal">
                {location}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between mt-1">
            <div className="flex items-center gap-[2.62px] sm:gap-1">
              <img
                src={JobIcon}
                alt="Role Icon"
                className="w-[8.602px] h-[8.602px] sm:w-[9.967px] sm:h-[10.323px]"
                loading="lazy"
              />
              <span className="text-white text-center font-manrope text-[5.376px] sm:text-[8.349px] not-italic font-medium leading-normal">
                {job}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Fullscreen Dialog */}
      <Transition show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[999]"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gradient-to-b from-[#000511] to-[#001756]" />
            </Transition.Child>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="fixed inset-0 p-4 sm:p-6 md:p-8 flex items-center justify-center">
                <button
                  className="absolute top-4 right-4 text-white z-10 p-2 hover:bg-white/10 rounded-full transition-colors"
                  onClick={() => setIsOpen(false)}
                >
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                <div className="w-full h-full max-w-[90vh] max-h-[90vh] aspect-video bg-[#000511]/50 rounded-lg p-2">
                  <ReactPlayer
                    url={vid}
                    width="100%"
                    height="100%"
                    playing={true}
                    controls={true}
                    loop
                    style={{ 
                      borderRadius: '8px',
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                    config={{
                      file: {
                        attributes: {
                          style: {
                            borderRadius: '8px',
                            objectFit: 'contain',
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

VideoCard.propTypes = {
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  vid: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
};

export default React.memo(VideoCard);
