import React, { useState, useEffect } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const RichTextEditor = ({ name, value, onChange, placeholder }) => {
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      const contentBlock = htmlToDraft(value)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      )

      return EditorState.createWithContent(contentState)
    } else {
      return EditorState.createEmpty()
    }
  })

  useEffect(() => {
    // Convert current editor state to HTML and compare with the value prop
    const currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
    if (value && value !== currentContentAsHTML) {
      const contentBlock = htmlToDraft(value)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap
        )
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  }, [value]) // Only run when value prop changes

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)

    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onChange({
      target: {
        name,
        value: html,
      },
    })
  }

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        tabIndex={-1}
      />
    </div>
  )
}

export default RichTextEditor
