//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'

//custom components
import { ModalCloseIcon } from 'components/Icons'

export default function CoverLetterUpgradeModal({
  isOpen,
  closeModal,
  upgradeNow,
}) {
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#F7F8FC] bg-opacity-90" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-[ #1B1D21] bg-opacity-70">
            <div className="flex min-h-full items-center justify-center p-4 text-center relative">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                  <div className="bg-white py-[40px] md:py-16 px-[20px] md:px-7 md:pb-[70px] relative">
                    <div
                      className="absolute right-0 top-[10px] w-[60px] md:w-auto"
                      onClick={closeModal}
                    >
                      <ModalCloseIcon className="w-full text-green-100 md:text-black-100 hover:text-green-100 cursor-pointer" />
                    </div>
                    <div className="flex flex-col items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="72"
                        height="72"
                        className="mb-[35px]"
                        viewBox="0 0 72 72"
                        fill="none"
                      >
                        <path
                          d="M42 6H30C20.1623 6 15.2435 6 11.7567 8.44146C10.4667 9.3447 9.3447 10.4667 8.44146 11.7567C6 15.2435 6 20.1623 6 30C6 39.8376 6 44.7564 8.44146 48.2433C9.3447 49.5333 10.4667 50.6553 11.7567 51.5586C15.2435 54 20.1623 54 30 54H42C51.8376 54 56.7564 54 60.2433 51.5586C61.5333 50.6553 62.6553 49.5333 63.5586 48.2433C66 44.7564 66 39.8376 66 30C66 20.1623 66 15.2435 63.5586 11.7567C62.6553 10.4667 61.5333 9.3447 60.2433 8.44146C56.7564 6 51.8376 6 42 6Z"
                          stroke="#1E3E85"
                          stroke-width="4.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M43.5 66L42.5535 64.7433C40.4199 61.9107 39.8907 57.5832 41.2404 54M28.5 66L29.4465 64.7433C31.5801 61.9107 32.1093 57.5832 30.7596 54"
                          stroke="#1E3E85"
                          stroke-width="4.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M21 66H51"
                          stroke="#1E3E85"
                          stroke-width="4.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M24 42C29.5154 34.2138 42.3564 33.789 48 42M42 24C42 27.3137 39.3138 30 36 30C32.6862 30 30 27.3137 30 24C30 20.6863 32.6862 18 36 18C39.3138 18 42 20.6863 42 24Z"
                          stroke="#1E3E85"
                          stroke-width="4.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      <h5 className="text-black-100 capitalize leading-[30px] md:leading-[36px] text-[20px] md:text-[24px] font-semibold text-center w-[395px] ">
                        You need to upgrade to Premium account to access this
                        feature
                      </h5>
                    </div>

                    <div className="flex justify-center mt-7">
                      <button
                        className="bg-[#FFC628] text-[#1E3E85] font-bold text-[14px] py-5 px-24 rounded-2xl"
                        onClick={upgradeNow}
                      >
                        Upgrade Now
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
