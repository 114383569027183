import clsx from 'clsx'
import * as React from 'react'

//assets
import ActiveIcon from 'assets/images/active-icon.png'
import InActiveIcon from 'assets/images/inactive-icon-free-card.png'

const InputRadioMembership = ({ onHandleChange, value: plan }) => {
  return (
    <div className="grid md:grid-cols-2 gap-x-[5px] gap-y-[20px] min:h-full">
      <div
        onClick={() => {
          onHandleChange('basic')
        }}
        className={clsx(
          'cursor-pointer',
          plan === 'basic'
            ? 'md:p-[8px] md:border-[4px] md:border-blue-100 rounded-2xl border-solid'
            : ''
        )}
      >
        <div
          className={clsx(
            'rounded-2xl pt-5 px-8 h-full',
            plan === 'basic'
              ? 'card-gradient'
              : 'border-[2px] border-gray-400 border-solid py-8'
          )}
          for="basic"
        >
          <div className="membership-option">
            <h4
              className={clsx(
                'tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold',
                plan === 'basic' ? 'text-white' : 'text-blue-100 '
              )}
            >
              BASIC
            </h4>
            <h5
              className={clsx(
                'leading-[19px] text-[16px] font-bold md:mt-[9px]',
                plan === 'basic' ? 'text-[#96F154]' : 'text-green-100'
              )}
            >
              Free
            </h5>
            <ul
              className={clsx(
                'space-y-5 md:space-y-3 py-4 mt-[20px] md:mt-4 mb-[24px] md:mb-0',
                plan === 'basic' ? 'text-white' : 'text-[#11142D]'
              )}
            >
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                      fill="#1E3E85"
                    />
                  </svg>
                  <span>1 Active Job</span>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                      fill="#1E3E85"
                    />
                  </svg>
                  <span>Basic Placement</span>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-start gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[19px] h-[15px] flex-shrink-0 "
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                      fill="#1E3E85"
                    />
                  </svg>
                  <span>Up to 24-hour delay to job posts</span>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                <div className="flex items-start gap-1">
                  {' '}
                  {/* Use `items-start` to align items at the top */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[19px] h-[15px] flex-shrink-0 "
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                      fill="#1E3E85"
                    />
                  </svg>
                  <div className="flex-grow">
                    {' '}
                    {/* Allow text to grow and wrap naturally */}
                    View first 25 applicants that have applied to your job
                  </div>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                <div className="flex items-start gap-1">
                  {' '}
                  {/* Use `items-start` to align items at the top */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[19px] h-[15px] flex-shrink-0 "
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                      fill="#1E3E85"
                    />
                  </svg>
                  <div className="flex-grow">
                    {' '}
                    {/* Allow text to grow and wrap naturally */}
                    Send 5 free messages to job seekers
                  </div>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                <div className="flex items-start gap-1">
                  {' '}
                  {/* Use `items-start` to align items at the top */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[19px] h-[15px] flex-shrink-0 "
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M6.38174 10.2973C8.49333 6.79474 14.2837 2.72693 17.2528 0.623047L18.0999 1.18386C14.288 4.40862 9.48776 8.33436 8.21711 12.6808C7.37004 12.9611 5.53462 13.9426 5.25227 14.223C4.26399 12.12 2.3205 9.14456 0.899902 8.29012C3.58349 5.13516 5.56919 8.64909 6.38174 10.2973Z"
                      fill="#1E3E85"
                    />
                  </svg>
                  <div className="flex-grow">
                    {' '}
                    {/* Allow text to grow and wrap naturally */}
                    Send up to 5 invites to job seekers
                  </div>
                </div>
              </li>
              {/* <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M14.1437 1.98348C14.177 1.79822 14.1237 1.60762 13.999 1.46701C13.7011 1.12313 13.3279 0.853218 12.9081 0.677954C12.4603 0.498681 11.9584 0.51068 11.5199 0.711942C10.315 1.26774 8.89157 2.24139 7.50011 3.45955C6.10861 2.24132 4.68511 1.26761 3.47824 0.71126C3.03974 0.509996 2.53792 0.497337 2.09007 0.677272C1.67156 0.852535 1.29903 1.12245 1.00113 1.46433C0.876508 1.60628 0.823188 1.79687 0.856514 1.98215C0.890502 2.17275 1.01046 2.33735 1.18106 2.428C2.74384 3.25303 4.24395 4.19206 5.66885 5.23634C3.07052 8.02933 1.359 11.1376 0.847864 14.0052H0.848531C0.834536 14.0838 0.87852 14.1611 0.95316 14.1904C0.972487 14.1964 0.99248 14.1997 1.01314 14.2004C1.07112 14.2004 1.1251 14.1704 1.15442 14.1204C2.85849 11.3194 5.00108 8.80964 7.50011 6.68713C9.99915 8.80964 12.1418 11.3187 13.8458 14.1198C13.8751 14.1697 13.9291 14.2004 13.9871 14.1997C14.0077 14.1997 14.0277 14.1964 14.0471 14.1897C14.1217 14.1611 14.1657 14.0838 14.1517 14.0051C13.6399 11.1369 11.9299 8.02932 9.33071 5.23633L9.33138 5.23566C10.7569 4.19136 12.2564 3.25305 13.8192 2.428C13.9898 2.33736 14.1097 2.17342 14.1437 1.98348Z"
                      fill="#C83F3F"
                    />
                  </svg>
                  <span>Ai Interviewer</span>
                </div>
              </li> */}
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M14.1437 1.98348C14.177 1.79822 14.1237 1.60762 13.999 1.46701C13.7011 1.12313 13.3279 0.853218 12.9081 0.677954C12.4603 0.498681 11.9584 0.51068 11.5199 0.711942C10.315 1.26774 8.89157 2.24139 7.50011 3.45955C6.10861 2.24132 4.68511 1.26761 3.47824 0.71126C3.03974 0.509996 2.53792 0.497337 2.09007 0.677272C1.67156 0.852535 1.29903 1.12245 1.00113 1.46433C0.876508 1.60628 0.823188 1.79687 0.856514 1.98215C0.890502 2.17275 1.01046 2.33735 1.18106 2.428C2.74384 3.25303 4.24395 4.19206 5.66885 5.23634C3.07052 8.02933 1.359 11.1376 0.847864 14.0052H0.848531C0.834536 14.0838 0.87852 14.1611 0.95316 14.1904C0.972487 14.1964 0.99248 14.1997 1.01314 14.2004C1.07112 14.2004 1.1251 14.1704 1.15442 14.1204C2.85849 11.3194 5.00108 8.80964 7.50011 6.68713C9.99915 8.80964 12.1418 11.3187 13.8458 14.1198C13.8751 14.1697 13.9291 14.2004 13.9871 14.1997C14.0077 14.1997 14.0277 14.1964 14.0471 14.1897C14.1217 14.1611 14.1657 14.0838 14.1517 14.0051C13.6399 11.1369 11.9299 8.02932 9.33071 5.23633L9.33138 5.23566C10.7569 4.19136 12.2564 3.25305 13.8192 2.428C13.9898 2.33736 14.1097 2.17342 14.1437 1.98348Z"
                      fill="#C83F3F"
                    />
                  </svg>
                  <span>Video Cover Letter</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        onClick={() => onHandleChange('premium')}
        className={clsx(
          'cursor-pointer',
          plan === 'premium'
            ? 'md:p-[8px] md:border-[4px] md:border-blue-100 rounded-2xl border-solid'
            : ''
        )}
      >
        <div
          className={clsx(
            'rounded-2xl pt-5 px-8 h-full',
            plan === 'premium'
              ? 'card-gradient'
              : 'border-[2px] border-gray-400 border-solid'
          )}
          for="premium"
        >
          <div className="membership-option">
            <h4
              className={clsx(
                'tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold uppercase',
                plan === 'premium' ? 'text-white' : 'text-blue-100 '
              )}
            >
              Premium
            </h4>
            <h5
              className={clsx(
                'leading-[19px] text-[16px] font-bold md:mt-[9px]',
                plan === 'premium' ? 'text-[#96F154]' : 'text-green-100'
              )}
            >
              $79.99/month
            </h5>
            <div className="relative rounded-[5px] bg-[#FFC700] py-[5px] mt-[5px] ml-[-10px] pl-[10px]">
              <ul
                className={clsx(
                  '',
                  plan === 'premium' ? 'text-white' : 'text-[#323445]'
                )}
              >
                {/* <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                  <div className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <path
                        d="M5.88184 9.87441C7.99343 6.37189 13.7838 2.30408 16.7529 0.200195L17.6 0.761011C13.7881 3.98577 8.98786 7.91151 7.71721 12.2579C6.87013 12.5383 5.03472 13.5198 4.75237 13.8002C3.76409 11.6971 1.8206 8.72171 0.400002 7.86727C3.08359 4.71231 5.06928 8.22623 5.88184 9.87441Z"
                        fill="#11142D"
                      />
                    </svg>
                    <span className="text-[#11142D]">Ai Interviewer</span>
                  </div>
                </li> */}
                <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                  <div className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <path
                        d="M5.88184 9.87441C7.99343 6.37189 13.7838 2.30408 16.7529 0.200195L17.6 0.761011C13.7881 3.98577 8.98786 7.91151 7.71721 12.2579C6.87013 12.5383 5.03472 13.5198 4.75237 13.8002C3.76409 11.6971 1.8206 8.72171 0.400002 7.86727C3.08359 4.71231 5.06928 8.22623 5.88184 9.87441Z"
                        fill="#11142D"
                      />
                    </svg>
                    <span className="text-[#11142D]">Video Cover Letter</span>
                  </div>
                </li>
              </ul>
            </div>
            <ul
              className={clsx(
                'space-y-4 py-0 mt-[20px] md:mt-4 mb-[22px] md:mb-0',
                plan === 'premium' ? 'text-white' : 'text-[#323445]'
              )}
            >
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                  >
                    <path
                      d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                      fill="#FFC628"
                    />
                  </svg>
                  <span>25 active jobs</span>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                  >
                    <path
                      d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                      fill="#FFC628"
                    />
                  </svg>
                  <span>Premium placement</span>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px] text-nowrap">
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                  >
                    <path
                      d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                      fill="#FFC628"
                    />
                  </svg>
                  <span>Instant job posting</span>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                <div className="flex items-start gap-1">
                  {/* Use `items-start` to align items at the top */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[18px] h-[14px] flex-shrink-0" /* Prevent SVG from shrinking */
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                  >
                    <path
                      d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                      fill="#FFC628"
                    />
                  </svg>
                  <div className="flex-grow">
                    {' '}
                    {/* Allow text to grow and wrap naturally */}
                    View all applicants that have applied to your job
                  </div>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                <div className="flex items-start gap-1">
                  {/* Use `items-start` to align items at the top */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[18px] h-[14px] flex-shrink-0" /* Prevent SVG from shrinking */
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                  >
                    <path
                      d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                      fill="#FFC628"
                    />
                  </svg>
                  <div className="flex-grow">
                    {' '}
                    {/* Allow text to grow and wrap naturally */}
                    Unlimited Messages to job seekers
                  </div>
                </div>
              </li>
              <li className="flex items-start font-inter text-[14px] not-italic font-semibold leading-[20px]">
                <div className="flex items-start gap-1">
                  {/* Use `items-start` to align items at the top */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[18px] h-[14px] flex-shrink-0" /* Prevent SVG from shrinking */
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                  >
                    <path
                      d="M5.88174 9.87441C7.99333 6.37189 13.7837 2.30408 16.7528 0.200195L17.5999 0.761011C13.788 3.98577 8.98776 7.91151 7.71711 12.2579C6.87004 12.5383 5.03462 13.5198 4.75227 13.8002C3.76399 11.6971 1.8205 8.72171 0.399902 7.86727C3.08349 4.71231 5.06919 8.22623 5.88174 9.87441Z"
                      fill="#FFC628"
                    />
                  </svg>
                  <div className="flex-grow">
                    {' '}
                    {/* Allow text to grow and wrap naturally */}
                    Unlimited invites to jobseekers
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputRadioMembership
