import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import React, { useState } from 'react'

//assets
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
import Footer from 'views/LatestHomePage/components/Footer'
import { NavBar } from 'views/LatestHomePage/components/NavBar'

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery({ maxWidth: 639 })
  const [activeIndex, setActiveIndex] = useState(null)
  const sections = [
    { title: '1. Introduction', id: 'introduction' },
    { title: '2. Use of the Platform by Minors', id: 'minors' },
    { title: '3. Information We Collect', id: 'info-collection' },
    { title: '4. How we Use Your Information', id: 'info-use' },
    {
      title: '5. How We Share or Disclose Your Information',
      id: 'info-sharing',
    },
    { title: '6. How Long Your Information Is Kept', id: 'info-duration' },
    { title: '7. Third Party Websites and Links', id: 'third-party' },
    { title: '8. Your Data Rights', id: 'data-rights' },
    { title: '9. Data Security', id: 'data-security' },
    { title: '10. Contact Information', id: 'contact' },
  ]

  const handleScrollToSection = (id, index) => {
    setActiveIndex(index)
    const sectionElement = document.getElementById(id)
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Helmet>
        <title>Remote Recruit Privacy Policy - Remoterecruit.com</title>
        <meta
          name="description"
          content="Read how Remote Recruit protects your privacy and personal information on our platform."
        />
      </Helmet>
      <div className="flex flex-col items-center justify-center min-h-full bg-white">
        {/* Navbar */}
        <NavBar showHowItWorks={false} />

        {/* Background Image */}
        <img
          className="w-full h-[365.833px] sm:h-[528.878px] object-cover"
          src={
            isMobile
              ? 'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/privacy_terms_mobile_bg.png'
              : 'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/privacy_terms_bg.png'
          }
          alt="Remote Recruit Search Result Page Background Image"
        />
        <div className="flex flex-col items-center justify-center absolute">
          <ScrollToTop />
          <p className="text-[#FFC628] text-center font-poppins text-[36px] sm:text-[72px] not-italic leading-[35.64px] sm:leading-[71.28px] font-semibold ">
            Privacy Policy
          </p>
          <p className="text-white text-center font-manrope text-[16px] sm:text-[20px] not-italic font-semibold leading-[29px] opacity-80 mt-[10px]">
            Effective date: January 3, 2025
          </p>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 gap-6 z-20">
        {/* <div className="hidden sm:block col-span-1 w-60 h-max flex-shrink-0 rounded-3xl bg-gray-200 shadow-box-shadow z-20"></div> */}
        <div className="hidden sm:block col-span-1 ml-[96px] font-manrope border-t-[8.804px] border-t-[#1E3E85] gap-[8.408px]  w-[265px] h-[353px] overflow-y-auto lg:left-44 xl:left-44 md:left-0 sm:left-0 flex-shrink-0 rounded-[8.408px] bg-white shadow-box-shadow-custom">
          {sections.map((section, index) => (
            <p
              key={section.id}
              onClick={() => handleScrollToSection(section.id, index)}
              className={`p-[8px] ${
                activeIndex === index ? 'bg-[#EFF0F8]' : ''
              } hover:bg-[#EFF0F8] cursor-pointer`}
            >
              {section.title}
            </p>
          ))}
        </div>
        <div className="col-span-2 sm:w-5/6 sm:px-0">
          <section id="introduction">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              1. Introduction
            </p>
            <ul className="list-disc list-inside">
              <li className="text-[#1B1D21] text-[18px] leading-[26px] font-bold ">
                Background
              </li>
            </ul>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 ml-6">
              <p>
                Remote Recruit, Inc. ("<b>Remote Recruit</b>,” “<b>us</b>,” or “
                <b>we</b>”) provides an online Platform that connects Job
                Seekers and Employers. We respect your privacy and are committed
                to protecting it by complying with this Privacy Policy. This
                Privacy Policy applies to any information we may collect: (A) on
                the Platform; (B) in email, text, and other electronic messages
                between you and us (whether through the Platform or otherwise);
                (C) through mobile and desktop applications you download from
                the Platform, which provide dedicated, non-browser-based
                interactions between you and the Platform; and (D) when you
                interact with our advertising and applications on third party
                websites, if those applications or advertising include links to
                this Privacy Policy.
              </p>
            </div>
            <ul className="list-disc list-inside">
              <li className="text-[#1B1D21] text-[18px] leading-[26px] font-bold mt-4">
                Overview
              </li>
            </ul>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 ml-6">
              This Privacy Policy, together with our Terms of Use, details the
              types of information we may collect about you or other Users when
              you access our Platform, including Personal Data.{' '}
              <b>“Personal Data”</b> means information that identifies, relates
              to, describes, references, is reasonably capable of being
              associated with, or could reasonably be linked, directly or
              indirectly, with a particular consumer, household, or device. The
              types of Personal Data that we collect is detailed in{' '}
              <span className="text-[#001957] font-poppins text-[18px] not-italic font-semibold leading-[26.201px] underline ">
                Section 3
              </span>{' '}
              below. This Privacy Policy also sets forth our practices with
              respect to collecting, using, maintaining, protecting, and
              disclosing such information. Any capitalized terms without
              definition have the meanings ascribed to them in our{' '}
              <span className="text-[#001957] font-poppins text-[18px] not-italic font-bold leading-[26.201px] underline ">
                Terms of Use.
              </span>
            </div>
            <ul className="list-disc list-inside">
              <li className="text-[#1B1D21] text-[18px] leading-[26px] font-bold mt-4">
                Acceptance
              </li>
            </ul>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 ml-6">
              By accessing or using the Platform, you agree that you have
              carefully read this Privacy Policy, understand our policies and
              practices regarding your information, and consent to this Privacy
              Policy. If you do not agree with this Privacy Policy, then you may
              not access or use the Platform.
            </div>
            <ul className="list-disc list-inside">
              <li className="text-[#1B1D21] text-[18px] leading-[26px] font-bold mt-4">
                Changes to this Privacy Policy
              </li>
            </ul>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 ml-6">
              We may revise this Privacy Policy from time to time in our sole
              discretion. We will notify you in advance of material changes to
              our Privacy Policy by posting the revisions on the Platform and/or
              by emailing you if you are a registered User. All revised terms of
              the Privacy Policy are effective when we publish the revised terms
              on the webpage, or the effective date as stated in the notice, if
              applicable. Your continued use of the Platform following the
              effective date of the revised Privacy Policy means that you accept
              and agree to the modified Privacy Policy. If you do not agree to
              any of the provisions contained in this Privacy Policy, then you
              may not access or use (or continue to access or use) the Platform.
            </div>
          </section>
          <section className="mt-10" id="minors">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              2. Use of the Platform by Minors.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                As set forth in the Terms of Use, all Users must be at least 18
                years of age or the age of majority in the state in which they
                reside (those who do not meet these requirements are{' '}
                <b>“Minors”</b>). Minors may not provide any Personal Data to or
                on the Platform. We do not knowingly collect Personal Data from
                any User who is a Minor. If you are a Minor, you are strictly
                prohibited from using the Platform, providing any information to
                us or on the Platform, including your name, address, telephone
                number, email address, or Platform username. If we determine
                that we have collected or received a Minor’s Personal Data, we
                will delete such Personal Data. If you believe that we may have
                any information, including Personal Data, that belongs to a
                Minor, please contact us at <b>team@remoterecruit.com.</b>
              </p>
            </div>
          </section>
          <section className="mt-10" id="info-collection">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              3. Information We Collect
            </p>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              A. Information You provide to Us.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              You may provide us with Personal Data voluntarily, including:
              <br /> <br /> (i) information you provide when registering for an
              account to use or access the Platform, such as your name, email
              address, username, and login details;
              <br /> <br /> (ii) when you post User Content, such as Personal
              Data related to your professional background, including job
              history, professional skills, education, or other work-related
              data that you might include in an uploaded resume or your profile,
              or your age or gender;
              <br /> <br /> (iii) if you contact us outside the Platform, such
              as contact information or identification information like a
              government issued ID (to verify your identity if you make a
              request described in{' '}
              <span className="text-[#001957] font-poppins text-[18px] not-italic font-semibold leading-[26.201px] underline ">
                Section 8
              </span>
              ); and <br /> <br />
              iv) details of transactions if you subscribe to certain paid
              services on the Platform.
            </div>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              B. Information from Third Parties.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              We may collect Personal Data about you from certain third parties,
              including <br /> <br />
              (i) social media websites if you link your account with a
              third-party service, <br /> <br />
              (ii) data enrichment providers,
              <br /> <br />
              (iii) email or other marketing partners to manage and measure our
              campaigns/communications, and <br /> <br />
              (iv) other vendors who provide us data to supplement the
              information we collect, such as our payment processor or security
              providers.
            </div>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              C. Automatic Data Collection.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              (i) When you use the Platform, we may use automatic data
              collection technologies, such as Google Analytics, to collect
              certain information about your equipment, browsing behavior, and
              patterns, including: (i) details of your visit to the Platform,
              including traffic data, location data, logs, etc.; and (ii)
              information about your computer and internet connection, including
              your IP address, operating system, and browser type. <br /> <br />
              (ii) We may also use the above technologies to collect information
              about your online activity over time and across third party
              website or other online services. You may find information about
              how you can opt out of this behavioral tracking in{' '}
              <span className="text-[#001957] font-poppins text-[18px] not-italic font-semibold leading-[26.201px] underline ">
                Section 8
              </span>
              .
            </div>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              D. Cookies and Tracking Technology. Automatic data collection may
              also include the following cookies:
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              i. Strictly necessary cookies: These are cookies that are required
              for the operation of the Platform (i.e., cookies that enable you
              to log into secure areas of the Platform, purchase services, if
              applicable, etc.).
              <br /> <br />
              ii. Analytical/performance cookies: These are cookies that allow
              us to recognize and count the number of Users and gives us
              visibility into how Users are using the Platform. These cookies
              help us improve the way the Platform works. <br /> <br />
              iii. Functionality cookies: These cookies are used to recognize
              you when you return to the Platform and allows us to personalize
              the content to you (e.g., recognizing the choice of language or
              region).
              <br /> <br />
              iv. Targeting cookies: These cookies record your visit to the
              Platform, the pages you have visited, and the links you have
              followed. We use this information to make the Platform and
              advertising displayed on it more relevant to your interests. We
              may also share this information with third parties for this
              purpose.
            </div>
          </section>
          <section className="mt-10" id="info-use">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              4. How We Use Your Information
            </p>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              A. General Purpose.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              Subject to Section 8 below, we may use, sell or disclose the
              Personal Data we collect for one or more of the following
              purposes: (i) to provide the services or information that you
              request from us; (ii) to fulfill the reason you provided the
              information (e.g., you provide your contact information to contact
              us about products or services); (ii) to provide, support,
              personalize, and develop the Platform; (iii) to create, maintain,
              customize, and secure your account with us; (iv) to process your
              requests, purchases, transactions, payments, and prevent
              transactional fraud; (v) to provide you with support and respond
              to your inquiries; (vi) to personalize your experience using the
              Platform and deliver content relevant to your interests, including
              targeted advertisements; or (vii) to help maintain the safety,
              security, and integrity of the Platform.
            </div>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              B. Legal Basis for Using your Personal Data.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              Where relevant under applicable laws, our use of your Personal
              Data will be justified by a legal basis for processing, including:
              where you have provided your consent for us to use your Personal
              Data for a particular purpose; where our use of your Personal Data
              is necessary to perform a contract with you (such as to provide
              you with the services you have requested); where our use is
              necessary to support our legitimate interests as a business, but
              subject to your overriding interests and fundamental rights;
              and/or our use of your Personal Data is necessary to comply with a
              relevant legal or regulatory obligation that we have.
            </div>
          </section>
          <section className="mt-10" id="info-sharing">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              5. How We Share or Disclose Your Information
            </p>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              A. Information that is Not Personal Data
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              We may share, sell, or disclose aggregated information about Users
              and information that does not identify any individual, without
              restriction.
            </div>
            <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
              B. Legal Basis for Using your Personal Data.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
              i. We may disclose your Personal Data to a third party for a
              business purpose or we may sell your Personal Data, subject to
              your right to opt out of those sales, as set forth in Section 8
              below. When we disclose Personal Data for business purposes, we
              enter into a contractual agreement that describes the purpose and
              requires the recipient to keep that Personal Data confidential and
              only use it for its performance under the contract. <br /> <br />{' '}
              ii. We share Personal Data between Users of our Platform to help
              facilitate interactions, such as to help connect Job Seekers with
              Employers to fulfill job postings. Certain account information you
              provide when you register, such as your name, location, and User
              Content will be publicly available and visible through the
              Platform. <br /> <br />
              iii. We may share your Personal Data with our service providers
              for the purposes of offering the Platform and its features to you.
              Examples include hosting providers, analytics tools, mailing
              systems, marketing partners, data enrichment services. <br />{' '}
              <br /> iv. We may also disclose your Personal Data to comply with
              a court order, law, or legal process, including in response to a
              government or regulatory request; to enforce our Terms of Use; or
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of Remote Recruit, our customers,
              or other third parties.
            </div>
          </section>
          <section className="mt-10" id="info-duration">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              2. How Long Your Information Is Kept.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              Generally, we only retain your Personal Data for as long as
              necessary to fulfill the purposes for which we collect the
              Personal Data, . However, in some cases, we will retain your
              Personal Data for a longer time, including to satisfy any legal,
              accounting, or reporting requirements, prevent fraud. <br />{' '}
              <br />
              To determine the appropriate retention period for Personal Data,
              we consider the amount, nature, and sensitivity of the Personal
              Data, the potential risk of harm from unauthorized use or
              disclosure of the Personal Data, and the purpose for which we
              process your Personal Data (and whether we can achieve those
              purposes through other means), and the applicable legal
              requirements.
            </div>
          </section>
          <section className="mt-10" id="third-party">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              3. Third Party Websites and Links.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                Some content on the Platform, including advertisements, are
                served by third parties, including advertisers, ad networks and
                servers, content providers, and application providers. These
                third parties may use cookies to collect information about you
                when you use the Platform. The information they collect may be
                associated with your Personal Data or they may collect
                information about your online activities over time and across
                different websites and other online services. They may use this
                information to provide you with behavioral advertising or other
                targeted content. <br /> <br />
                We do not control these third parties, their tracking
                technologies, or how they may be used. If you have any questions
                about any advertisements or other targeted contact, you should
                contact that third party directly.
              </p>
            </div>
          </section>
          <section className="mt-10" id="data-rights">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              4. Your Data Rights.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>A. Rights Generally</b>. You have the right to: <br /> <br />
                i. Confirm whether we process your Personal Data <br /> <br />
                ii. request access to, or correct or delete, subject to certain
                exceptions (for example, see Section 6 above), any Personal Data
                that you have provided to us. Notwithstanding the foregoing, we
                may not accommodate a request to change information if we
                believe such change would violate any law or legal requirement
                or cause the information to be incorrect. You may send any such
                requests to <b>team@remoterecruit.com.</b> <br /> <br />
                iii. review and change your Personal Data by logging into the
                Platform and visiting your account profile; <br /> <br />
                iv. remove or reject cookies from the Platform with your browser
                settings. However, if you remove or reject cookies, it may
                impact the Platform’s performance for you; <br /> <br />
                v. withdraw your consent, if we have collected or processed your
                Personal Data with your consent; or <br /> <br />
                vi. make a complaint to the data privacy authority in your
                jurisdiction.
              </p>
              <p className="text-[#1B1D21] text-[18px] leading-[26px] font-bold my-4">
                B. Targeted Advertising/Third Party Disclosure Opt-Out.
              </p>
              <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7">
                If you do not want us to process your Personal Data and/or sell
                your Personal Data to third parties, for targeted advertising or
                marketing purposes, you may opt out of that processing or
                disclosure of your Personal Data (the “right to opt out”).{' '}
                <br /> <br />
                To exercise your right to opt out, you (or your authorized
                representative) may submit a request by contacting us at
                <b>team@remoterecruit.com</b>. <br /> <br />
                Once you make an opt out request, we will wait at least twelve
                (12) months before asking you to reauthorize Personal Data
                processing/disclosure for targeted advertising. However, you may
                change your mind and opt back in to Personal Data disclosure at
                any time by notifying us at <b>team@remoterecruit.com</b>.{' '}
                <br /> <br />
                You do not need to create an account with us to exercise your
                opt-out rights. We will only use Personal Data provided in an
                opt-out request to review and comply with the request. Please
                note that you may still receive generalized ads on the Platform
                even after opting out of Third Party disclosure of Personal
                Data.
              </div>
            </div>
          </section>
          <section className="mt-10" id="data-security">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              5. Data Security
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              We have implemented measures to secure your Personal Data from
              accidental loss and unauthorized access, use, alteration, and
              disclosure. All information you provide to us is stored on our
              secure servers behind firewalls. The safety and security of your
              information also depends on you. As detailed in the Terms of Use,
              you are responsible for keeping your login credentials
              confidential and not sharing it with anyone.
              <br /> <br /> Unfortunately, the transmission of information via
              the Internet is not completely secure. Although we do our best to
              protect your Personal Data, we cannot guarantee the security of
              your Personal Data used on the Platform. Any transmission of
              Personal Data on the Platform is done at your own risk.
            </div>
          </section>
          <section className="mt-10" id="contact">
            <p className="text-[#1B1D21] text-[24px] leading-[26px] font-semibold mb-[15px]">
              6. Contact Information
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4 mb-[114px]">
              <p>
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at <b>team@remoterecruit.com</b>.
              </p>
            </div>
          </section>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </>
  )
}

export default PrivacyPolicy
