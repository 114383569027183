//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

//custom components
import Button from 'components/Button'
import { CirclePlusIcon, ModalCloseIcon } from 'components/Icons'
import SelectJobs from './SelectJobs'
import UpgradingPlanModal from 'components/Dialog/UpgradingPlanModal'
import LimitInviteModal from './LimitInviteModal'

//redux
import { sendInvite } from 'app/store/candidateSlice'
import { getUser } from 'app/store/profileSlice'
import { setProfile } from 'app/store/profileSlice'
import { fetchApplicantsAPI } from 'app/services/applicantAPI'
import { getInvitesList } from 'app/services/inviteAPI'

export default function InviteToJobModal({
  isOpen,
  closeModal,
  jobForInvite,
  setJobForInvite,
  userIdForInvite,
  setUserIdForInvite,
}) {
  const [loading, setLoading] = useState(false)
  const [loadingInvites, setLoadingInvites] = useState(false)
  const [invites, setInvites] = useState([])
  const [applicants, setApplicants] = useState([])
  const [showGetPremiumModal, setShowGetPremiumModal] = useState(false)
  const [showReachLimit, setShowReachLimit] = useState(false)

  const dispatch = useDispatch()
  const jobs = useSelector((state) => state.job.jobs)
  const profile = useSelector(getUser)

  const filteredJobs = useMemo(() => {
    try {
      return (
        jobs
          .filter((v) => v.status === 'active')
          // PREMIUM start comment this out no premium user
          .filter((v) => {
            const duration = moment().diff(moment(v.createdAt), 'seconds')
            if (profile.plan === 'basic' && duration < 86400) {
              return false
            }
            return true
          })
          // end comment this out no premium user
          .map((v) => {
            return {
              id: v.id,
              name: v.title,
            }
          }) || []
      )
    } catch (e) {
      return []
    }
  }, [jobs])

  // console.log('debug: jobs', jobs)

  useEffect(() => {
    setLoadingInvites(true)
    if (userIdForInvite !== '') {
      const fetchApplicants = async () => {
        const param = {
          offset: 0,
          limit: 100,
          userId: userIdForInvite,
        }
        const result = await fetchApplicantsAPI(param)
        setApplicants(result.data.applications)
      }
      const fetchInvitesList = async () => {
        const param = {
          offset: 0,
          limit: 100,
          userId: userIdForInvite,
        }
        await getInvitesList(param).then((result) => {
          setInvites(result.data.invites)
          setLoadingInvites(false)
        })
      }
      if (profile && userIdForInvite) {
        fetchApplicants()
        fetchInvitesList()
      }
    }
  }, [profile, userIdForInvite])

  const handleSendInvite = async () => {
    setLoading(true)
    const params = {
      userId: userIdForInvite,
      jobId: jobForInvite?.id,
      employerId: profile.id,
    }
    const resultAction = await dispatch(sendInvite(params))

    if (sendInvite.fulfilled.match(resultAction)) {
      dispatch(
        setProfile({
          ...profile,
          inviteLimitNo: profile.inviteLimitNo ? profile.inviteLimitNo + 1 : 1,
        })
      )
      toast.success('Invite sent successfully')
      closeModal()
    } else {
      if (resultAction.payload) {
        toast.error(
          'There is an error when sending invite ' +
            resultAction.payload.errorMessage
        )
      } else {
        console.log(resultAction)
        if (resultAction.error.message.includes('401')) {
          toast.error('Already sent invite')
        } else {
          toast.error(
            'You reached the limit of the maximum number of invitations. Please upgrade your plan'
          )
          setShowReachLimit(true)
        }
      }
    }
    setLoading(false)
    closeModal()
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 border-[10px] "
          onClose={() => {
            setUserIdForInvite('')
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-100 sm:bg-[#F7F8FC] bg-opacity-70 sm:bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-[ #1B1D21] sm:bg-opacity-50">
            <div className="flex min-h-full items-center justify-center p-4 text-center relative">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                  <div className="bg-white py-[40px] md:py-16 px-[20px] md:px-7 md:pb-[70px] relative">
                    <div
                      className="absolute right-0 top-[10px] w-[60px] md:w-auto"
                      onClick={closeModal}
                    >
                      <ModalCloseIcon className="w-full text-green-100 md:text-black-100 hover:text-green-100 cursor-pointer" />
                    </div>
                    <h5 className="text-black-100 leading-[30px] md:leading-[36px] text-[20px] md:text-[24px] font-semibold text-center">
                      Invite to Job
                    </h5>
                    <p className="text-gray-100 leading-[24px] md:leading-4 text-[14px] md:text-sm font-medium mt-[12px] md:mt-1.5 text-center">
                      {/* PREMIUM start comment this out no premium user */}
                      {profile.plan === 'basic'
                        ? filteredJobs.length
                          ? 'Invite candidate to the jobs from the list below.'
                          : 'It looks like you do not have any active jobs just yet. You can upgrade your account for instant approval on pending jobs below.'
                        : filteredJobs.length
                        ? 'Invite candidate to the jobs from the list below.'
                        : 'It looks like you do not have any active jobs just yet.'}
                      {/* end comment this out no premium user */}
                    </p>
                    <div
                      className={
                        filteredJobs.length || profile.plan === 'basic'
                          ? 'mt-[30px]'
                          : ''
                      }
                    >
                      {filteredJobs.length ? (
                        <>
                          {!loadingInvites ? (
                            <SelectJobs
                              selected={jobForInvite}
                              setSelected={setJobForInvite}
                              placeholder="Select Jobs"
                              applicantJobs={applicants.map((v) => v.jobId)}
                              inviteJobs={invites.map((v) => v.jobId)}
                              data={filteredJobs}
                              loadingInvites={loadingInvites}
                            />
                          ) : (
                            <p className="text-center">Loading...</p>
                          )}

                          <div className="mt-[20px] md:mt-7.5 flex justify-center">
                            <Button
                              onClick={handleSendInvite}
                              variant="secondary"
                              className="px-[82px] space-x-[10px]"
                              disabled={loading || !jobForInvite}
                            >
                              <CirclePlusIcon className="text-green-100 mr-[10px]" />
                              Send Invite
                            </Button>
                          </div>
                        </>
                      ) : profile.plan === 'basic' ? (
                        <div className="flex justify-center mt-7">
                          <Button
                            onClick={() => setShowGetPremiumModal(true)}
                            variant="primary"
                            className="px-[30px] py-[13px]"
                          >
                            Upgrade for Instant Approval
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>

          <UpgradingPlanModal
            isOpen={showGetPremiumModal}
            closeModal={() => setShowGetPremiumModal(false)}
          />
        </Dialog>
      </Transition>

      <LimitInviteModal
        showReachLimit={showReachLimit}
        setShowReachLimit={setShowReachLimit}
      />
    </div>
  )
}
