//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'


//custom components
import { ModalCloseIcon } from 'components/Icons'
import { useNavigate } from 'react-router-dom'

export default function UpgradedSuccessfully({ isOpen, closeModal }) {
  const navigate = useNavigate()

  const moveToDashboard = () => {
    navigate(`/employer/profile`)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pt-[30px] md:pt-14 pb-[30px] md:pb-12 px-[25px] md:px-8 bg-white shadow-[14px_41px_50px_0.07] w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle transition-all">
                <div
                  className="absolute top-2 right-1 cursor-pointer"
                  onClick={closeModal}
                >
                  <ModalCloseIcon />
                </div>
                {/* Body Section */}
                <div className='flex px-[55px] py-[31px] justify-center items-center rounded-[19px] '>
                    <div className='flex flex-col justify-center items-center w-[324px]'>
                        <div><img className="w-36 h-36" src="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/successImg.png" /></div>
                        <div>
                            <p className='text-center text-[#11142D] font-manrope text-[20px] font-bold '>Success! Your plan has been upgraded.</p>
                        </div>
                        <div>
                            <p className='text-[#808191] text-center font-manrope text-[14px] font-normal '>You now have access to premium hiring tools to find and connect with top global talent effortlessly.</p>
                        </div>
                        <div className='flex py-[7px] px-[36px] justify-center items-center content-center gap-[12px] flex-wrap rounded-[8px] bg-[#52B4DA] mt-[19px]'>
                            <button onClick={moveToDashboard} className=' text-white text-center font-inter text-[15px] font-bold leading-[25px] cursor-pointer '>Go to Dashboard</button>
                        </div>
                    </div>
                  </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>

                  
                  
                
  )
}