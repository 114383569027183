import React, { useState } from 'react'
import VideoCard from './VideoCard'
import clsx from 'clsx'
import { AnimateSpinIcon } from 'components/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { acceptApplicant, rejectApplicant } from 'app/store/applicantsSlice'
import {
  getNewApplicantsByJobID,
  getRejectedApplicantsByJobID,
  getShortlistedApplicantsByJobID,
} from 'app/store/jobSlice'
import { useNavigate } from 'react-router-dom'
import { fetchChatsNo, getChatsNo, getUser } from 'app/store/profileSlice'
import ChatNoPermissionModal from 'components/Dialog/ChatNoPermissionModal'
import { createUserChat } from 'app/store/chatSlice'

function CandidateVideoCard({
  isBlur,
  tab,
  data,
  applicantData,
  refresh,
  setRefresh,
  currentPage,
  numberPerPage,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const profile = useSelector(getUser)
  const chatLimits = useSelector(getChatsNo)
  const userId = useSelector((state) => state.auth.userId)
  const shortListLoading = useSelector(
    (state) =>
      state.applicants.acceptApplicantsLoading[applicantData?.id] === 'pending'
  )
  const rejectLoading = useSelector(
    (state) =>
      state.applicants.rejectApplicantsLoading[applicantData?.id] === 'pending'
  )
  const [openChatNoPermissionModal, setOpenChatNoPermissionModal] =
    useState(false)

  const handleReject = async (id) => {
    try {
      const result = await dispatch(rejectApplicant(id))
      console.log(result)
      if (result.meta.requestStatus === 'fulfilled') {
        const params = {
          jobId: applicantData?.jobId,
          offset: (currentPage - 1) * numberPerPage.name,
          limit: numberPerPage.name,
        }
        dispatch(getNewApplicantsByJobID(params)) // Refresh the list after successful rejection
        dispatch(getRejectedApplicantsByJobID(params))
        dispatch(getShortlistedApplicantsByJobID(params))
        setRefresh(!refresh)
      }
    } catch (error) {
      console.error('Failed to reject applicant:', error)
    }
  }

  const handleAccept = async (id) => {
    try {
      const result = await dispatch(acceptApplicant(id))
      console.log(result)
      if (result.meta.requestStatus === 'fulfilled') {
        const params = {
          jobId: applicantData?.jobId,
          offset: (currentPage - 1) * numberPerPage.name,
          limit: numberPerPage.name,
        }
        dispatch(getNewApplicantsByJobID(params)) // Refresh the list after successful rejection
        dispatch(getRejectedApplicantsByJobID(params))
        dispatch(getShortlistedApplicantsByJobID(params))
        setRefresh(!refresh)
      }
    } catch (error) {
      console.error('Failed to reject applicant:', error)
    }
  }

  const handleChat = async (senderID, receiverID) => {
    {
      /* PREMIUM start comment this out no premium user */
    }
    if (profile.plan === 'basic' && chatLimits >= 50) {
      setOpenChatNoPermissionModal(true)
    } else {
      dispatch(createUserChat({ senderID, receiverID }))
      // navigate(`/employer/messages?chatId=${data.id}`)
      dispatch(
        fetchChatsNo({
          userID: userId,
        })
      )
      navigate(`/employer/messages`)
    }
    {
      /* end comment this out no premium user */
    }
  }

  const handleContextMenu = (event) => {
    // Prevent the default right-click menu from appearing
    event.preventDefault()

    // Open the component (or a specific path) in a new tab
    window.open(`/employer/profile/${data?.id}`, '_blank')
  }

  const moveToCandidateDetail = () => {
    if (!isBlur) {
      navigate(`/employer/profile/${data?.id}`)
      // localStorage.setItem('ai_selected', id)
    }
  }

  const upgradeNow = () => {
    navigate('/employer/upgrade')
  }

  // console.log(applicantData)
  return (
    <div
      onContextMenu={handleContextMenu}
      className={clsx(
        'flex relative flex-col items-center max-w-[296px] pt-[39px] pb-[14px] bg-[#F7F8FC] rounded-[12px] ',
        isBlur && 'blur-sm'
        // tab.id === 'invites' &&
        //   ' w-full bg-green-200 text-white text-center font-bold'
      )}
    >
      {tab.id === 'Rejected Applicants' && (
        <div
          className={`absolute top-0 left-0 w-full text-white text-[10px] leading-[16.74px] text-center uppercase font-poppins font-bold py-1 rounded-t-lg ${
            applicantData?.status === 'rejected'
              ? 'bg-[#F69772]'
              : 'bg-gray-200'
          }`}
        >
          {applicantData?.status === 'rejected'
            ? 'REJECTED!'
            : 'UNKNOWN STATUS'}
        </div>
      )}

      {(tab.id === 'Invites' ||
        (tab.id === 'New Applicants' &&
          applicantData?.status === 'accepted')) && (
        <div
          className={`absolute top-0 left-0 w-full text-white text-[10px] leading-[16.74px] text-center uppercase font-poppins font-bold py-1 rounded-t-lg ${
            applicantData?.status === 'pending'
              ? 'bg-[#52B4DA]'
              : applicantData?.status === 'accepted'
              ? 'bg-[#58D058]'
              : applicantData?.status === 'rejected'
              ? 'bg-[#F69772]'
              : 'bg-gray-200'
          }`}
        >
          {applicantData?.status === 'pending'
            ? 'INVITE PENDING'
            : applicantData?.status === 'accepted'
            ? 'INVITE ACCEPTED'
            : applicantData?.status === 'rejected'
            ? 'INVITE REJECTED'
            : 'UNKNOWN STATUS'}
        </div>
      )}
      <div className="flex gap-[10px]">
        {applicantData.coverLetterVideo && (
          <div className="w-full">
            <div className="mb-[12px]">
              <p className="text-[#1E3E85] font-inter text-[12px] not-italic font-semibold ">
                Cover Letter Video
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="93"
                height="2"
                viewBox="0 0 93 2"
                fill="none"
              >
                <path
                  d="M1.70822 1H91.7082"
                  stroke="#FFC628"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <VideoCard
              // name="Paul"
              avatar={data?.avatar}
              video={applicantData?.coverLetterVideo}
              // job_title="SoftWare Engineer"
              // country="Kenya"
            />
          </div>
        )}
        {data?.video && (
          <div>
            <div className="mb-[12px]">
              <p className="text-[#1E3E85] font-inter text-[12px] not-italic font-semibold ">
                Introductory Video
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="93"
                height="2"
                viewBox="0 0 93 2"
                fill="none"
              >
                <path
                  d="M1.70822 1H91.7082"
                  stroke="#FFC628"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <VideoCard
              // name={data?.name?.split(' ')[0]}
              avatar={data?.avatar}
              video={data?.video}
              // job_title={data?.job_title}
              // country={data?.country}
            />
          </div>
        )}
      </div>
      <div
        onClick={moveToCandidateDetail}
        className="flex px-[15px] cursor-pointer "
      >
        <div className="flex flex-col ">
          <div className="flex justify-between">
            <div>
              <p className="text-[#1E3E85] font-inter text-[12px] not-italic font-semibold capitalize break-words whitespace-normal ">
                {data?.name?.split(' ')[0]}
              </p>
              <p className="text-[#11142D] font-poppins text-[12px] not-italic font-medium capitalize ">
                {data?.job_title}
              </p>
            </div>
            <div>
              {data?.hourly_rate > 0 && (
                <div className="w-[92.4px] h-[47.04px] rounded-[8.4px] bg-[#F7F8FC] border-[0.84px] border-[#E0E5F9] mb-[4.2px] px-[6px] py-[8px] ">
                  <p className="text-center text-[#52B4DA] font-inter text-[11.76px] not-italic font-bold ">
                    ${data?.hourly_rate}
                  </p>
                  <p className="text-[#808191] text-center font-inter text-[10.08px] not-italic font-medium leading-[15px] ">
                    per hour
                  </p>
                </div>
              )}
              {/* <div className="w-[92.4px] h-[47.04px] rounded-[8.4px] bg-[#F7F8FC] border-[0.84px] border-[#E0E5F9] pt-[7px] ">
            <p className="text-[#52B4DA] text-center font-inter text-[15px] not-italic font-bold ">
              $250K
            </p>
            <p className="text-[#808191] text-center font-inter text-[10.08px] not-italic font-medium">
              Annual
            </p>
          </div> */}
            </div>
          </div>

          {/* <p className="text-[#808191] font-manrope text-[10px] not-italic font-medium leading-3 ">
            Building Solutions, One Line of Code at a Time
          </p> */}
          <div className="flex gap-[8px] mt-[12px] flex-wrap">
            {data?.skills?.slice(0, 3)?.map((skill, id) => (
              <div
                key={id}
                className="flex py-[4px] px-[12px] items-start bg-[#1E3E85] rounded-[4px] "
              >
                <p className="text-[#FFF] text-center font-inter text-[10px] not-italic font-semibold leading-[12px] break-words whitespace-normal ">
                  {skill}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {tab?.id === 'New Applicants' && (
        <div className="flex gap-[14px] mt-[12px] bottom-4">
          <div
            onClick={() => handleAccept(applicantData?.id)}
            className="flex items-center justify-center gap-[10px] rounded-[8px] bg-[#35BE35] w-[122px] sm:w-[90px] 3xl:w-[122px] h-[32px] px-[20px] sm:px-[0px] 3xl:px-[20px] py-[6px] cursor-pointer  "
          >
            {shortListLoading ? (
              <AnimateSpinIcon className="w-3 h-3" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M12.3749 3.60938L5.95823 10.026L3.04156 7.10938"
                  stroke="#FFFFFE"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <p className="text-[#FFF] text-center font-inter text-[12px] sm:text-[10px] 3xl:text-[12px] not-italic font-bold leading-[20px] ">
              {shortListLoading ? 'ShortList' : 'ShortList'}
            </p>
          </div>
          <div
            onClick={() => handleReject(applicantData?.id)}
            disabled={rejectLoading}
            className="flex items-center justify-center gap-[10px] rounded-[8px] bg-[#C83F3F] w-[122px] sm:w-[90px] 3xl:w-[122px] h-[32px] px-[24px] sm:px-[12px] 3xl:px-[24px] py-[6px] cursor-pointer  "
          >
            {rejectLoading ? (
              <AnimateSpinIcon className="w-3 h-3 ml-2" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 9 9"
                fill="none"
              >
                <g clip-path="url(#clip0_13197_51678)">
                  <path
                    d="M5.44114 4.11717L8.55618 1.00203C8.75891 0.799404 8.75891 0.471784 8.55618 0.269158C8.35356 0.0665308 8.02594 0.0665308 7.82331 0.269158L4.70818 3.38429L1.59314 0.269158C1.39041 0.0665308 1.06289 0.0665308 0.860263 0.269158C0.657541 0.471784 0.657541 0.799404 0.860263 1.00203L3.9753 4.11717L0.860263 7.2323C0.657541 7.43493 0.657541 7.76255 0.860263 7.96518C0.961244 8.06625 1.09402 8.11703 1.2267 8.11703C1.35938 8.11703 1.49206 8.06625 1.59314 7.96518L4.70818 4.85004L7.82331 7.96518C7.92439 8.06625 8.05707 8.11703 8.18975 8.11703C8.32243 8.11703 8.45511 8.06625 8.55618 7.96518C8.75891 7.76255 8.75891 7.43493 8.55618 7.2323L5.44114 4.11717Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13197_51678">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(0.708221 0.109375)"
                    />
                  </clipPath>
                </defs>
              </svg>
            )}
            <p className="text-[#FFF] text-center font-inter text-[12px] sm:text-[10px] 3xl:text-[12px] not-italic font-bold leading-[20px] ">
              {rejectLoading ? 'Reject' : 'Reject'}
            </p>
          </div>
        </div>
      )}
      {tab?.id === 'Shortlisted Applicants' && (
        <div className="flex gap-[14px] mt-[12px]">
          <div
            onClick={() => handleChat(userId, applicantData?.user?.id)}
            className=" flex items-start gap-[10px] px-[24px] sm:px-[12px] 3xl:px-[24px] py-[6px] bg-[#52B4DA] rounded-[8px]  cursor-pointer  "
          >
            <div className="flex gap-[8px] items-center justify-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M12.958 7.49741C12.96 8.26734 12.7801 9.02685 12.433 9.71408C12.0214 10.5376 11.3887 11.2303 10.6057 11.7145C9.82268 12.1987 8.92032 12.4554 7.99968 12.4557C7.22975 12.4578 6.47024 12.2779 5.78301 11.9307L2.45801 13.0391L3.56634 9.71408C3.21922 9.02685 3.03933 8.26734 3.04134 7.49741C3.0417 6.57677 3.29836 5.67441 3.78259 4.8914C4.26682 4.10839 4.95949 3.47566 5.78301 3.06408C6.47024 2.71696 7.22975 2.53707 7.99968 2.53908H8.29134C9.50721 2.60616 10.6556 3.11936 11.5167 3.98041C12.3777 4.84147 12.8909 5.98988 12.958 7.20575V7.49741Z"
                  stroke="#FFFFFE"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-white text-center font-inter text-[12px] sm:text-[10px] 3xl:text-[12px] not-italic font-bold leading-[20px] ">
                Message
              </p>
            </div>
          </div>
          <div
            onClick={moveToCandidateDetail}
            className=" flex flex-col items-start gap-[10px] px-[20px] sm:px-[10px] 3xl:px-[20px] py-[6px] border-[1px] border-[#52B4DA] rounded-[8px] cursor-pointer  "
          >
            <p className="text-[#52B4DA] text-center font-inter text-[12px] sm:text-[10px] 3xl:text-[12px] not-italic font-bold leading-[20px] whitespace-nowrap ">
              View Full Profile
            </p>
          </div>
        </div>
      )}
      <ChatNoPermissionModal
        isOpen={openChatNoPermissionModal}
        closeModal={() => setOpenChatNoPermissionModal(false)}
        upgradeNow={upgradeNow}
      />
    </div>
  )
}

export default CandidateVideoCard
