//packages
import React, { useCallback, useEffect, useState } from 'react'

//custom hooks
import useTagList from 'hooks/useTagList'

//custom components
import { CrossIcon, CrossIcon2 } from 'components/Icons'
import SearchAutoComplete from 'components/Select/SearchAutoComplete'
import SearchTag from 'views/LatestHomePage/components/SearchTag'
import tagService from 'app/services/tag.service'
import SuggestedTag from 'views/LatestHomePage/components/SuggestedTag'
import AutoSuggestedTags from './AutoSuggestedTags'

export default function SearchFilter({
  filter,
  setFilter,
  disabled,
  onShowFilterModal,
}) {
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_candidates_keywords')) ?? []
  const [keywords, setKeywords] = useState(keywordsStored)
  const [suggestedTags, setSuggestedTags] = useState([])
  const [key, setKey] = useState(null)
  const [skillsTags, , , jobTitleTags] = useTagList()

  // Create a mapping object
  const tagMapping = {}

  const mergedData = [
    ...skillsTags.map((v) => {
      const uniqueId = `skill-${v.id}`
      tagMapping[uniqueId] = v
      return { id: uniqueId, name: v.label }
    }),
    ...jobTitleTags.map((v) => {
      const uniqueId = `job-${v.id}`
      tagMapping[uniqueId] = v
      return { id: uniqueId, name: v.label }
    }),
  ]

  const getRandomTags = (tagsArray, n) => {
    // Shuffle the array
    // const shuffled = tagsArray.sort(() => 0.5 - Math.random())
    // Return the first n items
    return tagsArray.slice(0, n)
  }

  useEffect(async () => {
    const lastSelectedTagID = keywords[keywords.length - 1]
    const lastSelectedTag = tagMapping[lastSelectedTagID]
    const lastSelectedTagMainID = lastSelectedTag?.mainId
    // console.log(lastSelectedTag?.mainId)
    if (keywords.length > 0) {
      const suggestedTags = await tagService.getSuggestedTags(
        lastSelectedTagMainID
      )
      // Randomly select 6 tags
      const randomlySelectedTags = getRandomTags(suggestedTags.data, 6)

      // Exclude the selected tag from the data
      // const filteredTags = randomlySelectedTags?.filter(
      //   (tag) =>
      //     tag.name !== lastSelectedTag && !keywords.includes(tag.name)
      // )

      const filteredTags = randomlySelectedTags?.filter((tag) => {
        const normalizedSearchTags = keywords.map((searchTag) =>
          searchTag.replace('skill-', '')
        )
        return tag !== lastSelectedTag && !normalizedSearchTags.includes(tag)
      })

      console.log('suggestedTags', suggestedTags.data)
      console.log('randomlySelectedTags', filteredTags)
      setSuggestedTags(filteredTags)
    }
  }, [keywords])

  useEffect(() => {
    if (keywords.length === 0) {
      setFilter((prev) => {
        const temp = { ...prev }
        delete temp.skills
        return temp
      })
    } else {
      const actualKeywords = keywords?.map((key) => {
        if (key.startsWith('skill-')) {
          return tagMapping[key]?.name
        } else {
          return key
        }
      })
      setFilter((prev) => ({
        ...prev,
        skills: actualKeywords,
      }))
    }
  }, [keywords, setFilter])

  const handleChange = useCallback(
    (value) => {
      if (keywords.includes(value.id)) {
        const newKeywords = keywords.filter((v) => v !== value.id)
        setKeywords(newKeywords)
        localStorage.setItem(
          'filter_jobs_keywords',
          JSON.stringify(newKeywords)
        )
      } else {
        const newKeywords = [...keywords, value.id]
        setKeywords(newKeywords)
        localStorage.setItem(
          'filter_jobs_keywords',
          JSON.stringify(newKeywords)
        )
      }
    },
    [keywords]
  )

  const handleRemoveKeyword = useCallback(
    (key) => {
      const newKeywords = keywords.filter((v) => v !== key)
      setKeywords(newKeywords)
      localStorage.setItem('filter_jobs_keywords', JSON.stringify(newKeywords))
    },
    [keywords]
  )

  const getLabelById = (id) => {
    const item = mergedData.find((item) => item.id === id)
    return item ? item.name : ''
  }

  const addSuggestedTag = (tag) => {
    const modifiedTag = `skill-${tag}`
    setKeywords([...keywords, modifiedTag])
    const newArr = suggestedTags.filter((item) => item !== tag)
    setSuggestedTags(newArr)
  }

  return (
    <div className="flex flex-col flex-grow space-y-[12px] md:space-y-6">
      <SearchAutoComplete
        disabled={disabled}
        data={mergedData}
        value={key}
        onChange={handleChange}
        placeholder="Search by skill or job title"
        onShowFilterModal={onShowFilterModal}
      />
      {keywords.length > 0 && (
        <div className="relative flex flex-col justify-start w-full  h-full rounded-[12px] mt-[10px] pt-[0px] pr-[24px] mb-[6.93px] overflow-x-hidden overflow-y-auto">
          <div className="flex relative flex-wrap w-full sm:w-full justify-start items-start gap-[4.219px] mb-[6.93px]">
            <p className="mr-[8.83px] text-white font-manrope text-[16px] not-italic font-medium ">
              Skills Tags:{' '}
            </p>
            {keywords.map((key, id) => (
              <div key={id}>
                <SearchTag
                  name={getLabelById(key)}
                  onClick={() => handleRemoveKeyword(key)}
                />
              </div>
              // <div
              //   key={key}
              //   className="group hover:border-blue-100 transition-all cursor-pointer duration-200 hover:bg-blue-100 inline-flex py-[6px] md:py-1.5 px-[15px] md:px-[17px] border-[2px] border-solid border-gray-500 rounded-xl space-x-4 box-border mr-2"
              // >
              //   <span className="text-[12px] md:text-[14px] leading-[24px] group-hover:text-white text-black-100 font-semibold">
              //     {getLabelById(key)}
              //   </span>
              //   <div
              //     className="cursor-pointer flex items-center"
              //     onClick={() => handleRemoveKeyword(key)}
              //   >
              //     <CrossIcon className="hidden md:block text-[#000000] group-hover:text-white" />
              //     <CrossIcon2 className="block md:hidden" />
              //   </div>
              //  </div>
            ))}
            {suggestedTags?.length > 0 &&
              suggestedTags?.map((key, id) => (
                <div key={id} onClick={() => addSuggestedTag(key)}>
                  {/* <SearchTag name={getLabelById(key)} /> */}
                  <SuggestedTag name={key} />
                </div>
              ))}
          </div>
        </div>
      )}

      {keywords.length <= 0 && (
        <div className="flex w-full sm:w-full justify-start items-start gap-[5px] sm:gap-[11px] flex-wrap mt-[9px] sm:mt-[20px] ">
          <AutoSuggestedTags
            name="Software Engineering"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Design & Creative"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Engineering & Architecture"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Writing & Translation"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Sales & Marketing"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Legal"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Admin & Customer Support"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
          <AutoSuggestedTags
            name="Finance & Accounting"
            filter={filter}
            setFilter={setFilter}
            searchKeywords={keywords}
            setSearchKeywords={setKeywords}
          />
        </div>
      )}
    </div>
  )
}
