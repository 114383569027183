//packages
import React, { useEffect, useState } from 'react'

//custom components
import Checkbox from 'components/Checkbox'
import InputRadioMembership from './Controller/InputRadioMembership'
import PaymentForm from './PaypalPaymentForm'
import StripePaymentForm from './StripePaymentForm'
import { AnimateSpinIcon, BackIcon } from 'components/Icons'

//assets
import Paypal from 'assets/img/Paypal.png'
import { getClientSecret } from 'app/services/auth.service'
import UpgradedSuccessfully from 'components/Modal/UpgradedSuccessfully'

const MembershipForm = ({
  formik,
  onMoveNextStep,
  handleFinish,
  handleFinishRedirect,
  finishing,
  clientToken,
  upgraded,
  setUpgraded,
  handleBack,
}) => {
  // console.log(formik.values)
  const [isUpgradeNow, setIsUpgradeNow] = useState(false)
  const [showUpgradeSuccessModal, setShowUpgradeSuccessModal] = useState(false)
  const [stripeClientToken, setStripeClientToken] = useState('')

  useEffect(() => {
    const fetchStripeClientToken = async () => {
      let result = await getClientSecret()
      setStripeClientToken(result?.data?.paymentIntent?.client_secret)
    }
    fetchStripeClientToken()
  }, [])

  const onPaymentComplete = () => {
    setUpgraded(true)
    handleFinish()
    setShowUpgradeSuccessModal(true)
    // onMoveNextStep()
  }

  useEffect(() => {
    if (formik.values.membership === 'basic') {
      setIsUpgradeNow(false)
    } else {
      setIsUpgradeNow(true)
    }
  }, [formik])

  return (
    <div>
      <div
        onClick={handleBack}
        className="font-semibold text-[16px] text-blue-300 flex gap-2 cursor-pointer mb-4"
      >
        <BackIcon size={18} />
        BACK
      </div>
      <p className="leading-[16px] text-gray-300 text-[12px] md:text-xs font-medium">
        Membership
      </p>
      <div className="md:h-[456px] mt-[20px] md:mt-8 mb-[80px]">
        <InputRadioMembership
          type="radio"
          id="basic"
          name="membership"
          value={formik.values.membership}
          onHandleChange={(param) => {
            if (!upgraded) formik.setFieldValue('membership', param)
          }}
        />
      </div>
      {formik.values.membership === 'basic' ? (
        <div className="mt-14">
          <div
            // disabled={formik.isSubmitting}

            // onClick={onMoveNextStep}
            onClick={() => handleFinishRedirect()}
            className="py-[18px] cursor-pointer hover:text-blue-100 hover:border-blue-100 hover:bg-white flex justify-center items-center text-white leading-5 text-sm font-bold border-[2px] border-solid border-blue-100 rounded-2xl bg-blue-100"
          >
            Finish
            {finishing && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
          </div>
         </div>
      ) : (
        <div>
          {!isUpgradeNow ? (
            <div className="mt-[18px] md:mt-10 space-y-[20px] md:space-y-[31px]">
              <p className="text-[16px] md:text-lg leading-[24px] text-black-100 text-center font-medium">
                Would you like to upgrade to premium?
              </p>
              <div className="grid grid-cols-2 gap-x-[24px]">
                <div
                  onClick={() => setIsUpgradeNow(false)}
                  className="py-[18px] cursor-pointer hover:text-blue-100 hover:border-blue-100 flex justify-center items-center  text-black-100 leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-gray-400 rounded-2xl"
                >
                  No
                </div>
                <div
                  onClick={() => setIsUpgradeNow(true)}
                  className="py-[18px] cursor-pointer hover:text-blue-100 hover:border-blue-100 flex justify-center items-center text-white leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-blue-100 rounded-2xl bg-blue-100"
                >
                  Yes
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-[18px] md:mt-10 space-y-[20px] md:space-y-[31px]">
              {!upgraded && (
                <div>
                  <p className="text-[12px] md:text-lg  leading-[24px] text-black-100 font-semibold">
                    Would you like to upgrade now?
                  </p>
                  <div className="flex space-x-[16px] mt-5">
                    <div
                      className="w-[100px] py-[10px] cursor-pointer hover:text-blue-100 hover:border-blue-100 flex justify-center items-center  text-gray-100 leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-gray-400 rounded-2xl"
                      onClick={() => {
                        formik.setFieldValue('membership', 'basic')
                        // onMoveNextStep()
                      }}
                    >
                      No
                    </div>
                    <div
                      onClick={() => setIsUpgradeNow(true)}
                      className="w-[100px] py-[10px] cursor-pointer hover:text-blue-100 hover:border-blue-100 flex justify-center items-center  text-black-100 leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-blue-100 rounded-2xl"
                    >
                      Yes
                    </div>
                  </div>
                </div>
              )}

              <div>
                <p className="text-[14px] md:text-sm leading-[16px] text-black-100 font-semibold">
                  Payment Method
                </p>
                <div className="flex space-x-[17px] mt-8">
                  <div className="border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl">
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue(
                          'method',
                          formik.values.method === 'stripe' ? '' : 'stripe'
                        )
                      }
                      value={formik.values.method === 'stripe'}
                      label={
                        <div className="text-black-100 text-[12px] md:text-base font-medium mt-1">
                          Credit Card
                        </div>
                      }
                    />
                  </div>
                  <div className="border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl">
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue(
                          'method',
                          formik.values.method === 'paypal' ? '' : 'paypal'
                        )
                      }
                      value={formik.values.method === 'paypal'}
                      label={<img alt="paypal" src={Paypal} />}
                    />
                  </div>
                </div>
                {upgraded ? (
                  <div className="text-xs leading-[24px] text-black-100 font-semibold my-4 text-center">
                    You have already made transaction
                  </div>
                ) : (
                  <div>
                    {formik.values.method === 'paypal' && (
                      <PaymentForm
                        clientToken={clientToken}
                        clientID={process.env.REACT_APP_PAYPAL_CLIENT_ID}
                        onPaymentComplete={onPaymentComplete}
                        formik={formik}
                      />
                    )}
                    {formik.values.method === 'stripe' && (
                      <StripePaymentForm
                        token={stripeClientToken}
                        onSuccessPaid={onPaymentComplete}
                        onBack={handleBack}
                      />
                    )}
                  </div>
                )}
              </div>
              {upgraded && (
                <div className="grid grid-cols-2 gap-x-[24px] mt-[18px] md:mt-8">
                  <button
                    onClick={handleBack}
                    className="diabled:opacity-50 py-[18px] cursor-pointer hover:text-blue-100 hover:border-blue-100 flex justify-center items-center  text-black-100 leading-5 text-sm font-bold border-[2px] border-solid border-gray-400 rounded-2xl"
                  >
                    Back
                  </button>
                  <button
                    onClick={onPaymentComplete}
                    className="disabled:opacity-50 hover:text-white py-[18px] cursor-pointer hover:border-blue-100 flex justify-center items-center text-white leading-5 text-sm font-bold border-[2px] border-solid border-blue-100 rounded-2xl bg-blue-100"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <UpgradedSuccessfully isOpen={showUpgradeSuccessModal}
        closeModal={() => setShowUpgradeSuccessModal(false)}/>
    </div>
  )
}

export default MembershipForm
