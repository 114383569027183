import clsx from 'clsx'
import React, { useState, useRef, useEffect } from 'react'

export default function TextArea({
  className = '',
  label,
  error,
  labelClassName = 'block text-[12px] md:text-xs',
  labelTextClassName = 'text-gray-300',
  ...rest
}) {
  const [isFocus, setIsFocus] = useState(false)
  const textAreaRef = useRef(null)

  // Function to adjust height dynamically
  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto' // Reset height to recalculate
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // Set to new height
    }
  }

  useEffect(() => {
    adjustHeight() // Adjust height if default text is present
  }, [])

  return (
    <div className={clsx('space-y-3 group', className)}>
      {label && (
        <label
          className={clsx(
            labelClassName,
            isFocus ? 'text-blue-100' : labelTextClassName
          )}
        >
          {label}
        </label>
      )}
      <textarea
        {...rest}
        ref={textAreaRef}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onInput={adjustHeight} // Adjust height as text is entered
        className={clsx(
          'leading-5 border-solid border-[2px] box-sizing text-[#11142D] text-[14px] md:text-sm py-[18px] px-[15px] md:px-[23px] w-full bg-[rgba(228,228,228,0.25)] rounded-lg resize-none', // Prevent manual resizing
          isFocus ? 'border-blue-100' : 'border-transparent'
        )}
        style={{ overflow: 'hidden' }} // Hide scrollbar
      />
      {error && (
        <div
          className="text-red-300 text-[12px] md:text-xs font-semibold"
          style={{ marginTop: '6px' }}
        >
          {error}
        </div>
      )}
    </div>
  )
}
