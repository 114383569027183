//packages
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReactPlayer from 'react-player'

//custom components
import Button from 'components/Button'
import { AddNewIcon } from 'components/Icons'
import EditProjectModal from 'components/Modal/EditProjectModal'
import AddNewProjectModal from '../../profile/components/AddNewProjectModal'
import { FolderIcon, PortfolioPlaceholderIcon } from 'components/Icons'

//redux & apis
import { getUser } from 'app/store/profileSlice'

//assets
import { deleteProject } from 'app/store/profileSlice'
import PortfolioSample from 'assets/images/portfolio-placeholder.png'

export default function PortfolioPanel() {
  const [showAddNewProjectModal, setShowAddNewProjectModal] = useState(false)
  const [showEditProjectModal, setShowEditProjectModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  if (!profile) return null

  const onDeleteHandler = useCallback(
    async (project) => {
      setIsDeleting(true)
      const params = {
        userId: profile.id,
        projectId: project ? project.id : selectedProject.id,
      }

      let resultAction = await dispatch(deleteProject(params))
      if (deleteProject.fulfilled.match(resultAction)) {
        toast.success('Deleted Project Successfully')
        setShowEditProjectModal(false)
        setIsDeleting(false)
      } else {
        if (resultAction.payload) {
          toast.error(
            'Deleted Project failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Deleted Project failed' + resultAction.error.message)
        }
        setIsDeleting(false)
      }
    },
    [profile, selectedProject]
  )

  const isNoPortfolios =
    !_.isArray(profile.projects) || profile.projects.length === 0

  return (
    <div>
      {' '}
      <div className="flex justify-between">
        <p className="text-black-100 font-medium text-lg leading-6">
          {!isNoPortfolios && profile.projects.length + ' Portfolio Items'}
        </p>
        {!isNoPortfolios && (
          <div
            className="text-green-100 inline-flex items-center space-x-2 cursor-pointer"
            onClick={() => setShowAddNewProjectModal(true)}
          >
            <div className="text-sm font-bold leading-5">Add New</div>
            <AddNewIcon />
          </div>
        )}
      </div>
      {isNoPortfolios && (
        <div className="w-full">
          <div className="w-full flex justify-center">
            <FolderIcon />
          </div>
          <div className="text-center text-gray-100 leading-6 text-xl font-inter mt-16">
            You haven't added anything to your portfolio yet.
          </div>
          <div className="flex justify-center mt-12">
            <Button
              onClick={() => setShowAddNewProjectModal(true)}
              variant="primary"
              className="px-21 py-4 text-white leading-[20px] md:leading-5 text-[14px] md:text-sm font-bold font-inter"
            >
              Add Portfolio Item
            </Button>
          </div>
        </div>
      )}
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-9 mt-8">
        {_.isArray(profile.projects) &&
          profile.projects.map((project) => (
            <div
              key={project.id}
              className="bg-white rounded-3xl shadow-xl w-full max-w-[316px] mx-auto"
              onClick={() => {
                setSelectedProject(project)
              }}
            >
              {project.imageUrls &&
                project.imageUrls.length > 0 &&
                (project.imageUrls[0].endsWith('.mp4') ||
                project.imageUrls[0].endsWith('.mp3') ||
                project.imageUrls[0].endsWith('.wav') ||
                project.imageUrls[0].endsWith('.ogg') ||
                project.imageUrls[0].endsWith('.m4a') ||
                project.imageUrls[0].endsWith('.aac') ||
                project.imageUrls[0].endsWith('.flac') ||
                project.imageUrls[0].endsWith('.wma') ||
                project.imageUrls[0].endsWith('.opus') ? (
                  <ReactPlayer
                    width="100%"
                    url={
                      project.imageUrls && project.imageUrls.length > 0
                        ? project.imageUrls[0]
                        : PortfolioSample
                    }
                  />
                ) : project.imageUrls[0].endsWith('.ppt') ||
                  project.imageUrls[0].endsWith('.doc') ||
                  project.imageUrls[0].endsWith('.docx') ||
                  project.imageUrls[0].endsWith('.xls') ||
                  project.imageUrls[0].endsWith('.html') ||
                  project.imageUrls[0].endsWith('.csv') ||
                  project.imageUrls[0].endsWith('.pdf') ||
                  project.imageUrls[0].endsWith('.txt') ? (
                  <div className="w-full h-44 rounded-t-3xl bg-[#E0E5F9] flex justify-center items-center">
                    <PortfolioPlaceholderIcon />
                  </div>
                ) : (
                  <img
                    className="w-full h-44 rounded-t-3xl"
                    src={
                      project.imageUrls && project.imageUrls.length > 0
                        ? project.imageUrls[0]
                        : PortfolioSample
                    }
                  />
                ))}
              <div className="border-b-gray-200 border-b-[1px] text-black-100 leading-6 font-medium text-lg p-6 text-center h-32">
                {project.description}
              </div>
              <div className="py-5 px-6 grid grid-cols-2 gap-x-2">
                <Button
                  variant="secondary"
                  className="w-full text-sm leading-5 font-bold py-2"
                  onClick={() => {
                    setSelectedProject(project)
                    setShowEditProjectModal(true)
                  }}
                  disabled={isDeleting}
                >
                  Edit Project
                </Button>
                <Button
                  variant="error"
                  className="w-full text-sm leading-5 font-bold py-2"
                  onClick={() => {
                    setSelectedProject(project)
                    onDeleteHandler(project)
                  }}
                  disabled={isDeleting}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
      </div>
      <AddNewProjectModal
        isOpen={showAddNewProjectModal}
        closeModal={() => setShowAddNewProjectModal(false)}
      />
      <EditProjectModal
        onDeleteHandler={onDeleteHandler}
        project={selectedProject}
        isDeleting={isDeleting}
        isOpen={showEditProjectModal}
        closeModal={() => setShowEditProjectModal(false)}
      />
    </div>
  )
}
