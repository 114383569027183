import React from 'react'

function HomePageSuggestedTag({
  name,
  filter,
  setFilter,
  searchKeywords,
  setSearchKeywords,
}) {
  const fetchCandidatesByCategory = (text) => {
    if (text === 'Software Engineering') {
      setFilter({
        ...filter,
        skills: ['skill-Software Development', 'skill-Software Engineering'],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Software Development',
        'skill-Software Engineering',
      ])
    }

    if (text === 'Design & Creative') {
      setFilter({
        ...filter,
        skills: [
          'skill-Adobe Illustrator',
          'skill-Figma',
          'skill-Adobe Bridge',
          'skill-Adobe',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Adobe Illustrator',
        'skill-Figma',
        'skill-Adobe Bridge',
        'skill-Adobe',
      ])
    }

    if (text === 'Finance & Accounting') {
      setFilter({
        ...filter,
        skills: [
          'skill-Accounting',
          'skill-Cost Accounting',
          'skill-Financial Accounting',
          'skill-Account Reconciliation',
          'skill-Tax Accounting',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Accounting',
        'skill-Cost Accounting',
        'skill-Financial Accounting',
        'skill-Account Reconciliation',
        'skill-Tax Accounting',
      ])
    }

    if (text === 'Admin & Customer Support') {
      setFilter({
        ...filter,
        skills: [
          'skill-Office Administration',
          'skill-Customer Service',
          'skill-Customer Success',
          'skill-Customer Satisfaction',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Office Administration',
        'skill-Customer Service',
        'skill-Customer Success',
        'skill-Customer Satisfaction',
      ])
    }

    if (text === 'Sales & Marketing') {
      setFilter({
        ...filter,
        skills: [
          'skill-Sales',
          'skill-Sales Management',
          'skill-Marketing',
          'skill-Marketing Strategy',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Sales',
        'skill-Sales Management',
        'skill-Marketing',
        'skill-Marketing Strategy',
      ])
    }

    if (text === 'Legal') {
      setFilter({
        ...filter,
        skills: [
          'skill-Legal Writing',
          'skill-Legal Advisory',
          'skill-Legal Research',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Legal Writing',
        'skill-Legal Advisory',
        'skill-Legal Research',
      ])
    }

    if (text === 'Writing & Translation') {
      setFilter({
        ...filter,
        skills: [
          'skill-Writing',
          'skill-Technical Writing',
          'skill-Content Writer',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Writing',
        'skill-Technical Writing',
        'skill-Content Writer',
      ])
    }

    if (text === 'Engineering & Architecture') {
      setFilter({
        ...filter,
        skills: [
          'skill-Engineering',
          'skill-Structural Engineering',
          'skill-Network Engineering',
          'skill-Data Engineering',
        ],
      })
      setSearchKeywords([
        ...searchKeywords,
        'skill-Engineering',
        'skill-Structural Engineering',
        'skill-Network Engineering',
        'skill-Data Engineering',
      ])
    }
  }
  return (
    <div
      onClick={() => fetchCandidatesByCategory(name)}
      className="flex h-[25.385px] sm:h-[32px] px-[10px] py-[4.85px] sm:py-[7.619px] justify-center items-center gap-[4.854px] sm:gap-[7.619px] rounded-[31.238px] bg-[#001957] cursor-pointer "
    >
      <p className="text-[#DBE6FF] text-center font-manrope text-[6.795px] sm:text-[10.667px] not-italic font-normal ">
        {name}
      </p>
    </div>
  )
}

export default HomePageSuggestedTag
