//packages
import { getNames } from 'country-list'
import languageNameMap from 'language-name-map/map'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

//custom hooks
import useTagList from 'hooks/useTagList'

//custom components
import Checkbox from 'components/Checkbox/Checkbox'
import TextField from 'components/Input/TextField'
import AutoComplete from 'components/Select/AutoComplete'
import TagInput from 'components/Input/TagInput'
import NumberInput from 'components/Input/NumberInput'

const typeDurations = [
  {
    id: 'Full-time',
    name: 'Full-time',
  },
  {
    id: 'Part-time',
    name: 'Part-time',
  },
  {
    id: 'Contract/Freelance',
    name: 'Contract/Freelance',
  },
]

export default function FilterBar({
  setFilter,
  setCurrentPage,
  disabled = false,
}) {
  const languageStored =
    JSON.parse(localStorage.getItem('filter_jobs_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_jobs_location')) ?? []
  const durationsStored =
    JSON.parse(localStorage.getItem('filter_jobs_duration')) ?? []
  const minHourlyRateStored =
    localStorage.getItem('filter_jobs_minHourlyRate') ?? ''
  const minAnnualRateStored =
    localStorage.getItem('filter_jobs_minAnnualRate') ?? ''

  const [languages, setLanguages] = useState(languageStored)
  const [locations, setLocations] = useState(locationsStored)
  const [durations, setDurations] = useState(durationsStored)
  const [minHourlyRate, setMinHourlyRate] = useState(minHourlyRateStored)
  const [enableHourly, setEnableHourly] = useState(
    minHourlyRateStored !== '' ? true : false
  )

  const [minAnnualRate, setMinAnnualRate] = useState(minAnnualRateStored)
  const [enableAnnual, setEnableAnnual] = useState(
    minAnnualRateStored !== '' ? true : false
  )
  const [enableNegotiable, setEnableNegotiable] = useState(false)
  const [enableCoverLetterVideoIncluded, setEnableCoverLetterVideoIncluded] =
    useState(false)
  const [enableBoth, setEnableBoth] = useState(
    minAnnualRateStored !== '' && minHourlyRateStored !== '' ? true : false
  )
  const [typingTimeout, setTypingTimeout] = useState(null)

  useEffect(() => {
    // Update "Both" checkbox based on the individual checkboxes
    setEnableBoth(enableHourly && enableAnnual)
  }, [enableHourly, enableAnnual])

  useEffect(() => {
    if (languages.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['languages']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          languages: languages.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('fj_page', 1)
    }
  }, [languages])

  useEffect(() => {
    if (locations.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['countries']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          countries: locations.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('fj_page', 1)
    }
  }, [locations])

  useEffect(() => {
    if (!enableNegotiable) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['negotiable']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          negotiable: true,
        }
      })
    }
  }, [enableNegotiable])

  useEffect(() => {
    if (enableHourly) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            hourly:
              minHourlyRate.length >= 5
                ? Number(minHourlyRate.replace(/,/g, ''))
                : Number(minHourlyRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem('fj_page', 1)
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['hourly']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [minHourlyRate, enableHourly])

  useEffect(() => {
    if (enableAnnual) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            annual:
              minAnnualRate.length >= 5
                ? Number(minAnnualRate.replace(/,/g, ''))
                : Number(minAnnualRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem('fj_page', 1)
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['annual']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [minAnnualRate, enableAnnual])

  useEffect(() => {
    if (durations.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['duration']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          duration: durations.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('fj_page', 1)
    }
  }, [durations])

  const onHandleReset = () => {
    setEnableHourly(false)
    setMinHourlyRate('')
    setEnableAnnual(false)
    setEnableNegotiable(false)
    setEnableCoverLetterVideoIncluded(false)
    setMinAnnualRate('')
    setLanguages([])
    setLocations([])
    setDurations([])
    setFilter(null)

    localStorage.removeItem('filter_jobs_language')
    localStorage.removeItem('filter_jobs_location')
    localStorage.removeItem('filter_jobs_duration')
    localStorage.removeItem('filter_jobs_minHourlyRate')
    localStorage.removeItem('filter_jobs_minAnnualRate')
  }

  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === 'hourly') {
      setEnableHourly(!enableHourly)
      setEnableNegotiable(false)
    } else if (checkboxType === 'annually') {
      setEnableAnnual(!enableAnnual)
      setEnableNegotiable(false)
    } else if (checkboxType === 'coverLetterVideo') {
      setEnableCoverLetterVideoIncluded(!enableCoverLetterVideoIncluded)
    }
  }

  const handleBothCheckboxChange = () => {
    setEnableBoth(!enableBoth)
    setEnableHourly(!enableBoth)
    setEnableAnnual(!enableBoth)
    if (enableNegotiable) {
      setEnableNegotiable(enableBoth)
    }
  }

  const handleNegotiableCheckboxChange = () => {
    setEnableNegotiable(!enableNegotiable)
    if (!enableNegotiable) {
      setEnableHourly(false)
      setEnableAnnual(false)
      setEnableBoth(false)
    }
  }

  return (
    <div className="w-full pl-[35px] pt-0">
      <div className="bg-white rounded-3xl shadow-md p-7">
        <div className="text-black-100 leading-6 text-lg font-medium">
          Filter
        </div>
        <p className="font-inter text-black-100 font-bold text-[14px] md:text-sm leading-[20px] md:leading-5 mt-6">
          Pay
        </p>
        <div className="space-y-[30px] mt-5">
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Hourly"
              value={enableHourly}
              onChange={() => handleCheckboxChange('hourly')}
              disabled={disabled}
            />
            <NumberInput
              placeholder="Min Hourly Rate"
              value={minHourlyRate}
              prefix="$"
              suffix="USD"
              onChange={(e) => {
                setEnableHourly(e.target.value.length > 0)
                setMinHourlyRate(e.target.value)
                localStorage.setItem(
                  'filter_jobs_minHourlyRate',
                  e.target.value
                )
              }}
              // type="number"
              disabled={disabled}
            />
          </div>
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Annual Salary"
              value={enableAnnual}
              onChange={() => handleCheckboxChange('annually')}
              disabled={disabled}
            />
            <NumberInput
              value={minAnnualRate}
              prefix="$"
              suffix="USD"
              onChange={(e) => {
                setEnableAnnual(e.target.value.length > 0)
                setMinAnnualRate(e.target.value)
                localStorage.setItem(
                  'filter_jobs_minAnnualRate',
                  e.target.value
                )
              }}
              placeholder="Min Annual Salary"
              // type="number"
              disabled={disabled}
            />
          </div>
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Both Hourly & Salary"
              value={enableBoth}
              onChange={handleBothCheckboxChange}
              disabled={disabled}
            />
          </div>
          {/* <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Negotiable"
              value={enableNegotiable}
              onChange={handleNegotiableCheckboxChange}
              // disabled={disabled}
            />
          </div> */}
          <div className="space-y-[15px]">
            <p className="font-inter text-black-100 font-bold text-[14px] md:text-sm leading-[20px] md:leading-5">
              Languages Required:
            </p>
            <TagInput
              disabled={disabled}
              multiple={true}
              list={_.map(languageNameMap, (v, k) => {
                return {
                  id: v.name,
                  name: v.name,
                }
              })}
              placeholder="Select Languages"
              value={languages}
              name="language"
              id="language"
              onChange={(value) => {
                setLanguages(
                  _.unionBy(value, function (e) {
                    return e.id
                  })
                )
                const unionVal = _.unionBy(value, function (e) {
                  return e.id
                })
                localStorage.setItem(
                  'filter_jobs_language',
                  JSON.stringify(unionVal)
                )
              }}
              removeItem={(v) => {
                setLanguages((prev) => prev.filter((i) => i.id !== v.id))
                const filterRemoveVal = languages.filter((i) => i.id !== v.id)
                localStorage.setItem(
                  'filter_jobs_language',
                  JSON.stringify(filterRemoveVal)
                )
              }}
            />
          </div>
          <div className="space-y-[15px]">
            <p className="font-inter text-black-100 font-bold text-[14px] md:text-sm leading-[20px] md:leading-5">
              Employer Location:
            </p>
            <TagInput
              multiple={true}
              disabled={disabled}
              list={_.map(getNames(), (v, k) => {
                return {
                  id: v,
                  name: v,
                }
              })}
              placeholder="Select Locations"
              value={locations}
              name="location"
              id="location"
              onChange={(value) => {
                setLocations(
                  _.unionBy(value, function (e) {
                    return e.id
                  })
                )
                const unionVal = _.unionBy(value, function (e) {
                  return e.id
                })
                localStorage.setItem(
                  'filter_jobs_location',
                  JSON.stringify(unionVal)
                )
              }}
              removeItem={(v) => {
                setLocations((prev) => prev.filter((i) => i.id !== v.id))
                const filterRemoveVal = locations.filter((i) => i.id !== v.id)
                localStorage.setItem(
                  'filter_jobs_location',
                  JSON.stringify(filterRemoveVal)
                )
              }}
            />
          </div>
          <div className="space-y-[15px]">
            <p className="font-inter text-black-100 font-bold text-[14px] md:text-sm leading-[20px] md:leading-5">
              Job Type:
            </p>
            <TagInput
              multiple={true}
              disabled={disabled}
              list={_.map(typeDurations, (v, k) => {
                return {
                  id: v.id,
                  name: v.name,
                }
              })}
              placeholder="Select Job Type"
              value={durations}
              name="durations"
              id="durations"
              onChange={(value) => {
                setDurations(
                  _.unionBy(value, function (e) {
                    return e.id
                  })
                )
                const unionVal = _.unionBy(value, function (e) {
                  return e.id
                })
                localStorage.setItem(
                  'filter_jobs_duration',
                  JSON.stringify(unionVal)
                )
              }}
              removeItem={(v) => {
                setDurations((prev) => prev.filter((i) => i.id !== v.id))
                const filterRemoveVal = durations.filter((i) => i.id !== v.id)
                localStorage.setItem(
                  'filter_jobs_duration',
                  JSON.stringify(filterRemoveVal)
                )
              }}
            />
          </div>
          {/* <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Cover Letter Video Included"
              value={enableCoverLetterVideoIncluded}
              onChange={() => handleCheckboxChange('coverLetterVideo')}
              // disabled={disabled}
            />
          </div> */}
        </div>
        <div
          onClick={onHandleReset}
          className="text-[#EF8080] leading-5 text-[14px] md:text-sm font-bold mt-[40px] cursor-pointer"
        >
          Reset all filters
        </div>
      </div>
    </div>
  )
}
