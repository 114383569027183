import Button from 'components/Button'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getClickableLink } from 'app/utils/function'
import ShareOnSocial from 'react-share-on-social'

import { ShareIcon } from 'components/Icons'
import RLogo from 'assets/images/rr-logo.png'

export default function JobDetailPanel({ job }) {
  const navigate = useNavigate()
  const shareUrl =
    process.env.REACT_APP_LIVE_BASE_URL + '/jobseeker/jobs/' + job.id

  const onClickViewCompany = () => {
    navigate(`/jobseeker/profile/${job.createdBy.id}`)
  }

  // check the pay preference use in a job item
  const payPreference = (key) => {
    if (job['pay_preference'].length > 0) {
      if (job['pay_preference'].indexOf(key) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  // check the pay preference mode use in a job item
  const payPreferenceMode = (key) => {
    if (job['pay_preference_mode'].length > 0) {
      if (job['pay_preference_mode'].indexOf(key) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  // console.log('debug: jobs', job)
  return (
    <div className="bg-white rounded-3xl py-[15px] md:py-[30px] px-[15px] md:px-8 shadow-md md:space-y-8">
      <p className="text-black-100 leading-[24px] md:leading-6 text-[16px] md:text-lg font-medium">
        Job Details
      </p>
      <div className="grid grid-cols-[114px_1fr] gap-x-9 md:gap-y-6 mt-[15px] md:mt-0">
        <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
          Employer Name
        </div>
        <div
          onClick={onClickViewCompany}
          className="font-inter cursor-pointer text-blue-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm"
        >
          {job.createdBy.emType === 'individual'
            ? `${job.createdBy.firstName} ${job.createdBy.lastName}`
            : job.createdBy.companyName}
        </div>
        <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
          Job Type
        </div>
        <div className="font-inter text-black leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
          {job.duration}
        </div>

        {job.createdBy.website && (
          <>
            <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
              Website
            </div>
            <div className="text-blue-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm truncate">
              <a
                target="_blank"
                href={getClickableLink(job.createdBy.website)}
                className="text-blue-100"
              >
                <span className="leading-5 font-medium text-sm">
                  {job.createdBy.website}
                </span>
              </a>
            </div>
          </>
        )}

        <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
          Hour Rate
        </div>
        <div>
          {payPreferenceMode('hourlyRange') ? (
            <div className="text-green-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
              ${job['min_hourly_rate']}/hr - ${job['max_hourly_rate']}/hr
            </div>
          ) : (
            <div className="text-green-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
              ${job['exact_hourly_rate']}/hr
            </div>
          )}
        </div>

        <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
          Annual Salary Range
        </div>
        {payPreferenceMode('annualRange') ? (
          <div className="text-green-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
            ${job['min_annual_salary']} - ${job['max_annual_salary']} / year
          </div>
        ) : (
          <div className="text-green-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
            ${job['exact_annual_salary']} / year
          </div>
        )}

        {job.createdBy.emType === 'individual' ? (
          <>
            <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
              Employer's Location
            </div>
            <div className="text-black-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
              {job.createdBy.location}
            </div>
          </>
        ) : (
          <>
            <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
              Employer's Location
            </div>
            <div className="text-black-100 leading-[28px] md:leading-4 font-medium text-[13px] md:text-sm">
              {job.createdBy.country}
            </div>
          </>
        )}
      </div>
      <div className="mt-0 md:mt-6">
        <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium">
          Languages Required
        </div>
        <div className="flex mt-[10px] md:mt-4 flex-wrap">
          {job.language.map((v) => (
            <div
              key={v}
              className="mr-[10px] border inline-block border-[#E0E5F9] border-solid rounded-lg py-2 px-2 text-black-100 leading-4 text-[12px] font-inter md:text-sm font-semibold mb-[10px]"
            >
              {v}
            </div>
          ))}
        </div>
        <div className="text-gray-100 font-inter leading-[28px] md:leading-4 text-[13px] md:text-sm font-medium mt-0 md:mt-6">
          Skills
        </div>
        <div className="flex mt-[10px] md:mt-4 flex-wrap">
          {job.skills.map((v) => (
            <div
              key={v}
              className="mr-[10px] border inline-block border-[#E0E5F9] border-solid rounded-lg py-2 px-2 text-black-100 leading-4 text-[12px] font-inter md:text-sm font-semibold mb-[10px]"
            >
              {v}
            </div>
          ))}
        </div>
      </div>
      <ShareOnSocial
        link={shareUrl}
        linkFavicon={RLogo}
        shareTo={[
          'facebook',
          'twitter',
          'email',
          'whatsapp',
          'telegram',
          'linkedin',
        ]}
        textToShare="Check out this job post!"
        linkTitle={job.title}
        linkMetaDesc={job.duration}
        noReferer
      >
        <Button
          className="w-full text-white text-[14px] bg-green-100 leading-5 font-bold rounded-xl transition-all duration-300 hover:scale-105 disabled:scale-100 disabled:opacity-50"
          variant="text"
        >
          <ShareIcon size={18} fill="#fff" className="mr-2" />
          Share Job Post
        </Button>
      </ShareOnSocial>
    </div>
  )
}
