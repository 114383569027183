import sanitizeHtml from 'sanitize-html'

export function removeExtraSpaces(inputString) {
  // Remove leading and trailing spaces from the entire input
  inputString = inputString?.trim()

  // Sanitize the HTML and customize text nodes
  return sanitizeHtml(inputString, {
    allowedTags: [], // Keep the default allowed tags
    allowedAttributes: sanitizeHtml.defaults.allowedAttributes, // Keep the default allowed attributes
    exclusiveFilter: function (frame) {
      // Remove text nodes that are purely whitespace
      if (frame.tag === undefined && /^\s*$/.test(frame.text)) {
        return true
      }
      return false
    },
    transformText: function (text) {
      // Replace 3 or more consecutive spaces with 2 spaces
      return text.replace(/ {2,}/g, '').replace(/\s{2,}/g, '')
    },
  })
}
