import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

//custom components
import JobDetailPanel from './components/JobDetailPanel'
import NewJobPostingModal from './components/NewJobPostingModal'

//redux
import { getUser } from 'app/store/profileSlice'

//api
import HeaderForEditJob from './components/HeaderForEditJob'
import { getJobWithID } from 'app/store/jobSlice'

export default function YourJobPosts() {
  const [openNewJobPostingModal, setOpenNewJobPostingModal] = useState(false)

  const params = useParams()

  const selectedJob = useSelector((state) => state.job.selectedJob)

  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const fetchJob = useCallback(() => {
    if (user?.id) {
      dispatch(getJobWithID({ id: params?.id, data: { userId: user.id } }))
    }
  }, [user])

  useEffect(() => {
    if (!selectedJob) {
      fetchJob()
    }
  }, [selectedJob])

  if (!user) return null

  return (
    <div className="py-[30px] md:py-12 pl-[15px] md:pl-16 pr-[15px] md:pr-15 flex-1">
      <HeaderForEditJob
        handleShowNowJobPosting={() => setOpenNewJobPostingModal(true)}
        selected={selectedJob}
      />
      <div className="flex max-w-[682px] mx-auto mt-[30px]">
        {selectedJob ? (
          <JobDetailPanel selectedJob={selectedJob} userPlan={user.plan} />
        ) : null}
      </div>
      <NewJobPostingModal
        isOpen={openNewJobPostingModal}
        closeModal={() => setOpenNewJobPostingModal(false)}
      />
    </div>
  )
}
