import { getUser } from 'app/store/profileSlice'
import clsx from 'clsx'
import { BackIcon } from 'components/Icons'
import { PlusIcon } from 'components/Icons'
import SearchInput from 'components/Input/SearchInput'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function HeaderForEditJob({
  selected,
  setSelected,
  activeJobsNumber,
  inactiveJobsNumber,
  handleShowNowJobPosting,
}) {
  const profile = useSelector(getUser)
  const navigate = useNavigate()
console.log(selected)
  const gotoListPage = () => {
    navigate(`/${profile.type}/job-posts/applicants/${selected?.id}`)
  }

  if (!profile) return null

  return (
    <div className="w-full mb-10">
      <div className="text-[text-black-100]">
        <div
          onClick={gotoListPage}
          className="flex items-center space-x-4.5 cursor-pointer"
        >
          <BackIcon />
          <div className="text-[#52B4DA] leading-8 text-2xl font-medium">
            Go back
          </div>
        </div>
        <h3 className="text-[24px] md:text-[48px] leading-[36px] md:leading-[72px] font-semibold">
          Edit Job Post
        </h3>
      </div>
      <div className="md:flex justify-end w-full -mt-14">
        <div className="flex-none">
          <div className="flex space-x-[40px] md:space-x-10 justify-end items-center px-[29px] md:px-0">
            <div className="block">
              <div
                onClick={handleShowNowJobPosting}
                className="cursor-pointer w-12 h-12 bg-green-100 rounded-full flex justify-center items-center"
              >
                <PlusIcon size={12} className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
