import React, { useState } from 'react'
import JobIcon from 'assets/img/jobIcon.png'
import VideoPlayerModal from 'components/Dialog/VideoPlayerModal'
import { useMediaQuery } from 'react-responsive'
const { flag } = require('country-emoji')

function CandidateVideoPreviewCard({ data }) {
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 639 })

  const onVideo = () => {
    setShowVideoPlayerModal(true)
    console.log("I'm Clicked")
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  return (
    <div className="relative min-w-full h-[313px] rounded-[8px] group overflow-hidden mb-[20px]">
      {/* Profile Image */}
      <img
        className="w-full h-full object-cover"
        src={
          data.avatar
            ? data.avatar
            : 'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/blank-profile.png'
        }
        alt={`${data.name}'s profile picture`}
      />

      {/* Hover Tint */}
      <div className="absolute inset-0 bg-[rgba(0,5,17,0.68)] opacity-100 transition-opacity duration-300 pointer-events-none"></div>

      {/* Play Button */}
      {data.video && (
        <div
          onClick={onVideo}
          // style={{
          //   background: 'rgba(255, 255, 255, 0.3)',
          // }}
          className="absolute inset-0 flex items-center justify-center opacity-100 transition-opacity duration-300"
        >
          <div className="py-[14.9px] sm:py-[18.6px] px-[16.9px] sm:px-[21.7px] bg-white bg-opacity-30 rounded-[6.229px] sm:rounded-[8px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[26.205px] sm:w-[34px] h-[31.022px] sm:h-[40px]"
              viewBox="0 0 34 40"
              fill="none"
            >
              <path
                d="M4.83245 0.222095C-1.45326 4.15668 -1.31355 37.1749 4.83245 39.7737C8.54038 41.3408 33.8284 27.9241 33.8284 20.6456C33.8284 13.0427 7.86586 -1.6765 4.83245 0.222095Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}

      {/* Bottom Gradient Info */}
      <div
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 5, 17, 0.00) 0%, rgba(0, 11, 35, 0.27) 17.25%, rgba(0, 5, 17, 0.55) 48.5%)',
        }}
        className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-[9px] sm:p-4 text-white z-50"
      >
        {/* <div className="xs:hidden flex relative justify-center mb-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
          >
            <path
              d="M2.21619 0.0795804C-0.771847 1.94997 -0.70543 17.6459 2.21619 18.8812C3.97884 19.6262 16 13.2483 16 9.78831C16 6.17411 3.65819 -0.822957 2.21619 0.0795804Z"
              fill="white"
            />
          </svg>
        </div> */}

        <div className="flex items-center justify-between mt-1">
          <h3 className="text-white font-manrope text-[15.796px] sm:text-[20.289px] not-italic font-bold leading-normal">
            {data.name.split(' ')[0]}
          </h3>
          {/* Country Section */}
          <div className="flex items-center justify-center gap-[2.62px] sm:gap-1">
            <div className="flex items-center justify-center">
              <span
                className="text-[15.358px]"
                role="img"
                aria-label={`Flag of ${data.country}`}
              >
                {flag(`${data.country}`)}
              </span>
            </div>
            <span className="text-white text-center font-manrope text-[9.851px] sm:text-[12.653px] not-italic font-medium leading-normal whitespace-nowrap">
              {truncateString(data.country, 15)}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between mt-1">
          {/* Role Section */}
          <div className="flex items-center gap-[2.62px] sm:gap-1">
            <img
              src={JobIcon}
              alt="Role Icon"
              className="w-[14.602px] h-[14.602px] sm:w-[15.967px] sm:h-[15.323px]"
            />
            <span className="text-white text-center font-manrope text-[9.873px] sm:text-[12.68px] not-italic font-medium leading-normal whitespace-nowrap">
              {isMobile ? data.job_title : truncateString(data.job_title, 52)}
            </span>
          </div>
        </div>
      </div>
      <VideoPlayerModal
        isOpen={showVideoPlayerModal}
        closeModal={() => setShowVideoPlayerModal(false)}
        url={data.video}
      />
    </div>
  )
}

export default CandidateVideoPreviewCard
