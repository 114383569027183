import React, { useEffect, useState } from 'react'

import SignupCard from 'components/Register/SignUpCard'
import { Helmet } from 'react-helmet'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import { useSearchParams } from 'react-router-dom'

function Register() {
  const [open, setOpen] = useState(true)
  const [searchParams] = useSearchParams();
  const [isGoogleAdUser, setIsGoogleAdUser] = useState(false);

  

  useEffect(() => {
    const gadSource = searchParams.get("gad_source");
    const gclid = searchParams.get("gclid");

    if (gadSource || gclid) {
      setIsGoogleAdUser(true);
    }
  }, [searchParams]);

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="w-116 mx-auto">
      <ScrollToTop />
      <Helmet>
        <title>Join Remote Recruit Today - Remoterecruit.com</title>
        <meta
          name="description"
          content="Sign up and start navigating the global remote job market with Remote Recruit today!"
        />
      </Helmet>
      <SignupCard open={open} handleClose={handleClose} isGoogleAdUser={isGoogleAdUser} />
    </div>
  )
}

export default Register
