import React, { useState, useEffect, useRef } from 'react'
import bgImg from 'assets/img/clientbg.png'
import { useNavigate } from 'react-router-dom'

function GetStartedSection() {
  const [activeTab, setActiveTab] = useState('JobSeeker')
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const navigate = useNavigate()

  const toSignUp = () => {
    navigate('/auth/register')
  }

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleVideoEnded = () => {
    setIsPlaying(false) // Show the SVGs when the video ends
    videoRef.current.src = videoRef.current.src // Reset the video source to trigger a refresh
  }

  useEffect(() => {
    // Listen for the 'ended' event to detect when the video playback is complete
    videoRef.current.addEventListener('ended', handleVideoEnded)

    // Clean up the event listener when the component unmounts
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleVideoEnded)
      }
    }
  }, [])

  // Data for each tab
  const tabContent = {
    JobSeeker: {
      videoSrc:
        'https://remote-recruit-website-assets.s3.us-east-1.amazonaws.com/rr-jobseeker-help-optimised.mp4',
      text: 'Find your next job <br/> opportunity and showcase <br/> your talent.',
      buttonText: 'Get Started',
    },
    Employer: {
      videoSrc:
        'https://remote-recruit-website-assets.s3.us-east-1.amazonaws.com/rr-employer-help-optimised.mp4',
      text: 'Post jobs and discover top talent from around the <br/> world.',
      buttonText: 'Get Started',
    },
  }

  return (
    <div id="getStarted" className="flex justify-center">
      <div className="flex flex-col items-center relative w-[1026px] h-full mt-[76px] mb-[80px] sm:mb-[149px]">
        <p className="block sm:inline text-white text-center font-manrope text-[36px] sm:text-5xl not-italic font-light mb-[14px] sm:mb-[34px]">
          Get Started with{' '}
          <span className="block sm:inline text-[#FFC628] font-medium">
            Our Easy Guide
          </span>
        </p>
        <div className="flex justify-around items-center py-[4.69px] px-[11.1px] sm:py-[19px] sm:px-[8px] sm:w-[416px] h-[37.53px] sm:h-[64px] bg-white rounded-[19.942px] sm:rounded-[34px] gap-[15.837px] sm:gap-[27px] mb-[44px] sm:mb-[90px]">
          <button
            className={`flex gap-[5.865px] sm:gap-[10px] justify-center items-center rounded-[15.25px] sm:rounded-[26px] px-[21.7px] py-[4.11px] sm:px-[37px] sm:py-[7px] h-[19.9px] sm:w-[201px] sm:h-[48px] text-[#11142D] text-center font-manrope text-[14.077px] sm:text-[24px] not-italic font-medium leading-normal ${
              activeTab === 'JobSeeker'
                ? 'bg-[#172F57] text-white h-[28.15px] w-[117.89px] sm:w-[127px] sm:h-[34px]'
                : ''
            }`}
            onClick={() => setActiveTab('JobSeeker')}
          >
            JobSeeker
          </button>
          <button
            className={`flex gap-[5.865px] sm:gap-[10px] justify-center items-center rounded-[15.25px] sm:rounded-[26px] px-[21.7px] py-[4.11px] sm:px-[37px] sm:py-[7px] h-[19.9px] sm:w-[201px] sm:h-[48px] text-[#11142D] text-center font-manrope text-[14.077px] sm:text-[24px] not-italic font-medium leading-normal ${
              activeTab === 'Employer'
                ? ' bg-[#172F57] text-white h-[28.15px] w-[117.89px] sm:w-[127px] sm:h-[34px]'
                : ''
            }`}
            onClick={() => setActiveTab('Employer')}
          >
            Employer
          </button>
        </div>

        {/* Main Content */}
        <div className="flex flex-col sm:flex-row w-[409px] sm:min-w-[1026px] h-[506px] sm:h-[395px] items-center">
          {/* Video Section */}
          <div className="relative px-[10px] sm:px-0 sm:pr-[48px] sm:w-full sm:h-full mb-[48px] sm:mb-0">
            <video
              src={tabContent[activeTab].videoSrc}
              alt={`${activeTab} Video`}
              className="w-[409px] sm:w-[569px] h-[261px] sm:h-[395px] object-cover rounded-[16px]"
              poster={!isPlaying ? bgImg : ''}
              style={{
                opacity: 0.56,
                background: `url(${bgImg}) #52B4DA 50% / cover no-repeat`,
              }}
              ref={videoRef}
            />

            <button
              className="absolute inset-0 flex items-center justify-center"
              // style={{ filter: 'blur(8.699999809265137px)' }}
            >
              {!isPlaying && 
              <div
                className="flex items-center py-[16.9px] px-[19.7px] sm:py-[25.6px] sm:px-[29.9px] bg-[#507991] rounded-[23.127px] sm:rounded-[35px] gap-[7.049px] sm:gap-[10.6px] shadow-lg"
                // style={{ filter: 'blur(8.699999809265137px)' }}
                onClick={handlePlayClick}
              >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[30.6px] h-[36.2px] sm:w-[48px] sm:h-[56px]"
                    viewBox="0 0 48 56"
                    fill="none"
                  >
                    <path
                      d="M7.27754 0.833353C-1.36143 6.24098 -1.16941 51.6207 7.27754 55.1924C12.3737 57.3462 47.1291 38.9065 47.1291 28.9031C47.1291 18.4538 11.4466 -1.77605 7.27754 0.833353Z"
                      fill="white"
                    />
                  </svg>
              </div>
                 }
            </button>
          </div>

          {/* Text Section */}
          <div className="flex flex-col sm:w-full text-center justify-center items-center sm:justify-start sm:items-start">
            <div
              className="flex sm:w-full justify-center items-center gap-[6.465px] sm:gap-[9.32px] px-[24.6px] sm:px-[35.417px] py-[3.23px] sm:py-[4.66px] rounded-[21.336px] sm:rounded-[30.757px] border-t-[0.647px] border-l-[0.647px] border-r-[0.647px] sm:border-t-[0.932px] sm:border-l-[0.932px] sm:border-r-[0.932px] border-white border-b-[0.932px] mb-[9px] sm:mb-[17px]"
              style={{
                borderBottomColor: '#11378F', // Blue color for the bottom border
                background:
                  'var(--gradient, linear-gradient(184deg, #FFF 82.02%, #0031A1 95.13%))',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                transform: 'rotate(0.056deg)',
              }}
            >
              <p className="text-white text-center font-manrope text-[12.931px] sm:text-[18.64px] font-light leading-normal not-italic">
                Watch to know how to get started
              </p>
            </div>

            <h2
              dangerouslySetInnerHTML={{ __html: tabContent[activeTab].text }}
              className="text-white text-center sm:text-start sm:self-stretch font-manrope text-[28px] sm:text-[32px] not-italic font-medium leading-normal tracking-[0.79px]"
            ></h2>
            <button
              onClick={toSignUp}
              className="bg-[#FFC628] flex px-[33.8px] py-[11.7px] justify-center items-center gap-[7px] rounded-[4.403px] mt-[17px]"
            >
              {tabContent[activeTab].buttonText}{' '}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="11"
                  viewBox="0 0 28 11"
                  fill="none"
                >
                  <path
                    d="M0.8748 5.29183H25.865L22.3134 1.74027C22.1259 1.55293 22.1259 1.24887 22.3134 1.06152C22.5009 0.874023 22.8046 0.874023 22.9921 1.06152L27.3109 5.38023C27.4338 5.46715 27.5146 5.60977 27.5146 5.77183C27.5146 5.7743 27.514 5.77658 27.5139 5.77904C27.514 5.78429 27.5125 5.78925 27.5124 5.79451C27.5101 5.84144 27.501 5.88623 27.4863 5.92869C27.4776 5.95564 27.4652 5.97996 27.4516 6.00535C27.4395 6.02691 27.4261 6.04732 27.4109 6.06668C27.3974 6.08466 27.3904 6.10578 27.374 6.12214L22.9604 10.5357C22.8667 10.6295 22.7439 10.6764 22.6211 10.6764C22.4982 10.6764 22.3754 10.6295 22.2817 10.5357C22.0942 10.3482 22.0942 10.0445 22.2817 9.85699L25.8868 6.25183H0.8748C0.609802 6.25183 0.3948 6.03699 0.3948 5.77183C0.3948 5.50668 0.609802 5.29183 0.8748 5.29183Z"
                    fill="#001A55"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStartedSection
