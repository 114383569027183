//packages
import clsx from 'clsx'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterBarModal from './components/FilterBarModal'

//custom components
import Button from 'components/Button'
import NewJobPostingModal from '../your-job-posts/components/NewJobPostingModal'
import FilterBar from './components/FilterBar'
import InviteToJobModal from './components/InviteToJobModal'
import NoJobPostModal from './components/NoJobPostModal'
import Results from './components/Results'
import SearchFilter from './components/SearchFilter'
import Dropdown from 'components/dropdown/Dropdown'

//redux
import { fetchCandidates } from 'app/store/candidateSlice'
import { getJobsByUser } from 'app/store/jobSlice'
import { getUser } from 'app/store/profileSlice'

//assets
import NoSearchIcon from 'assets/images/no-result-search.png'

import { PAGINATION_LIST } from 'app/utils/list'
import { getCandidates } from 'app/store/candidateSlice'
import { getCandidatesCount } from 'app/store/candidateSlice'
import compareProfiles from 'app/utils/profileRank'
import { updateUserLastActivity } from 'app/services/auth.service'
import { Helmet } from 'react-helmet'

export default function Candidates() {
  // this is for filters that are saved
  const languageStored =
    JSON.parse(localStorage.getItem('filter_candidates_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_candidates_location')) ?? []
  const maxHourlyRateStored =
    localStorage.getItem('filter_candidates_maxHourlyRate') ?? ''
  const maxAnnualRateStored =
    localStorage.getItem('filter_candidates_maxAnnualRate') ?? ''
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_candidates_keywords')) ?? []
  const can_page = localStorage.getItem('can_page')
    ? Number(localStorage.getItem('can_page'))
    : ''

  const [openNewJobPostingModal, setOpenNewJobPostingModal] = useState(false)
  const [openNoJobPostModal, setOpenNoJobPostModal] = useState(false)
  const [filter, setFilter] = useState((prev) => {
    let tempParam = {}

    // languages
    if (languageStored.length !== 0) {
      tempParam['languages'] = languageStored.map((v) => v.id)
    }

    // locations
    if (locationsStored.length !== 0) {
      tempParam['countries'] = locationsStored.map((v) => v.id)
    }

    // max hourly
    if (maxHourlyRateStored !== '') {
      tempParam['hourly'] =
        maxHourlyRateStored.length >= 5
          ? Number(maxHourlyRateStored.replace(/,/g, ''))
          : Number(maxHourlyRateStored)
    }

    // max annual
    if (maxAnnualRateStored !== '') {
      tempParam['annual'] =
        maxAnnualRateStored.length >= 5
          ? Number(maxAnnualRateStored.replace(/,/g, ''))
          : Number(maxAnnualRateStored)
    }

    // keywords
    if (keywordsStored.length !== 0) {
      tempParam['skills'] = keywordsStored.map((v) => v)
    }

    return tempParam
  })
  const [userIdForInvite, setUserIdForInvite] = useState('')
  const [jobForInvite, setJobForInvite] = useState(null)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showFilterBarModal, setShowFilterBarModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(can_page !== '' ? can_page : 1)

  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.userId)
  const profile = useSelector(getUser)
  const candidates = useSelector(getCandidates)
  const candidatesCount = useSelector(getCandidatesCount)
  const jobs = useSelector((state) => state.job.jobs)

  // Sort the candidates based on the custom comparator
  const sortedCandidates = [...candidates].sort(compareProfiles)

  const isBasicPlan = false

  useEffect(() => {})

  const abortControllerRef = React.useRef(null)

  const fetchList = useCallback(async () => {
    try {
      // Cancel any ongoing request
      if (abortControllerRef.current && !_.isEmpty(filter)) {
        abortControllerRef.current.abort()
        console.log('Aborting previous request...')
        console.log(
          'Request aborted:',
          abortControllerRef.current.signal.aborted
        )
      }

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController()

      // Verify if the signal is properly set up
      // console.log('Signal is set:', abortControllerRef.current.signal)

      setLoading(true)
      let param = {
        offset: (currentPage - 1) * numberPerPage.name,
        limit: numberPerPage.name,
      }
      if (!_.isEmpty(filter)) {
        param = { ...param, filter }
      }

      // Pass the signal to your fetch call
      await dispatch(
        fetchCandidates({ ...param, signal: abortControllerRef.current.signal })
      )

      if (userId) {
        await updateUserLastActivity(userId)
      }
    } catch (err) {
      // Only log errors that aren't from aborting
      if (err.name !== 'AbortError') {
        console.log('debug: err', err)
      }
    } finally {
      setLoading(false)
      // Reset the abortControllerRef to null after fetch completes or errors
      abortControllerRef.current = null
    }
  }, [filter, currentPage, numberPerPage])

  // Cleanup effect to abort any pending request when component unmounts
  useEffect(() => {
    return () => {
      if (abortControllerRef.current && !_.isEmpty(filter)) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  useEffect(async () => {
    fetchList()
  }, [fetchList, currentPage])

  useEffect(async () => {
    if (can_page === '') {
      setCurrentPage(1)
    } else {
      setCurrentPage(can_page)
    }
  }, [numberPerPage])

  const fetchJobsList = useCallback(async () => {
    if (userId && jobs.length === 0) {
      dispatch(getJobsByUser(userId))
    }
  }, [userId, jobs])

  //Update user last
  useEffect(() => {}, [])

  useEffect(() => {
    fetchJobsList()
  }, [])

  useEffect(() => {
    dispatch(getJobsByUser(userId))
  }, [])

  const openInviteModal = (param) => {
    if (jobs.length === 0) {
      // setOpenNewJobPostingModal(true)
      setOpenNoJobPostModal(true)
    } else {
      setJobForInvite(null)
      setUserIdForInvite(param)
      setShowInviteModal(true)
    }
  }

  const onShowFilterModal = () => {
    setShowFilterBarModal(true)
  }

  const onHandleReset = () => {
    setFilter(null)
  }

  const onPageChange = (param) => {
    localStorage.setItem('can_selected', 'res0')
    localStorage.setItem('prev_can_page', currentPage)
    setCurrentPage(param)
    localStorage.setItem('can_page', param)
  }

  if (!profile) return null

  return (
    <div className="md:flex flex-grow max-h-screen px-[15px] md:px-16 pb-[40px] md:pb-0 overflow-visible">
      <Helmet>
        <title>Find Remote Talent - Remoterecruit.com</title>
        <meta
          name="description"
          content="Discover skilled remote candidates from our global talent pool to fill your job openings."
        />
      </Helmet>
      <div className="py-[30px] md:py-12 flex-1 ">
        <div className="flex relative flex-col flex-grow w-full min:h-[278px] pb-4 rounded-[16px] bg-[#1E3E85]">
          <img
            className="absolute sm:w-full w-full max-h-[320px] sm:max-h-full z-0 opacity-10 object-fill rounded-[16px]"
            src="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/bgImg.jpeg"
            alt="remote recruit top-section bg image"
          />
          <div className="flex items-center w-fit ml-[10px] sm:ml-[31px] h-[33px] gap-[4.526px] py-[4px] px-[22px] rounded-[15px] border-[0.2px] border-white mt-[30px] mb-[12px] z-20 ">
            <p className="text-white font-manrope text-[13.81px] sm:text-[17.83px] not-italic font-normal ">
              Hello {profile.firstName}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[15px] h-[15px] sm:w-[20px] sm:h-[20px] "
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M9.86764 0.46875C4.42142 0.46875 -0.0062294 4.8964 -0.0062294 10.3426C-0.0062294 15.7888 4.42142 20.2165 9.86764 20.2165C15.3139 20.2165 19.7415 15.7888 19.7415 10.3426C19.7415 4.8964 15.3139 0.46875 9.86764 0.46875ZM4.67087 9.0954C4.2967 8.99146 4.08883 8.61729 4.19277 8.24313C4.50457 7.1622 5.48156 6.41386 6.60407 6.41386C7.72657 6.41386 8.72435 7.1622 9.01537 8.24313C9.11931 8.61729 8.91144 8.99146 8.53727 9.0954C8.47491 9.11618 8.41255 9.11618 8.35019 9.11618C8.05917 9.11618 7.76815 8.90831 7.685 8.61729C7.53949 8.13919 7.10296 7.78581 6.60407 7.78581C6.10518 7.78581 5.64786 8.1184 5.50235 8.61729C5.4192 8.99146 5.04504 9.19933 4.67087 9.0954ZM13.9003 13.2112C13.152 14.7287 11.5722 15.7057 9.86764 15.7057C8.1631 15.7057 6.58328 14.7287 5.83495 13.1905C5.66865 12.8579 5.81416 12.4421 6.14675 12.2758C6.47935 12.1095 6.89509 12.255 7.06138 12.5876C7.58106 13.6478 8.68278 14.3337 9.86764 14.3337C11.0733 14.3337 12.1334 13.6686 12.6739 12.5876C12.8402 12.255 13.2559 12.1095 13.5885 12.2758C13.9211 12.4421 14.0666 12.8579 13.9003 13.2112ZM15.0644 9.0954C15.0021 9.11618 14.9397 9.11618 14.8773 9.11618C14.5863 9.11618 14.2953 8.90831 14.2121 8.61729C14.0666 8.13919 13.6301 7.78581 13.1104 7.78581C12.6115 7.78581 12.1542 8.1184 12.0295 8.61729C11.9256 8.99146 11.5514 9.19933 11.1772 9.0954C10.8031 8.99146 10.5952 8.61729 10.6991 8.24313C11.0109 7.1622 11.9879 6.41386 13.1104 6.41386C14.2329 6.41386 15.2307 7.1622 15.5217 8.24313C15.6257 8.61729 15.4178 8.99146 15.0644 9.0954Z"
                fill="#FFC628"
              />
            </svg>
          </div>
          <p className="w-full text-white font-manrope text-[24px] sm:text-[32px] ml-[10px] sm:ml-[31px] not-italic font-normal mb-[16px] sm:mb-[22.32px] z-20">
            Looking for your next great hire? Start your search now!
          </p>
          <div
            className={clsx(
              'z-20 ml-[10px] sm:ml-[31px]',
              isBasicPlan && 'blur-sm'
            )}
          >
            <SearchFilter
              disabled={isBasicPlan}
              filter={filter}
              setFilter={setFilter}
              onShowFilterModal={onShowFilterModal}
            />
          </div>
        </div>
        {/* <div className="text-black-100">
          <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
            Welcome, {profile.firstName}!
          </h5>
          <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
            Let's Find Some Help
          </h2>
        </div>
        <div className={clsx(isBasicPlan && 'blur-sm')}>
          <SearchFilter
            disabled={isBasicPlan}
            filter={filter}
            setFilter={setFilter}
            onShowFilterModal={onShowFilterModal}
          />
        </div> */}
        <div className="flex">
          <div
            className={clsx(
              'mt-[38px] pb-[47px] w-full',
              isBasicPlan && 'blur-sm'
            )}
          >
            {!loading && candidates.length === 0 ? (
              <div className="py-[60px] md:py-25 w-full text-center bg-white rounded-3xl shadow-[14px_41px_50px_rgba(49,89,211,0.07)]">
                <div className="w-full flex justify-center">
                  <img alt="no-search-image" src={NoSearchIcon} />
                </div>
                <p className="text-gray-100 leading-[24px] md:leading-6 text-[16px] md:text-xl mt-[60px] md:mt-16">
                  No results found, please try again
                </p>
              </div>
            ) : (
              <div className="space-y-5">
                <div className="flex justify-between items-center">
                  <div className="text-sm font-semibold text-[#808191]">
                    <span className="text-[#1E3E85] font-bold text-sm">
                      {candidatesCount}
                    </span>{' '}
                    candidates found
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="text-[#808191] font-semibold text-sm">
                      View
                    </div>
                    <div className="w-[70px]">
                      <Dropdown
                        data={PAGINATION_LIST}
                        label=""
                        value={numberPerPage}
                        onChange={(data) => {
                          setNumberPerPage(data)
                        }}
                        className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                        parentClassName="w-full"
                        labelClassName="text-[#1E3E85] font-semibold text-sm"
                      />
                    </div>
                    <div className="text-[#808191] text-sm font-semibold">
                      candidates per page
                    </div>
                  </div>
                </div>
                <Results
                  candidates={sortedCandidates}
                  loading={loading}
                  handleInvite={openInviteModal}
                  filter={filter}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  pageSize={numberPerPage.name}
                  count={candidatesCount}
                  isOpen={showInviteModal}
                  jobForInvite={jobForInvite}
                  setJobForInvite={setJobForInvite}
                  closeModal={() => {
                    setShowInviteModal(false)
                    setUserIdForInvite('')
                  }}
                  userIdForInvite={userIdForInvite}
                  setUserIdForInvite={setUserIdForInvite}
                />
              </div>
            )}
          </div>
          {isBasicPlan && (
            <Button
              variant="secondary"
              className="text-white leading-5 text-sm font-bold px-9 py-4.5 absolute bottom-[50px] left-[calc(50%-50px)]"
            >
              Upgrade to see
            </Button>
          )}
          <div
            className={clsx(
              'hidden md:block md:w-[398px] md:mt-[38px]',
              isBasicPlan && 'blur-sm'
            )}
          >
            <FilterBar
              disabled={isBasicPlan}
              filter={filter}
              setFilter={setFilter}
              setCurrentPage={setCurrentPage}
              onReset={onHandleReset}
            />
          </div>
        </div>
      </div>

      <InviteToJobModal
        isOpen={showInviteModal}
        jobForInvite={jobForInvite}
        setJobForInvite={setJobForInvite}
        closeModal={() => {
          setShowInviteModal(false)
          setUserIdForInvite('')
        }}
        userIdForInvite={userIdForInvite}
        setUserIdForInvite={setUserIdForInvite}
      />
      <NoJobPostModal
        isOpen={openNoJobPostModal}
        closeModal={() => {
          setOpenNoJobPostModal(false)
        }}
        openNewJobPost={() => {
          setOpenNewJobPostingModal(true)
          setOpenNoJobPostModal(false)
        }}
      />
      <NewJobPostingModal
        isOpen={openNewJobPostingModal}
        closeModal={() => setOpenNewJobPostingModal(false)}
      />
      <FilterBarModal
        isOpen={showFilterBarModal}
        closeModal={() => setShowFilterBarModal(false)}
        filter={filter}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        disabled={isBasicPlan}
        onHandleReset={onHandleReset}
      />
    </div>
  )
}
