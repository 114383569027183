import React from 'react'
import MessagesSearchBar from './MessagesSearchBar'
import Chat from './Chat'
import { useFetchMessageRecipientUser } from 'hooks/useFetchMessageRecipientUser'

function ChatBar({ chats, setSelectedChat, profile, setIsMobileView }) {
  return (
    <div className="flex flex-col w-full sm:w-[422px] max-h-[65vh] sm:max-h-[75vh] min-h-[65vh] sm:min-h-screen items-center gap-[59.225px] bg-[#FFF] rounded-[9.871px] overflow-y-scroll no-scrollbar ">
      {/* <div className="flex px-[24.7px] w-full pt-[19.74px] justify-between items-center rounded-[12.339px] ">
        <div>
          <p className="text-[#001957] text-center font-poppins text-[24px] sm:text-[29.612px] not-italic font-semibold leading-[36px] sm:leading-[44.419px] tracking-[0.617px] ">
            Messages{' '}
          </p>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
          >
            <path
              d="M14 7.10442H7.83074C6.46786 7.10442 5.36304 8.20925 5.36304 9.57212V23.1445C5.36304 24.5073 6.46786 25.6122 7.83074 25.6122H21.4031C22.766 25.6122 23.8708 24.5073 23.8708 23.1445V16.9752M22.1259 5.35949C23.0896 4.39579 24.652 4.39579 25.6157 5.35949C26.5794 6.32319 26.5794 7.88565 25.6157 8.84935L15.0221 19.4429H11.5323L11.5323 15.9531L22.1259 5.35949Z"
              stroke="#001957"
              stroke-width="2.4677"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div> */}
      <MessagesSearchBar />
      {chats?.map((chat, id) => {
        const { recipientUser } = useFetchMessageRecipientUser(chat, profile)
        return (
          <Chat
            key={id}
            chat={chat}
            setSelectedChat={setSelectedChat}
            recipientUser={recipientUser}
            setIsMobileView={setIsMobileView}
          />
        )
      })}
    </div>
  )
}

export default ChatBar
