//packages
import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrCreateChat } from 'react-chat-engine'
import { createChat, createUser } from 'app/services/chat.service'

//custom components
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { Tab } from 'components/Tabs'
import AboutSection from 'views/contractor/profile/components/AboutSection'
import PortfolioSection from 'views/contractor/profile/components/PortfolioSection'
import NewJobPostingModal from '../your-job-posts/components/NewJobPostingModal'
import InviteToJobModal from '../candidates/components/InviteToJobModal'
import NoJobPostModal from '../candidates/components/NoJobPostModal'

//redux & apis
import { getProfile } from 'app/store/profileSlice'
import {
  ChatIcon2,
  MessageCandidateIcon,
  CirclePlusIcon,
  BackCircleIcon,
} from 'components/Icons'
import ChatNoPermissionModal from 'components/Dialog/ChatNoPermissionModal'
import { getUser } from 'app/store/profileSlice'
import { getChatsNo } from 'app/store/profileSlice'
import { fetchChatsNo } from 'app/store/profileSlice'
import { getJobsByUser } from 'app/store/jobSlice'
import { createUserChat } from 'app/store/chatSlice'

//assets
const TABS = ['About', 'Portfolio']

export default function CandidateProfile() {
  const [user, setUser] = useState(null)
  const [activeTab, setActiveTab] = useState('About')
  const [openChatNoPermissionModal, setOpenChatNoPermissionModal] =
    useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [openNewJobPostingModal, setOpenNewJobPostingModal] = useState(false)
  const [openNoJobPostModal, setOpenNoJobPostModal] = useState(false)
  const [jobForInvite, setJobForInvite] = useState(null)
  const [userIdForInvite, setUserIdForInvite] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const profile = useSelector(getUser)
  const chatLimits = useSelector(getChatsNo)
  const userId = useSelector((state) => state.auth.userId)
  const jobs = useSelector((state) => state.job.jobs)

  useEffect(() => {
    const fetchUser = async () => {
      const result = await getProfile(id)
      setUser(result.data.user)
    }
    fetchUser()
  }, [id])

  // for fetching jobs when reloads this page
  const fetchJobsList = useCallback(async () => {
    if (userId && jobs.length === 0) {
      dispatch(getJobsByUser(userId))
    }
  }, [userId, jobs])

  useEffect(() => {
    fetchJobsList()
  }, [fetchJobsList])

  useEffect(() => {
    dispatch(getJobsByUser(userId))
  }, [])

  const handleChat = async (senderID, receiverID) => {
    {
      /* PREMIUM start comment this out no premium user */
    }
    if (profile.plan === 'basic' && chatLimits >= 5) {
      setOpenChatNoPermissionModal(true)
    } else {
      dispatch(createUserChat({ senderID, receiverID }))
      // navigate(`/employer/messages?chatId=${data.id}`)
      dispatch(
        fetchChatsNo({
          userID: userId,
        })
      )
      navigate(`/employer/messages`)
    }
    {
      /* end comment this out no premium user */
    }
  }

  const handleCreateChat = async (senderID, receiverID) => {
    // const chat = await createChat({ senderID, receiverID })
    dispatch(createUserChat({ senderID, receiverID }))
    // navigate(`/employer/messages?chatId=${data.id}`)
    navigate(`/employer/messages`)
  }

  const upgradeNow = () => {
    navigate('/employer/upgrade')
  }

  const openInviteModal = (param) => {
    if (jobs.length === 0) {
      // setOpenNewJobPostingModal(true)
      setOpenNoJobPostModal(true)
    } else {
      setJobForInvite(null)
      setUserIdForInvite(user.id)
      setShowInviteModal(true)
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  if (!user) return null

  return (
    <div className="md:flex min-h-screen px-[15px] md:px-16">
      <div className="py-[37px] md:py-12 flex-1 relative mb-15 md:mb-0">
        <h5
          onClick={goBack}
          className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl text-black-100 flex space-x-[18px] items-center cursor-pointer"
        >
          <BackCircleIcon />
          <h5 className="text-[#52B4DA] text-[24px] font-medium">Back</h5>
        </h5>
        <div className="bg-white rounded-3xl pl-[20px] md:pl-12 pr-[20px] md:pr-7 py-[20px] md:py-5 relative mt-5 md:mt-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-[25px] md:space-x-8">
              <div className="w-[80px] md:w-28 h-[80px] md:h-28 flex justify-center items-center border-[4px] border-[#0049C6] rounded-full border-solid">
                <Avatar
                  image={user.avatar}
                  className="w-[68px] md:w-20 h-[68px] md:h-20"
                />
              </div>
              <div>
                <h3 className="text-black-100 text-[18px] md:text-5xl leading-[27px] md:leading-18 font-semibold">
                  {user.name}
                </h3>
                <div className="flex md:hidden items-center space-x-6 mt-[15px]">
                  <div className="flex items-center space-x-[15px]">
                    <Button
                      variant="secondary"
                      className="px-[13px] md:px-8 py-[6px] md:py-4.5 flex items-center space-x-[7px] text-[12px] rounded-[5px]"
                      onClick={openInviteModal}
                    >
                      <CirclePlusIcon
                        size={13}
                        className="mr-[7px] text-green-100"
                      />
                      Invite
                    </Button>
                  </div>
                  <div onClick={() => handleChat(userId, user.id)}>
                    <ChatIcon2
                      size={18}
                      className="text-green-100 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex items-center justify-center space-x-2.5">
              <div
                onClick={() => handleChat(userId, user.id)}
                className="w-[140px] h-[48px] rounded-[16px] bg-[#1E3E84] cursor-pointer flex items-center justify-center "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.98999 22.78C9.38999 22.78 8.81999 22.48 8.42999 21.95L7.23001 20.35C7.23001 20.36 7.18 20.33 7.16 20.33H6.79001C3.37001 20.33 1.25 19.4 1.25 14.79V10.79C1.25 6.58001 3.82001 5.48001 5.98001 5.29001C6.22001 5.26001 6.50001 5.25 6.79001 5.25H13.19C16.81 5.25 18.73 7.17001 18.73 10.79V14.79C18.73 15.08 18.72 15.36 18.68 15.63C18.5 17.76 17.4 20.33 13.19 20.33H12.79L11.55 21.95C11.16 22.48 10.59 22.78 9.98999 22.78ZM6.79001 6.75C6.56001 6.75 6.34 6.76 6.13 6.78C3.81 6.98 2.75 8.25001 2.75 10.79V14.79C2.75 18.22 3.81001 18.83 6.79001 18.83H7.19C7.64 18.83 8.14999 19.08 8.42999 19.44L9.63 21.05C9.85001 21.35 10.13 21.35 10.35 21.05L11.55 19.45C11.84 19.06 12.3 18.83 12.79 18.83H13.19C15.73 18.83 17 17.76 17.19 15.48C17.22 15.24 17.23 15.02 17.23 14.79V10.79C17.23 8.00001 15.98 6.75 13.19 6.75H6.79001Z"
                    fill="white"
                  />
                  <path
                    d="M9.99023 14.1895C9.43023 14.1895 8.99023 13.7395 8.99023 13.1895C8.99023 12.6395 9.44023 12.1895 9.99023 12.1895C10.5402 12.1895 10.9902 12.6395 10.9902 13.1895C10.9902 13.7395 10.5502 14.1895 9.99023 14.1895Z"
                    fill="white"
                  />
                  <path
                    d="M13.1895 14.1895C12.6295 14.1895 12.1895 13.7395 12.1895 13.1895C12.1895 12.6395 12.6395 12.1895 13.1895 12.1895C13.7395 12.1895 14.1895 12.6395 14.1895 13.1895C14.1895 13.7395 13.7395 14.1895 13.1895 14.1895Z"
                    fill="white"
                  />
                  <path
                    d="M6.80078 14.1895C6.24078 14.1895 5.80078 13.7395 5.80078 13.1895C5.80078 12.6395 6.25078 12.1895 6.80078 12.1895C7.35078 12.1895 7.80078 12.6395 7.80078 13.1895C7.80078 13.7395 7.35078 14.1895 6.80078 14.1895Z"
                    fill="white"
                  />
                  <path
                    d="M17.9396 16.29C17.7396 16.29 17.5396 16.21 17.3996 16.06C17.2396 15.9 17.1697 15.67 17.1997 15.45C17.2297 15.24 17.2396 15.02 17.2396 14.79V10.79C17.2396 8.00001 15.9897 6.75 13.1997 6.75H6.79963C6.56963 6.75 6.34966 6.76 6.13966 6.78C5.91966 6.81 5.68964 6.72999 5.52964 6.57999C5.36964 6.41999 5.27963 6.20001 5.29963 5.98001C5.47963 3.82001 6.58963 1.25 10.7996 1.25H17.1997C20.8197 1.25 22.7396 3.17001 22.7396 6.79001V10.79C22.7396 15 20.1697 16.1 18.0097 16.29C17.9797 16.29 17.9596 16.29 17.9396 16.29ZM6.91966 5.25H13.1896C16.8096 5.25 18.7297 7.17001 18.7297 10.79V14.66C20.4297 14.24 21.2297 12.99 21.2297 10.79V6.79001C21.2297 4.00001 19.9796 2.75 17.1896 2.75H10.7897C8.58965 2.75 7.34966 3.55 6.91966 5.25Z"
                    fill="white"
                  />
                </svg>
                <p className="text-[#FFF] text-center font-inter text-[14px] not-italic font-bold leading-[20px] ml-[12px] ">
                  Message
                </p>
              </div>
              <div className="flex justify-center items-center">
                <Button
                  variant="secondary"
                  className="px-[23px] py-[14px] w-[130px] h-[48px] shrink-0 text-white text-center font-inter text-sm not-italic font-bold leading-5 whitespace-nowrap"
                  onClick={openInviteModal}
                >
                  Invite to Job
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* Tabs Section */}
        <div className="mt-8">
          <Tab
            list={TABS}
            activeTab={activeTab}
            onChange={() =>
              setActiveTab((prev) => (prev === 'About' ? 'Portfolio' : 'About'))
            }
          />
        </div>

        <div className="mt-6">
          {activeTab === 'About' && <AboutSection profile={user} />}
          {activeTab === 'Portfolio' && (
            <PortfolioSection profile={user} enableAddNew={false} />
          )}
        </div>
      </div>
      <ChatNoPermissionModal
        isOpen={openChatNoPermissionModal}
        closeModal={() => setOpenChatNoPermissionModal(false)}
        upgradeNow={upgradeNow}
      />
      <NoJobPostModal
        isOpen={openNoJobPostModal}
        closeModal={() => {
          setOpenNoJobPostModal(false)
        }}
        openNewJobPost={() => {
          setOpenNewJobPostingModal(true)
          setOpenNoJobPostModal(false)
        }}
      />
      <NewJobPostingModal
        isOpen={openNewJobPostingModal}
        closeModal={() => setOpenNewJobPostingModal(false)}
      />
      <InviteToJobModal
        isOpen={showInviteModal}
        jobForInvite={jobForInvite}
        setJobForInvite={setJobForInvite}
        closeModal={() => {
          setShowInviteModal(false)
          setUserIdForInvite('')
        }}
        userIdForInvite={userIdForInvite}
        setUserIdForInvite={setUserIdForInvite}
      />
    </div>
  )
}
