import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import JobIcon from 'assets/img/jobIcon.png'
const { flag } = require('country-emoji')

function CarauselSection() {
  const videos = [
    {
      name: 'Pablo',
      role: 'Video Editor',
      country: 'Spain',
      coverImg:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/Pablo+Garc%C3%ADa-Roll%C3%A1n+new.png',
      image:
        'https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/pablo-optimised.mp4', // Replace with real image paths
    },
    {
      name: 'Silindo',
      role: 'Writer',
      country: 'South Africa',
      coverImg:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/silindo.png',
      image:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/silindo.mov',
    },
    {
      name: 'Tamara',
      role: 'Finance Lead',
      country: 'Kenya',
      coverImg:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/tamara.png',
      image:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/tamara-optimised.mp4',
    },
    {
      name: 'Muskan',
      role: 'Marketer',
      country: 'Pakistan',
      coverImg:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/muskani.png',
      image:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/muskani.mp4',
    },
    {
      name: 'Michael',
      role: 'Sales Development Rep',
      country: 'Ethiopia',
      coverImg:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/michaels.png',
      image:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/michael.mp4',
    },
    {
      name: 'Maria',
      role: 'Customer Service  ',
      country: 'Philippines',
      coverImg:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/mariaImg.png',
      image:
        'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/maria-optimised.mp4',
    },
  ]
  const videoRefs = useRef([])
  const [playingIndex, setPlayingIndex] = useState(null)
  const navigate = useNavigate()

  const toSignUp = () => {
    navigate('/auth/register')
  }

  const handlePlayClick = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (video) {
        if (i === index) {
          if (video.paused) {
            video.play()
            setPlayingIndex(index) // Set the current video as playing
            console.log('playing')
          } else {
            video.pause()
            setPlayingIndex(null)
            console.log('paused')
          }
        } else {
          video.pause() // Pause other videos
          console.log('paused')
        }
      }
    })
  }

  const handleVideoEnded = () => {
    setPlayingIndex(null) // Reset playing index when video ends
    console.log('Ending')
  }

  // Function to handle mouse scroll
  const handleScroll = (e) => {
    e.preventDefault()
    const container = e.currentTarget
    container.scrollLeft += e.deltaY // Adjust horizontal scroll based on mouse wheel movement
  }
  return (
    <div className="flex justify-center w-full">
      <div className="flex w-full flex-col items-center sm:mb-[96px]">
        <div className="flex flex-col items-center w-[400px] h-auto sm:h-auto sm:w-auto gap-[11px] sm:gap-[30px] px-[29px] py-[45px] sm:pt-[62px] sm:pb-[66.5px] sm:px-[105px] bg-[#001035] rounded-[11.691px] sm:rounded-[16px]">
          <p className="text-white text-center font-manrope  text-[24px] sm:text-[32px] not-italic font-medium leading-normal mb-[11px] sm:mb-[30px]">
            Tired of Bland Resumes? Show Recruiters the Real You!
          </p>
          <button
            onClick={toSignUp}
            className="flex justify-center items-center text-[#001A55] text-[11.691px] sm:text-base  font-manrope font-normal not-italic leading-normal gap-[14.676px] px-[33.8px] py-[11.7px] bg-[#FDB900] rounded-[4.403px]"
          >
            Join Remote Recruit today!
          </button>
        </div>
        <p className="w-[369px] sm:w-[998px] text-white text-center font-manrope text-[32px] sm:text-[55px] not-italic font-light leading-[38.4px] sm:leading-[67.2px] mt-[63.84px] sm:mt-[96px] z-40">
          Explore{' '}
          <span className="text-[#FFC628] font-semibold">Real Talent</span> That
          Fits Your Vision
        </p>
        <p className="relative w-[369px] sm:w-[732px] text-[#DBE6FF] text-center font-manrope text-[11.22px] sm:text-[20px] not-italic font-normal leading-normal mt-[4.91px] sm:mt-[7px] z-40">
          Experience candidates' skills and personalities through video first.
          Find the right fit faster with a face-to-face connection from the
          start.
        </p>

        <div className="w-full border-t border-[#28C4FF] mt-[30.46px] sm:mt-[36px] mb-[14.18px] sm:mb-[16.64px]" />

        {/* Scrollable Div */}
        <div
          onWheel={handleScroll}
          className="flex flex-col relative w-full overflow-x-auto gap-5 mt-[0px] no-scrollbar p-[5.5px]"
        >
          <div className="flex gap-[10px]">
            {videos.map((video, index) => (
              <div
                key={index}
                className="flex items-center justify-center relative w-full sm:h-[380px]"
              >
                <div
                  onClick={() => handlePlayClick(index)}
                  className="absolute px-[14px] py-[10px] sm:px-[20px] sm:py-[16px] rounded-[9px] bg-white bg-opacity-30 z-40 "
                >
                  {playingIndex === index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[16px] h-[20px] sm:w-[22px] sm:h-[26px]"
                      viewBox="0 0 22 26"
                      fill="none"
                    >
                      <rect x="3" y="0" width="6" height="26" fill="white" />
                      <rect x="13" y="0" width="6" height="26" fill="white" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[16px] h-[20px] sm:w-[22px] sm:h-[26px]"
                      viewBox="0 0 22 26"
                      fill="none"
                    >
                      <path
                        d="M3.11222 0.192864C-0.962775 2.74363 -0.872198 24.1492 3.11222 25.8339C5.51605 26.8499 21.9101 18.1519 21.9101 13.4333C21.9101 8.50438 5.07876 -1.03799 3.11222 0.192864Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
                {/* Video Section */}
                <video
                  className="h-[208.928px] min-w-[164.943px] sm:w-full sm:h-full object-cover rounded-[8px]"
                  src={video.image}
                  onEnded={handleVideoEnded}
                  ref={(el) => (videoRefs.current[index] = el)} // Store individual refs
                  poster={video?.coverImg ? video.coverImg : ''}
                />

                {/* Overlay Content */}
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(0, 5, 17, 0.00) 0%, rgba(0, 11, 35, 0.27) 17.25%, rgba(0, 5, 17, 0.55) 48.5%)',
                  }}
                  className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4 text-white"
                >
                  <div className="flex items-center justify-between mt-1">
                    <h3 className="font-bold text-[8.797px] sm:text-lg">
                      {video.name}
                    </h3>
                    {/* Country Section */}
                    <div className="flex items-center gap-1">
                      {/* <img
                        src="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/ethiopia_flag.png"
                        alt="Flag Icon"
                        className="w-[13.358px] h-[13.358px]"
                      /> */}
                      <div className="flex items-center justify-center">
                        <span
                          className="text-[14px] sm:text-[14px]"
                          role="img"
                          aria-label={`Flag of ${video.country}`}
                        >
                          {flag(`${video.country}`)}
                        </span>
                      </div>
                      <span className="text-white text-center font-manrope text-[10px] sm:text-[14px] not-italic font-medium leading-normal">
                        {video.country}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    {/* Role Section */}
                    <div className="flex items-center gap-1">
                      <img
                        src={JobIcon}
                        alt="Role Icon"
                        className="w-[18px] h-[18px]"
                      />
                      <span className="text-white text-center font-manrope text-[10px] sm:text-[14px] not-italic font-medium leading-normal">
                        {video.role}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full border-t border-[#28C4FF] mt-[14.18px] sm:mt-[19.36px] mb-[30.46px]" />
      </div>
    </div>
  )
}

export default CarauselSection
