import React from 'react'

function TalentFromEveryCornerCard({ lowerText, color }) {
  return (
    <div
      className="flex flex-col justify-center items-start gap-[2.602px] h-[82.757px] w-[124px] py-[12.5px] px-[9.89px] rounded-[6.246px] mt-[5.63px] sm:gap-[4.128px] sm:h-[131.266px] sm:w-[198.138px] sm:py-[19.8px] sm:px-[15.7px] sm:rounded-[9.907px] sm:mt-[9.89px]"
      style={{ background: color }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="19"
        viewBox="0 0 14 19"
        fill="none"
      >
        <path
          d="M4.3483 7.29712L2.55843 8.23669C2.49315 8.27095 2.4196 8.28627 2.34607 8.28094C2.27255 8.2756 2.20197 8.24982 2.14233 8.2065C2.08268 8.16318 2.03633 8.10405 2.00851 8.03578C1.98069 7.96751 1.97252 7.89282 1.9849 7.82015L2.32667 5.82709C2.33763 5.76392 2.33302 5.69903 2.31324 5.63804C2.29346 5.57705 2.2591 5.52181 2.21314 5.4771L0.76464 4.06775C0.711892 4.01624 0.674599 3.951 0.656973 3.8794C0.639346 3.80781 0.642089 3.73271 0.664891 3.6626C0.687694 3.59248 0.729647 3.53014 0.786013 3.4826C0.842379 3.43507 0.910912 3.40425 0.983872 3.39361L2.98476 3.10274C3.04859 3.0938 3.10928 3.06941 3.16154 3.03168C3.2138 2.99395 3.25606 2.94402 3.28464 2.88625L4.17801 1.07288C4.21061 1.00675 4.26108 0.95106 4.32369 0.912119C4.3863 0.873177 4.45857 0.852539 4.5323 0.852539C4.60604 0.852539 4.6783 0.873177 4.74091 0.912119C4.80353 0.95106 4.85399 1.00675 4.8866 1.07288L5.78153 2.88625C5.80994 2.94365 5.85185 2.99331 5.90368 3.03095C5.9555 3.06859 6.01569 3.09309 6.07906 3.10235L8.08034 3.39322C8.1533 3.40386 8.22183 3.43468 8.2782 3.48221C8.33456 3.52974 8.37652 3.59209 8.39932 3.66221C8.42212 3.73232 8.42487 3.80742 8.40724 3.87901C8.38961 3.95061 8.35232 4.01584 8.29957 4.06736L6.85107 5.47867C6.80516 5.52342 6.7708 5.57865 6.75095 5.63961C6.73111 5.70058 6.72637 5.76545 6.73715 5.82866L7.07931 7.82171C7.09169 7.89438 7.08352 7.96907 7.0557 8.03734C7.02788 8.10561 6.98153 8.16475 6.92189 8.20807C6.86224 8.25139 6.79167 8.27717 6.71814 8.28251C6.64461 8.28784 6.57106 8.27251 6.50578 8.23825L4.71591 7.29869C4.6593 7.26881 4.59631 7.25306 4.5323 7.25279C4.46829 7.25252 4.40516 7.26772 4.3483 7.29712Z"
          fill="#FFBF11"
        />
        <path
          d="M10.6696 8.83186L9.48575 9.45432C9.4427 9.47686 9.39421 9.48696 9.34574 9.48348C9.29726 9.48 9.25072 9.46308 9.21132 9.43462C9.17193 9.40616 9.14124 9.36729 9.12271 9.32236C9.10418 9.27744 9.09853 9.22823 9.1064 9.18028L9.33229 7.86175C9.33955 7.81998 9.33649 7.77707 9.32339 7.73675C9.31029 7.69643 9.28754 7.65991 9.25712 7.63038L8.29955 6.69669C8.26465 6.66263 8.23998 6.61949 8.2283 6.57214C8.21663 6.5248 8.21843 6.47513 8.2335 6.42876C8.24856 6.38238 8.2763 6.34114 8.31356 6.30969C8.35083 6.27824 8.39615 6.25784 8.4444 6.25079L9.76801 6.05857C9.80977 6.05224 9.84939 6.0359 9.88347 6.01096C9.91756 5.98601 9.9451 5.95318 9.96375 5.91528L10.5557 4.71577C10.5772 4.67181 10.6106 4.63477 10.6521 4.60886C10.6937 4.58295 10.7416 4.56921 10.7906 4.56921C10.8395 4.56921 10.8875 4.58295 10.929 4.60886C10.9705 4.63477 11.0039 4.67181 11.0255 4.71577L11.6174 5.91528C11.636 5.95318 11.6636 5.98601 11.6977 6.01096C11.7318 6.0359 11.7714 6.05224 11.8131 6.05857L13.1383 6.24805C13.1866 6.2551 13.2319 6.2755 13.2692 6.30695C13.3064 6.3384 13.3341 6.37964 13.3492 6.42602C13.3643 6.47239 13.3661 6.52206 13.3544 6.5694C13.3427 6.61675 13.3181 6.65989 13.2832 6.69395L12.3252 7.62764C12.2949 7.65727 12.2723 7.69379 12.2592 7.73409C12.2461 7.77438 12.2429 7.81724 12.25 7.85901L12.4763 9.17754C12.4842 9.22549 12.4785 9.2747 12.46 9.31962C12.4415 9.36455 12.4108 9.40342 12.3714 9.43188C12.332 9.46034 12.2854 9.47726 12.237 9.48074C12.1885 9.48421 12.14 9.47412 12.097 9.45158L10.9131 8.83186C10.8755 8.81217 10.8338 8.80189 10.7914 8.80189C10.7489 8.80189 10.7072 8.81217 10.6696 8.83186Z"
          fill="#FFBF11"
        />
        <path
          d="M4.55806 14.3403C4.64654 14.3998 4.74245 14.4578 4.84346 14.5161C4.96284 14.5837 5.08909 14.6384 5.22007 14.6793C5.23736 14.6852 5.25596 14.6861 5.27374 14.6819C5.29152 14.6777 5.30777 14.6686 5.32062 14.6556C5.33348 14.6426 5.34243 14.6263 5.34645 14.6085C5.35048 14.5907 5.34941 14.5721 5.34338 14.5548C5.27514 14.3753 5.22197 14.1904 5.18444 14.0021C5.12934 13.7118 5.12247 13.4145 5.16408 13.122C5.21967 12.7059 5.2459 11.8716 4.616 11.6434C4.55429 11.6217 4.48849 11.6141 4.42347 11.6213C4.35845 11.6284 4.29585 11.65 4.24029 11.6845C4.18473 11.719 4.13763 11.7656 4.10244 11.8207C4.06726 11.8759 4.04489 11.9382 4.03699 12.0031C3.98534 12.4368 3.95737 12.8729 3.95322 13.3095C3.95056 13.5118 4.00599 13.7106 4.11294 13.8823C4.22807 14.0641 4.37963 14.22 4.55806 14.3403Z"
          fill="#FEF5EF"
        />
        <path
          d="M7.07047 14.6917C7.09323 14.6868 7.11462 14.6769 7.13307 14.6627C7.15153 14.6485 7.1666 14.6304 7.17717 14.6097C7.18775 14.5889 7.19357 14.5661 7.19421 14.5428C7.19484 14.5196 7.19029 14.4965 7.18087 14.4752C7.09709 14.2837 7.04659 14.1268 7.04581 14.0445C7.04111 13.6213 7.05598 12.7072 6.40925 12.5655C6.34972 12.5526 6.28812 12.5524 6.22852 12.565C6.16891 12.5776 6.11267 12.6027 6.06349 12.6387C6.01432 12.6747 5.97333 12.7207 5.94325 12.7736C5.91316 12.8266 5.89466 12.8854 5.88896 12.946C5.82855 13.4896 5.83289 14.0384 5.90188 14.5809C5.90995 14.6366 5.93774 14.6875 5.9802 14.7244C6.02267 14.7614 6.07698 14.7818 6.13325 14.7821H6.154C6.46186 14.7851 6.76914 14.7548 7.07047 14.6917Z"
          fill="#FEF5EF"
        />
        <path
          d="M1.81858 12.8999C1.95442 13.3653 2.21868 14.3311 2.34199 15.1971C2.35699 15.2993 2.39666 15.3963 2.45757 15.4797C2.51848 15.5632 2.59881 15.6305 2.69159 15.6759C3.0588 15.8579 3.68401 16.2208 4.17219 16.7771C4.25858 16.8767 4.37354 16.9472 4.50143 16.9791C4.99627 17.1005 6.28465 17.4063 7.21365 17.5272C8.01388 17.6632 8.8054 17.8462 9.58409 18.0753C9.94946 18.1846 10.3231 18.264 10.7014 18.3125C11.3587 18.3961 12.0202 18.443 12.6827 18.4531C12.7218 18.454 12.7607 18.4472 12.7972 18.433C12.8336 18.4188 12.8669 18.3975 12.895 18.3703C12.9232 18.3431 12.9457 18.3106 12.9612 18.2747C12.9767 18.2388 12.9849 18.2002 12.9853 18.161V15.4598C12.9847 15.3862 12.9589 15.315 12.9123 15.258C12.8656 15.2011 12.8009 15.1618 12.7289 15.1466C12.4024 15.0726 11.843 14.8796 11.5313 14.3942C11.5077 14.3568 11.4873 14.3175 11.4703 14.2767C11.3251 13.9475 11.1203 13.6479 10.8662 13.3931C10.6423 13.1705 10.4967 12.8812 10.4512 12.5687C10.3763 12.096 10.3269 11.6197 10.3033 11.1417C10.3006 11.0959 10.2876 11.0513 10.2651 11.0114C10.2426 10.9714 10.2113 10.9371 10.1735 10.9111C10.1357 10.8851 10.0925 10.8681 10.0471 10.8614C10.0018 10.8547 9.95546 10.8584 9.91177 10.8724C9.57157 10.9761 9.10218 11.2709 8.91035 12.0989C8.8552 12.342 8.84881 12.5936 8.89156 12.8392C8.91035 12.9492 8.93423 13.0776 8.9632 13.2087C9.01553 13.4475 9.00634 13.6956 8.93649 13.9298C8.86664 14.164 8.73843 14.3766 8.56388 14.5476C8.0941 15.0088 7.21325 15.4524 5.58859 15.3192C5.22533 15.2888 4.87299 15.1799 4.55585 15.0002C4.45328 14.9422 4.34288 14.8753 4.23209 14.8005C3.80709 14.5125 3.499 14.0821 3.36338 13.5869C3.2201 13.0702 2.86737 12.3064 2.05425 12.5017C2.01212 12.5115 1.97241 12.5297 1.93752 12.5552C1.90262 12.5808 1.87328 12.6132 1.85125 12.6504C1.82922 12.6876 1.81496 12.7289 1.80935 12.7718C1.80373 12.8147 1.80687 12.8582 1.81858 12.8999Z"
          fill="#FEF5EF"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="11"
        viewBox="0 0 80 11"
        fill="none"
      >
        <g filter="url(#filter0_d_12066_26274)">
          <path
            d="M4.30469 2.12753C4.19309 2.12753 4.10263 2.21799 4.10263 2.32959C4.10263 2.44119 4.19309 2.53165 4.30469 2.53165V2.12753ZM76.3815 2.47247C76.4604 2.39356 76.4604 2.26562 76.3815 2.18671L75.0956 0.900798C75.0167 0.821888 74.8888 0.821888 74.8099 0.900798C74.731 0.979708 74.731 1.10765 74.8099 1.18656L75.9529 2.32959L74.8099 3.47262C74.731 3.55153 74.731 3.67947 74.8099 3.75838C74.8888 3.83729 75.0167 3.83729 75.0956 3.75838L76.3815 2.47247ZM4.30469 2.53165H76.2387V2.12753H4.30469V2.53165Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_12066_26274"
            x="0.869552"
            y="0.841675"
            width="78.8039"
            height="9.4418"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3.23299" />
            <feGaussianBlur stdDeviation="1.61649" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_12066_26274"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_12066_26274"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <p className="max-w-[164px] sm:w-[164px] text-white text-[22.901px] sm:text-[24px] not-italic font-normal sm:font-bold leading-[18.321px] sm:leading-[21.36px] font-Oswald">
        {lowerText}
      </p>
    </div>
  )
}

export default TalentFromEveryCornerCard
