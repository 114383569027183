import { useState } from 'react'

const Accordion = ({ title, children, isOpen, setIsOpen }) => {
  return (
    <div className="rounded-lg mb-2">
      {/* Accordion Header */}
      <button
        type="button"
        className="w-full text-left px-4 py-2 flex justify-between items-center"
      >
        <span className="font-medium">{title}</span>
        <span onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M4.15286 15.1471L10.6412 8.59559C11.4074 7.82187 12.6674 7.81576 13.4411 8.58201L19.9927 15.0703"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.9201 8.9502L13.4001 15.4702C12.6301 16.2402 11.3701 16.2402 10.6001 15.4702L4.08008 8.9502"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </span>
      </button>

      {/* Accordion Content */}
      <div
        className={`transition-all duration-300 overflow-hidden ${
          isOpen ? 'max-h-96 p-4' : 'max-h-0 p-0'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

// const Accordion = () => {
//   return (
//     <div className="w-full max-w-md mx-auto">
//       <AccordionItem title="Accordion Item 1">
//         <p className="text-gray-700">This is the content for item 1.</p>
//       </AccordionItem>
//       <AccordionItem title="Accordion Item 2">
//         <p className="text-gray-700">This is the content for item 2.</p>
//       </AccordionItem>
//       <AccordionItem title="Accordion Item 3">
//         <p className="text-gray-700">This is the content for item 3.</p>
//       </AccordionItem>
//     </div>
//   )
// }

export default Accordion
