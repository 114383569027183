import { getUserChatMessages } from 'app/services/chat.service'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

function Chat({ chat, setSelectedChat, recipientUser, setIsMobileView }) {
  const [chatMessages, setChatMessages] = useState([])

  const getInitials = (recipientUser) => {
    const firstName = recipientUser?.user?.firstName
    const lastName = recipientUser?.user?.lastName

    const firstInitial = firstName ? firstName[0].toUpperCase() : ''
    const lastInitial = lastName ? lastName[0].toUpperCase() : ''
    return `${firstInitial}${lastInitial}`
  }

  useEffect(async () => {
    const chatMessages = await getUserChatMessages({ chatID: chat?.id })
    setChatMessages(chatMessages.data)
  }, [])

  // console.log(chatMessages)

  return (
    <div
      onClick={() => {
        setSelectedChat(chat), setIsMobileView(true)
      }}
      className="flex w-[344px] sm:w-[379px] px-[22px] sm:px-[24.7px] py-[10px] items-center gap-[9.871px] hover:bg-[#E5EAFF] hover:border-l-[4.935px] hover:border-l-[#001957] cursor-pointer "
    >
      <div>
        {recipientUser?.user?.avatar ? (
          <img
            src={recipientUser?.user?.avatar}
            alt="candidate avatar"
            className="flex justify-center w-[53px] h-[53px] sm:w-[59px] sm:h-[59px] rounded-full sm:rounded-[59px] border-[1.234px] "
          />
        ) : (
          <span className="flex w-[53px] h-[53px] sm:w-[59px] sm:h-[59px] rounded-full sm:rounded-[59px] justify-center items-center bg-[#A7F3D0] text-[#059669] text-center font-inter text-[19.742px] not-italic font-bold leading-[25.911px] tracking-[-0.197px] ">
            {getInitials(recipientUser)}
          </span>
        )}
      </div>
      <div className="flex relative flex-col justify-between w-[251px]">
        <div className="flex justify-between items-start ">
          <p className="text-[#000] font-inter text-[15.6px] sm:text-[17.274px] not-italic font-semibold ">
            {recipientUser?.user?.firstName && recipientUser?.user?.lastName
              ? `${recipientUser?.user?.firstName}
              
              ${recipientUser?.user?.lastName}`
              : null}
          </p>
          {chatMessages.length > 0 && (
            <p className="text-[#343434] font-inter text-[13.4px] sm:text-[14.806px] font-normal capitalize ">
              {moment(
                chatMessages[chatMessages.length - 1]?.createdAt
              ).fromNow()}
            </p>
          )}
        </div>
        <div className="flex relative justify-between items-start ">
          {/* <p className="text-[#001957] font-inter text-[14.806px] not-italic font-medium leading-[25.911px] tracking-[-0.148px] ">
            Typing...
          </p> */}
          <p className=" text-[#9D9D9D] truncate font-inter text-[13.4px] sm:text-[14.806px] not-italic font-light leading-[25.911px] tracking-[-0.148px] ">
            {chatMessages[chatMessages.length - 1]?.text}
          </p>
          {/* <p className="flex p-[4px] bg-[#1E3E85] rounded-[5px] text-[#E5EAFF] font-inter text-[9.871px] font-semibold leading-[9.871px] tracking-[-0.099px] ">
            3
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default Chat
