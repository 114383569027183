import { createMessage } from 'app/services/chat.service'
import clsx from 'clsx'
import { useFetchMessageRecipientUser } from 'hooks/useFetchMessageRecipientUser'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import NoChatSelected from 'views/employer/messages/NoChatSelected'
import NoMessages from 'views/employer/messages/NoMessages'
import { Popover } from '@headlessui/react'
import { BlockIcon, DeleteIcon, DetailPopoverIcon } from 'components/Icons'
import Button from 'components/Button'

function MessageBox({
  messages,
  setMessages,
  chatID,
  senderID,
  selectedChat,
  profile,
  setIsMobileView,
}) {
  const [textMessage, setTextMessage] = useState('')
  const [newMessage, setNewMessage] = useState('')
  const { recipientUser } = useFetchMessageRecipientUser(selectedChat, profile)
  const messagesEndRef = useRef(null) // For scrolling to the bottom
  const inputRef = useRef(null) // For focusing input
  console.log(recipientUser)

  const getInitials = (recipientUser) => {
    const firstName = recipientUser?.user?.firstName
    const lastName = recipientUser?.user?.lastName

    const firstInitial = firstName ? firstName[0].toUpperCase() : ''
    const lastInitial = lastName ? lastName[0].toUpperCase() : ''
    return `${firstInitial}${lastInitial}`
  }

  // Scroll to the bottom on component load or messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  // Focus the input on component load
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleChange = (e) => {
    setTextMessage(e.target.value)
    console.log('Test message', textMessage)
  }

  const handleSendMessage = async () => {
    if (textMessage.trim() === '') return // Prevent sending empty messages

    // Clear the input after sending
    setTextMessage('')

    // Call onSend prop to send message to server
    const msg = await createMessage({
      senderID: senderID,
      chatID: chatID,
      text: textMessage,
    })
    setNewMessage(msg.data)
    setMessages((prev) => [...prev, msg.data])
    console.log('Saving message', textMessage)
  }

  // console.log(textMessage)
  // console.log(recipientUser)
  return !recipientUser ? (
    <NoChatSelected />
  ) : (
    <div className="flex flex-col items-start gap-[59.225px] sm:ml-[31px] max-h-[65vh] min-h-[65vh] sm:max-h-screen sm:min-h-screen sm:px-[29.6px] sm:py-[39.5px] bg-[#FFF] rounded-[16px] ">
      <div className="flex justify-between sm:justify-between items-center w-full px-[20px]">
        <div className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            className="sm:hidden"
            onClick={() => setIsMobileView(false)}
          >
            <path
              d="M4.6665 14.9277H23.3331"
              stroke="#141B34"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.4993 20.7604C10.4993 20.7604 4.66604 16.4643 4.66602 14.9271C4.666 13.3899 10.4993 9.09375 10.4993 9.09375"
              stroke="#141B34"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {recipientUser?.user?.avatar ? (
            <img
              src={recipientUser?.user?.avatar}
              alt="Candidate Profile"
              className="flex items-center w-[59px] h-[59px] border-[1.234px] border-[#FFF] rounded-full "
            />
          ) : (
            <span className="flex w-[59px] h-[59px] justify-center items-center rounded-full bg-[#A7F3D0] text-[#059669] text-center font-inter text-[19.742px] not-italic font-bold leading-[25.911px] tracking-[-0.197px] ">
              {getInitials(recipientUser)}
            </span>
          )}
          <div className="ml-[10px]">
            <p className="text-[#000] font-inter text-[14.806px] font-semibold ">
              {recipientUser?.user?.firstName && recipientUser?.user?.lastName
                ? `${recipientUser?.user?.firstName}
              
              ${recipientUser?.user?.lastName}`
                : null}
            </p>
            <p className="text-[#000] font-inter text-[12.339px] font-normal ">
              {recipientUser?.user?.location
                ? recipientUser?.user?.location
                : recipientUser?.user?.country}
            </p>
          </div>
        </div>
        <div>
          <Popover className="relative">
            <Popover.Button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="23"
                viewBox="0 0 6 23"
                fill="none"
              >
                <path
                  d="M3.42753 3.2651L3.42753 3.27744M3.42753 11.9021L3.42753 11.9144M3.42753 20.539L3.42753 20.5513M3.42753 4.49895C2.74609 4.49895 2.19368 3.94654 2.19368 3.2651C2.19368 2.58366 2.74609 2.03125 3.42753 2.03125C4.10896 2.03125 4.66138 2.58366 4.66138 3.2651C4.66138 3.94654 4.10896 4.49895 3.42753 4.49895ZM3.42753 13.1359C2.74609 13.1359 2.19368 12.5835 2.19368 11.9021C2.19368 11.2206 2.74609 10.6682 3.42753 10.6682C4.10896 10.6682 4.66138 11.2206 4.66138 11.9021C4.66138 12.5835 4.10896 13.1359 3.42753 13.1359ZM3.42753 21.7729C2.74609 21.7729 2.19368 21.2204 2.19368 20.539C2.19368 19.8576 2.74609 19.3052 3.42753 19.3052C4.10896 19.3052 4.66138 19.8576 4.66138 20.539C4.66138 21.2204 4.10896 21.7729 3.42753 21.7729Z"
                  stroke="#9FB4FF"
                  stroke-width="2.4677"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Popover.Button>

            <Popover.Panel className="absolute z-10 right-0 bg-white rounded-lg shadow-popover w-[206px] md:w-56 p-[10px] space-y-1">
              <Button
                // onClick={deleteChatHandler}
                style={{ justifyContent: 'flex-start' }}
                className="w-full justify-start rounded-md text-[13px] md:text-sm flex items-center space-x-3 px-3"
                variant="secondary"
              >
                <DeleteIcon />
                <span>Delete Conversation</span>
              </Button>
              <Button
                style={{ justifyContent: 'flex-start' }}
                className="w-full  rounded-md text-[13px] md:text-sm flex items-center space-x-3 justify-start px-3"
                variant="error"
              >
                <BlockIcon />
                <span>Block User</span>
              </Button>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      {/* Messages */}
      {messages.length > 0 ? (
        <div className="flex flex-col flex-grow w-full sm:w-full max-h-[40vh] sm:max-h-[55vh] 2xl:max-h-[70vh] px-[20px]">
          <div className="flex flex-col gap-[19.742px] self-end w-full sm:w-full max-h-screen overflow-y-auto no-scrollbar">
            {messages?.map((message, index) => (
              <>
                <div
                  className={clsx(
                    `${
                      message?.senderID === profile?.id
                        ? 'self-end flex-col'
                        : 'self-start flex-col-reverse'
                    }`,
                    'flex'
                  )}
                >
                  <p className="text-right font-inter text-[9.871px] not-italic font-normal text-[#AAA] ">
                    {moment(message.createdAt).fromNow()}
                  </p>
                  <div
                    className={clsx(
                      `${
                        message?.senderID === profile?.id
                          ? 'bg-[#1E3E85] text-[#E5EAFF]'
                          : 'bg-[#E5EAFF] text-[#1B1B1B]'
                      }`,
                      'flex gap-[1.705px] sm:gap-[12.339px] w-[271px] sm:w-[392.364px] p-[9.871px] items-start min-h-[35px] sm:min-h-[35.782px] rounded-[9.871px_0px_9.871px_9.871px]  font-inter text-[10.229px] sm:text-[14.806px] font-normal leading-[17.901px] tracking-[-0.102px] sm:leading-[25.911px] sm:tracking-[-0.148px] break-all overflow-hidden whitespace-normal '
                    )}
                  >
                    {message.text}{' '}
                  </div>
                </div>
              </>
            ))}
            {/* Scroll Anchor */}
            <div ref={messagesEndRef}></div>
          </div>
        </div>
      ) : (
        <NoMessages />
      )}
      <div className="flex items-center justify-center w-full">
        <div className="bottom-0 flex w-[90vw] sm:w-full px-[10px] sm:px-[14.8px] py-[6.8px] sm:py-[9.87px] rounded-[9.871px] border-[1.234px] border-[#C1CFFF]">
          <div className="flex flex-1">
            {' '}
            {/* Allow flexible width */}
            <input
              ref={inputRef}
              type="text"
              placeholder="Enter your message"
              className="outline-none w-full text-[rgba(102, 102, 102, 0.60)] text-left font-poppins text-[17.274px] not-italic font-normal ml-[9.87px]"
              value={textMessage}
              onChange={handleChange}
              autoFocus
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            />
          </div>
          <button
            onClick={handleSendMessage}
            className="flex ml-[9.87px] p-[14.806px] items-center gap-[12.339px] rounded-[9.871px] bg-[#011137] cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              className="cursor-pointer"
            >
              <path
                d="M2.86335 8.96755L1.21821 16.3707L16.0244 8.96755L1.21821 1.56445L2.86335 8.96755ZM2.86335 8.96755H9.44388"
                stroke="#E5EAFF"
                stroke-width="1.64513"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MessageBox
