import React from 'react'

export default function ProgressBar({
  className,
  percent = 90,
  prefix = false,
  suffix = false,
  bgColor = '#1E3E85', // default background color
  progressColor = 'bg-white', // default progress color class
}) {
  return (
    <div className={`flex items-center ${className}`}>
      {prefix && (
        <span className="mr-2 text-[rgba(17,20,45,0.80)] font-inter text-sm font-bold leading-[1.25rem]">
          0%
        </span>
      )}
      <div className="flex-1">
        <div
          className="h-[4px] w-full rounded-[2px]"
          style={{ backgroundColor: bgColor }}
        >
          <div
            className={`h-full rounded-[2px] ${progressColor}`}
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      </div>
      {suffix && (
        <span className="ml-2 text-[rgba(17,20,45,0.80)] font-inter text-sm font-bold leading-[1.25rem]">
          100%
        </span>
      )}
    </div>
  )
}
