//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'

//custom components
import { CrossIcon } from 'components/Icons'
import { RotateIcon } from 'components/Icons'

export default function VideoPlayerModal({ isOpen, closeModal, url }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[790px] transform overflow-hidden text-left align-middle transition-all">
                <div className="relative">
                  <div className="relative h-21 grid items-center">
                    <div
                      onClick={closeModal}
                      className=" cursor-pointer absolute top-0 right-0 w-12 h-12 flex items-center justify-center bg-white rounded-full"
                    >
                      <CrossIcon className="text-[#11142D]" />
                    </div>
                  </div>
                  <div className="relative w-full rounded-md pt-[56.25%]">
                    <ReactPlayer
                      className="absolute top-0 left-0"
                      url={url}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={true}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
