import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

//custom components
import TextField from 'components/Input/TextField'
import Checkbox from 'components/Checkbox/Checkbox'

//packages
import useTagList from 'hooks/useTagList'

//api & redux
import tagService from 'app/services/tag.service'
import NumberInput from 'components/Input/NumberInput'

export default function InterestForm({ formik }) {
  const [payStyle, setPayStyle] = useState('hourly') //hourly or annual, both
  const [, , interestTagList] = useTagList()

  return (
    <div className="pb-[37px]">
      <header>
        <h3 className="text-[18px] md:text-lg leading-6 text-black-100 font-medium">
          What are you looking for?
        </h3>
      </header>
      <div className="mt-[20px] md:mt-10 space-y-8">
        <p className="text-blue-100 uppercase leading-[16px] text-[12px] md:text-sm font-bold">
          Pay Preferences
        </p>
        <div>
          <p className="font-medium leading-4 text-xs font-inter text-gray-300">
            Pay References
          </p>
          <div className="space-y-4 mt-4">
            <div className="grid grid-cols-2 gap-4">
              <div
                className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg"
                style={{ border: '2px solid var(--Border, #E0E5F9)' }}
              >
                <Checkbox
                  variant="primary"
                  value={payStyle === 'hourly'}
                  onChange={(e) => {
                    // formik.setFieldValue('isHide', !formik.values.isHide)
                    setPayStyle('hourly')
                  }}
                  label="Hourly"
                />
              </div>

              <div
                className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg text-balance"
                style={{ border: '2px solid var(--Border, #E0E5F9)' }}
              >
                <Checkbox
                  variant="primary"
                  value={payStyle === 'annual'}
                  onChange={(e) => {
                    // formik.setFieldValue('isHide', !formik.values.isHide)
                    setPayStyle('annual')
                  }}
                  label="Annual&nbsp;Salary"
                />
              </div>

              <div
                className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg"
                style={{ border: '2px solid var(--Border, #E0E5F9)' }}
              >
                <Checkbox
                  variant="primary"
                  value={payStyle === 'both'}
                  onChange={(e) => {
                    // formik.setFieldValue('isHide', !formik.values.isHide)
                    setPayStyle('both')
                  }}
                  label="Both"
                />
              </div>

              {/* <div
                className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg"
                style={{ border: '2px solid var(--Border, #E0E5F9)' }}
              >
                <Checkbox
                  variant="primary"
                  value={payStyle === 'negotiable'}
                  onChange={(e) => {
                    formik.setFieldValue('hourlyRate', 0)
                    formik.setFieldValue('annualRate', 0)
                    setPayStyle('negotiable')
                  }}
                  label="Negotiable"
                />
              </div> */}

              {/* <div
                onClick={() => setPayStyle('hourly')}
                className={clsx(
                  'flex items-center cursor-pointer text-[14px] md:text-sm font-semibold leading-5 border-[2px] border-solid rounded-lg py-[10px] px-[22px] md:py-[14px] md:px-[28px]',
                  payStyle === 'hourly'
                    ? 'bg-green-100 text-white border-green-100'
                    : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
                )}
              >
                Hourly
              </div>
              <div
                onClick={() => setPayStyle('annual')}
                className={clsx(
                  'flex items-center cursor-pointer text-[14px] md:text-sm font-semibold leading-5 border-[2px] border-solid rounded-lg py-[10px] px-[22px] md:py-[14px] md:px-[28px]',
                  payStyle === 'annual'
                    ? 'bg-green-100 text-white border-green-100'
                    : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
                )}
              >
                Annual Salary
              </div>
              <div
                onClick={() => setPayStyle('both')}
                className={clsx(
                  'flex items-center cursor-pointer text-[14px] md:text-sm font-semibold leading-5 border-[2px] border-solid rounded-lg py-[14px] px-7',
                  payStyle === 'both'
                    ? 'bg-green-100 text-white border-green-100'
                    : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
                )}
              >
                Both Hourly & Salary
              </div>
              <div
                onClick={() => setPayStyle('negotiable')}
                className={clsx(
                  'flex items-center cursor-pointer text-[14px] md:text-sm font-semibold leading-5 border-[2px] border-solid rounded-lg py-[14px] px-7',
                  payStyle === 'negotiable'
                    ? 'bg-green-100 text-white border-green-100'
                    : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
                )}
              >
                Negotiable
              </div> */}
            </div>
          </div>

          {/* Dynamic part */}
          <div className="mt-[30px]">
            {payStyle === 'hourly' && (
              <div>
                <div className="md:grid block grid-cols-2 gap-x-5">
                  <NumberInput
                    label="Expected Hourly Rate"
                    prefix="$"
                    suffix="USD"
                    name="hourlyRate"
                    id="hourlyRate"
                    onChange={formik.handleChange}
                    value={formik.values['hourlyRate']}
                    error={
                      formik.touched['hourlyRate']
                        ? formik.errors['hourlyRate']
                        : ''
                    }
                    placeholder="Min Hourly Rate"
                  />
                  <div className="md:flex hidden items-center mt-[35px]">
                    <p className="text-[14px] md:text-sm text-gray-300 leading-[16px] font-medium">
                      *($)USD only
                    </p>
                  </div>
                </div>
              </div>
            )}
            {payStyle === 'annual' && (
              <div>
                <div className="md:grid block grid-cols-2 gap-x-5">
                  <NumberInput
                    prefix="$"
                    suffix="USD"
                    label="Expected Annual Salary"
                    name="annualRate"
                    id="annualRate"
                    onChange={formik.handleChange}
                    value={formik.values['annualRate']}
                    error={
                      formik.touched['annualRate']
                        ? formik.errors['annualRate']
                        : ''
                    }
                    placeholder="Min Annual Salary"
                  />
                  <div className="md:flex hidden items-center mt-[30px]">
                    <p className="text-[14px] md:text-sm text-gray-300 leading-[16px] font-medium">
                      *($)USD only
                    </p>
                  </div>
                </div>
              </div>
            )}
            {payStyle === 'both' && (
              <div>
                {/* <div className="grid md:grid-cols-2 gap-x-5 gap-y-[20px] md:gap-y-0"> */}
                <div className="gap-x-5">
                  <div className="mb-5">
                    <NumberInput
                      prefix="$"
                      suffix="USD"
                      label="Expected Hourly Rate"
                      name="hourlyRate"
                      id="hourlyRate"
                      onChange={formik.handleChange}
                      value={formik.values['hourlyRate']}
                      error={
                        formik.touched['hourlyRate']
                          ? formik.errors['hourlyRate']
                          : ''
                      }
                      placeholder="Min Hourly Rate"
                    />
                  </div>
                  <NumberInput
                    prefix="$"
                    suffix="USD"
                    label="Expected Annual Salary"
                    name="annualRate"
                    id="annualRate"
                    onChange={formik.handleChange}
                    value={formik.values['annualRate']}
                    error={
                      formik.touched['annualRate']
                        ? formik.errors['annualRate']
                        : ''
                    }
                    placeholder="Min Annual Salary"
                  />
                </div>
              </div>
            )}
            {/* <div className="mt-5">
              <Checkbox
                value={formik.values.isHide}
                onChange={(e) => {
                  formik.setFieldValue('isHide', !formik.values.isHide)
                }}
                label={
                  <div className="text-gray-100 font-semibold text-[13px] leading-[18px]">
                    Hide my pay preferences
                  </div>
                }
                sizeClass="w-6 h-6"
                rectClass="border-gray-500 focus-within:border-gray-500 w-6 h-6"
                checkClass="w-4 h-4 text-green-200"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
