//packages
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//redux & api
import { getUser } from 'app/store/profileSlice'
import { updateUserLastActivity } from 'app/services/auth.service'
import { Helmet } from 'react-helmet'
import ChatBar from './components/ChatBar'
import MessageBox from './components/MessageBox'
import { getUserChatMessages, getUserChats } from 'app/services/chat.service'

export default function Messages() {
  const profile = useSelector(getUser)
  const [chats, setChats] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedChat, setSelectedChat] = useState(null)
  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(async () => {
    const userChats = await getUserChats({ userID: profile.id })
    setChats(userChats.data)
  }, [])

  useEffect(async () => {
    const userMessages = await getUserChatMessages({ chatID: selectedChat?.id })
    setMessages(userMessages.data)
  }, [selectedChat])

  return (
    <div
      className=" sm:px-[30px] 2xl:px-[66px] py-[28px] md:pl-16 pb-[70px] md:pb-0 bg-[#F7F8FC]"
      id="message-chat"
    >
      <Helmet>
        <title>JobSeeker Communications - Remoterecruit.com</title>
        <meta
          name="description"
          content="Manage your communications with potential remote candidates efficiently."
        />
      </Helmet>
      {/* <div className="py-[30px] md:py-12 flex-1 md:border-r relative md:border-r-gray-200 border-solid border-y-0 border-l-0 pr-11"> */}
      <div className="text-black-100 mx-[5px]">
        <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
          Welcome,{' '}
          {profile?.firstName ? profile?.firstName + '!' : profile?.companyName}
        </h5>
        <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
          Your Messages
        </h2>
      </div>
      {/* </div> */}

      <div className="flex">
        <div
          className={`w-full md:w-1/3 h-full ${
            isMobileView ? 'hidden md:block' : 'block'
          }`}
        >
          <ChatBar
            chats={chats}
            messages={messages}
            setMessages={setMessages}
            setSelectedChat={setSelectedChat}
            profile={profile}
            setIsMobileView={setIsMobileView}
          />
        </div>
        <div
          className={`w-full md:w-full sm:ml-15 h-full ${
            selectedChat && isMobileView ? 'block' : 'hidden md:block'
          }`}
        >
          <MessageBox
            messages={messages}
            setMessages={setMessages}
            chatID={selectedChat?.id}
            senderID={profile?.id}
            selectedChat={selectedChat}
            profile={profile}
            setIsMobileView={setIsMobileView}
          />
        </div>
      </div>
    </div>
  )
}
