import React from 'react'

function SearchTag({ name, onClick }) {
  return (
    <div className="flex justify-center items-center gap-[3.68px] py-[4.42px] pl-[8.83px] pr-[7.36px] rounded-[7.374px] border-solid border-[0.231px] border-[#52B4DA] bg-[#1E3E85]">
      <div className="text-white text-center font-inter text-[8.833px] not-italic font-semibold leading-[14.721px]">
        {name}
      </div>
      <div onClick={onClick} className="cursor-pointer ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 10 9"
          fill="none"
        >
          <path
            d="M6.43689 2.96545L3.66406 5.73828"
            stroke="white"
            strokeWidth="1.18835"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.43689 5.73828L3.66406 2.96545"
            stroke="white"
            strokeWidth="1.18835"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  )
}

export default SearchTag
