import axios from 'axios'
const CHAT_API_BASE_URL = `${process.env.REACT_APP_CHAT_API_URL}`

export const createUser = (data) => {
  return axios.post(`https://api.chatengine.io/users/`, data, {
    headers: {
      'PRIVATE-KEY': process.env.REACT_APP_CHAT_ENGINE_PROJECT_KEY,
    },
  })
}

export const getChats = ({ userName, userSecret }) => {
  return axios.get(`https://api.chatengine.io/chats/`, {
    headers: {
      'Project-ID': process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
      'User-Name': userName,
      'User-Secret': userSecret,
    },
  })
}

export const getChatsCount = (data) => {
  return axios.post(`${CHAT_API_BASE_URL}/get/user-chats-count`, data)
}

export const createChat = (data) => {
  return axios.post(`${CHAT_API_BASE_URL}/create/chat`, data)
}

// New Functions
export const getUserChats = (data) => {
  return axios.post(`${CHAT_API_BASE_URL}/get/user-chats`, data)
}

export const getUserChatMessages = (data) => {
  return axios.post(`${CHAT_API_BASE_URL}/get/messages`, data)
}

export const createMessage = (data) => {
  return axios.post(`${CHAT_API_BASE_URL}/create/message`, data)
}
