//packages
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

//custom components
import CandidateItem from './CandidateItem'
import SkeletonCandidateItem from 'components/Loader/SkeletonCandidateItem'
import Button from 'components/Button'
import UpgradingPlanModal from 'components/Dialog/UpgradingPlanModal'
import Pagination from 'components/pagination'

//redux & apis
import { getUser } from 'app/store/profileSlice'
import EmptyScreen from 'views/employer/profile/components/EmptyScreen'
import Dropdown from 'components/dropdown/Dropdown'
import { PAGINATION_LIST } from 'app/utils/list'
import CandidateVideoCard from './CandidateVideoCard'

const limit4Basic = 25

export default function Results({
  loading = false,
  refresh,
  setRefresh,
  candidates = [],
  selectedJob,
  currentPage,
  count,
  pageSize,
  onPageChange,
  sort,
  sortData,
  setSort,
  tab,
}) {
  const ai_selected = localStorage.getItem('ai_selected')
  const [showGetPremiumModal, setShowGetPremiumModal] = useState(false)
  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  // const [sortData, setSortData] = useState([
  //   { name: 'All', id: 'all' },
  //   { name: 'Oldest', id: 'old' },
  //   { name: 'Newest', id: 'new' },
  // ])
  // const [sort, setSort] = useState(sortData[0])
  const profile = useSelector(getUser)

  console.log(count)
  // if user already viewed an applicant/invitee, then go back to Results, it will auto scroll into the last click item
  useEffect(() => {
    if (!loading) {
      const element = document.getElementById(`${ai_selected}`)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [loading, ai_selected])

  const sortedCandidates = [...candidates].sort((a, b) => {
    if (a.status === 'pending' && b.status !== 'pending') {
      return -1
    }
    if (a.status !== 'pending' && b.status === 'pending') {
      return 1
    }
    // If statuses are the same, sort by `createdAt` in descending order (newest first)
    return new Date(b.createdAt) - new Date(a.createdAt)
  })

  // console.log(sortedCandidates)

  return (
    <div
      className="min-w-full bg-white shadow-[14px_41px_50px_rgba(49,89,211,0.07)] rounded-3xl py-5 px-7.5"
      id="results-top"
    >
      {/* {
        loading ? (
          <div className="flex flex-row items-center justify-center h-full space-x-5 animate-pulse ">
            <div className="h-[32px] bg-gray-300 rounded-md min:w-full"></div>
          </div>
        ) : null
        <div className="text-black-100 leading-[32px] text-lg font-medium">
          {candidates.length > 0 && selectedJob?.title}
        </div>
      } */}
      {loading ? (
        <SkeletonCandidateItem />
      ) : (
        <div>
          {candidates.length === 0 ? (
            <EmptyScreen
              tab={tab}
              sort={sort}
              numberPerPage={numberPerPage}
              setNumberPerPage={setNumberPerPage}
              sortData={sortData}
              setSort={setSort}
            />
          ) : (
            <div className="relative min-w-full">
              <div className="flex gap-5 mt-[19px] mb-[32px] min-w-full justify-between ">
                <div className="flex items-center space-x-3">
                  <div className="text-[#808191] font-semibold text-sm">
                    View
                  </div>
                  <div className="w-[70px]">
                    <Dropdown
                      data={PAGINATION_LIST}
                      label=""
                      value={numberPerPage}
                      onChange={(data) => {
                        setNumberPerPage(data)
                      }}
                      className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                      parentClassName="w-full"
                      labelClassName="text-[#1E3E85] font-semibold text-sm"
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="text-[#808191] font-semibold text-sm">
                    Sort by
                  </div>
                  <div className="w-[100px]">
                    <Dropdown
                      data={sortData}
                      value={sort}
                      className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                      parentClassName="w-full"
                      labelClassName="text-[#1E3E85] font-semibold text-sm"
                      onChange={setSort}
                    />
                  </div>
                </div>
              </div>
              {/* PREMIUM start comment this out no premium user */}
              <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-[21px] ">
                {candidates.map((v, index) => {
                  const actualIndex = (currentPage - 1) * pageSize + index

                  console.log(actualIndex)

                  return (
                    // <CandidateItem
                    //   data={v.user}
                    //   selectedJob={selectedJob}
                    //   applicantData={v}
                    //   isBlur={
                    //     profile?.plan === 'basic' && actualIndex >= limit4Basic
                    //   }
                    //   tab={tab}
                    //   id={`res${actualIndex}`}
                    // />
                    <CandidateVideoCard
                      isBlur={
                        profile?.plan === 'basic' && actualIndex >= limit4Basic
                      }
                      data={v.user}
                      applicantData={v}
                      tab={tab}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      numberPerPage={numberPerPage}
                      currentPage={currentPage}
                    />
                  )
                })}
              </div>
              {profile?.plan === 'basic' &&
                currentPage * pageSize > limit4Basic && (
                  <div className="absolute bottom-[80px] w-full flex flex-col justify-center items-center">
                    <p className="w-[474px] text-center text-[#000] font-inter text-[24px] not-italic font-semibold leading-[36px] ">
                      You’ve Reached Your Limit!
                    </p>
                    <p className="w-[474px] text-center text-[#1E3E85] font-inter text-[16px] not-italic font-normal leading-none mb-[27px] ">
                      You’ve viewed the maximum number of applicants available
                      on your current plan. Upgrade now to unlock unlimited
                      access and find your perfect candidate.
                    </p>
                    <Button
                      onClick={() => setShowGetPremiumModal(true)}
                      variant="secondary"
                      className="px-5 !bg-[#FFC700] !text-[#11142D]"
                    >
                      Upgrade to view more
                    </Button>
                  </div>
                )}
              {/* end comment this out no premium user */}

              {/* {sortedCandidates.map((v, index) => (
                <CandidateItem
                  data={v.user}
                  selectedJob={selectedJob}
                  applicantData={v}
                  tab={tab}
                  id={`res${index}`}
                />
              ))} */}
            </div>
          )}
        </div>
      )}
      {!loading && (
        <div className="mt-6 mb-[44px]">
          <Pagination
            onPageChange={onPageChange}
            currentPage={currentPage}
            totalCount={count}
            pageSize={pageSize}
            toTop={() => {
              localStorage.removeItem('ai_selected')
            }}
          />
        </div>
      )}
      <UpgradingPlanModal
        isOpen={showGetPremiumModal}
        closeModal={() => setShowGetPremiumModal(false)}
      />
    </div>
  )
}
