import React, { useState } from 'react'
import JobIcon from 'assets/img/jobIcon.png'
import VideoPlayerModal from 'components/Dialog/VideoPlayerModal'
import CandidateDialog from 'components/Dialog/CandidateDialog'
const { flag } = require('country-emoji')

function CandidateVideoCard({ data, handleInvite }) {
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false)
  const [showCandidateModal, setShowCandidateModal] = useState(false)

  const [isTouched, setIsTouched] = useState(false)

  const handleTouchStart = () => setIsTouched(true)
  const handleTouchEnd = () => setIsTouched(false)

  const onVideo = () => {
    setShowVideoPlayerModal(true)
    console.log("I'm Clicked")
  }

  const onCandidateModal = () => {
    setShowCandidateModal(true)
    console.log("I'm Clicked")
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  console.log(flag('Philippines'))

  return (
    <div
      className="relative group w-[166.664px] sm:w-[191px] 2xl:w-[210px] 3xl:w-[230px] h-[215.529px] sm:h-[247px] rounded-[6px] sm:rounded-[8px] overflow-hidden mb-[24px] mr-[16px]"
      tabIndex={0}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      {data.hourly_rate !== 0 && (
        <div className="absolute top-2 right-2 px-[7px] py-[3px] bg-white shadow-sm group-hover:opacity-0 transition-opacity duration-300 rounded-[12px]">
          <div className="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.00335 14.6692C4.32144 14.6692 1.33667 11.6845 1.33667 8.00257C1.33667 4.3207 4.32144 1.33594 8.00335 1.33594C11.6852 1.33594 14.67 4.3207 14.67 8.00257C14.67 11.6845 11.6852 14.6692 8.00335 14.6692ZM5.67 9.3359V10.6692H7.33668V12.0026H8.67002V10.6692H9.33668C10.2572 10.6692 11.0034 9.9231 11.0034 9.00257C11.0034 8.0821 10.2572 7.3359 9.33668 7.3359H6.67002C6.48591 7.3359 6.33667 7.1867 6.33667 7.00257C6.33667 6.8185 6.48591 6.66924 6.67002 6.66924H10.3367V5.33594H8.67002V4.0026H7.33668V5.33594H6.67002C5.74953 5.33594 5.00334 6.08212 5.00334 7.00257C5.00334 7.9231 5.74953 8.66924 6.67002 8.66924H9.33668C9.52075 8.66924 9.67002 8.8185 9.67002 9.00257C9.67002 9.1867 9.52075 9.3359 9.33668 9.3359H5.67Z"
                fill="#1E3E85"
              />
            </svg>
            <span className="text-[#1E3E85] text-center font-manrope text-[10px] not-italic font-medium">
              {data.hourly_rate}/hr
            </span>
          </div>
        </div>
      )}

      {/* Profile Image */}
      <img
        className="w-full h-full object-cover"
        src={
          data.avatar
            ? data.avatar
            : 'https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/blank-profile.png'
        }
        alt={`${data.name}'s profile picture`}
      />

      {/* Hover Tint */}
      <div
        className={`absolute inset-0 bg-[rgba(0,5,17,0.68)] transition-opacity duration-300 pointer-events-none ${
          isTouched
            ? 'opacity-100'
            : 'opacity-0 group-hover:opacity-100 group-focus:opacity-100'
        }`}
      ></div>

      {/* Quick View Button */}
      <div
        className={`absolute top-[18px] left-1/2 transform -translate-x-1/2 transition-opacity duration-300 pointer-events-auto z-10 ${
          isTouched
            ? 'opacity-100'
            : 'opacity-0 group-hover:opacity-100 group-focus:opacity-100'
        }`}
      >
        <button
          onClick={onCandidateModal}
          className="flex gap-[6.144px] w-[149.144px] justify-center items-center text-center bg-white text-[#011137] text-[9px] py-[7px] not-italic font-normal bg-opacity-90"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
          >
            <path
              d="M0.5 9.52718V6.18699C0.5 5.9265 0.711519 5.71402 0.972976 5.71402H1.45575C1.5811 5.71402 1.70155 5.76396 1.78967 5.85307C1.87878 5.9412 1.92873 6.06165 1.92873 6.18699V8.57147H4.31321C4.43855 8.57147 4.559 8.62141 4.64713 8.71052C4.73624 8.79866 4.78618 8.91911 4.78618 9.04444V9.52722C4.78618 9.65257 4.73624 9.77302 4.64713 9.86114C4.559 9.95025 4.43855 10.0002 4.31321 10.0002H0.973024C0.847679 10.0002 0.72723 9.95025 0.639105 9.86114C0.549992 9.77301 0.5 9.65251 0.5 9.52718ZM9.07132 3.81321V1.42873H6.68684C6.42635 1.42873 6.21386 1.21721 6.21386 0.955754V0.472976C6.21386 0.211507 6.42636 0 6.68684 0H10.027C10.1524 0 10.2728 0.0499427 10.3609 0.139055C10.4501 0.227188 10.5 0.347636 10.5 0.472973V3.81316C10.5 3.9385 10.4501 4.05895 10.3609 4.14708C10.2728 4.23619 10.1524 4.28613 10.027 4.28613H9.54425C9.4189 4.28613 9.29845 4.23619 9.21033 4.14708C9.12122 4.05895 9.07132 3.93855 9.07132 3.81321Z"
              fill="#001957"
            />
          </svg>
          Quick view
        </button>
      </div>

      {/* Play Button */}
      {data.video && (
        <div
          onClick={onVideo}
          className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300`}
        >
          <div className="flex px-[14px] py-[12px] items-center bg-white bg-opacity-50 rounded-[8px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="20"
              viewBox="0 0 22 26"
              fill="none"
            >
              <path
                d="M3.11222 0.186654C-0.962775 2.73742 -0.872198 24.1429 3.11222 25.8277C5.51605 26.8437 21.9101 18.1457 21.9101 13.4271C21.9101 8.49817 5.07876 -1.0442 3.11222 0.186654Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}

      {data.video && (
        <div
          onClick={onVideo}
          className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ${
            isTouched
              ? 'opacity-100'
              : 'opacity-0 group-hover:opacity-100 group-focus:opacity-100'
          }`}
        >
          <div className="flex px-[14px] py-[12px] items-center bg-white bg-opacity-50 rounded-[8px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="20"
              viewBox="0 0 22 26"
              fill="none"
            >
              <path
                d="M3.11222 0.186654C-0.962775 2.73742 -0.872198 24.1429 3.11222 25.8277C5.51605 26.8437 21.9101 18.1457 21.9101 13.4271C21.9101 8.49817 5.07876 -1.0442 3.11222 0.186654Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}

      {/* Bottom Gradient Info */}
      <div
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 5, 17, 0.00) 0%, rgba(0, 11, 35, 0.27) 17.25%, rgba(0, 5, 17, 0.55) 48.5%)',
        }}
        className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-[9px] sm:p-4 text-white z-50"
      >
        <div className="flex items-center justify-between mt-1">
          <h3 className="text-white font-manrope text-[11.477px] sm:text-[13.358px] not-italic font-bold leading-normal">
            {data.name.split(' ')[0]}
          </h3>
          {/* Country Section */}
          <div className="flex items-center justify-center gap-[2.62px] sm:gap-1">
            <div className="flex items-center justify-center">
              <span
                className="text-[8.602px] sm:text-[13.358px]"
                role="img"
                aria-label={`Flag of ${data.country}`}
              >
                {flag(`${data.country}`)}
              </span>
            </div>
            <span className="text-white text-center font-manrope text-[5.365px] sm:text-[10.331px] not-italic font-medium leading-normal whitespace-nowrap">
              {truncateString(data.country, 15)}
            </span>
          </div>
        </div>
        {data.job_title !== '' && (
          <div className="flex items-center justify-between mt-1">
            {/* Role Section */}
            <div className="flex items-center gap-[2.62px] sm:gap-1">
              <img
                src={JobIcon}
                alt="Role Icon"
                className="w-[8.602px] h-[8.602px] sm:w-[9.967px] sm:h-[10.323px]"
              />
              <span className="text-white text-center font-manrope text-[7.376px] sm:text-[12px] not-italic font-medium leading-normal whitespace-nowrap">
                {truncateString(data.job_title, 25)}
              </span>
            </div>
          </div>
        )}
      </div>
      <VideoPlayerModal
        isOpen={showVideoPlayerModal}
        closeModal={() => setShowVideoPlayerModal(false)}
        url={data.video}
      />
      <CandidateDialog
        isOpen={showCandidateModal}
        closeModal={() => setShowCandidateModal(false)}
        data={data}
        handleInvite={handleInvite}
      />
    </div>
  )
}

export default CandidateVideoCard
