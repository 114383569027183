//packages
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { AnimateSpinIcon } from 'components/Icons'
import moment from 'moment'

export default function MemberShipCard({
  profile,
  setShowGetPremiumModal,
  setShowCancelMembershipModal,
  loading,
}) {
  const cancelHandler = async () => {
    setShowCancelMembershipModal(true)
  }

  const upgradeHandler = () => {
    setShowGetPremiumModal(true)
  }

  // console.log('debug: profile', profile)

  const isPremium = profile?.plan === 'premium'

  return (
    <div className="bg-gray-1300 border border-gray-500 py-5 px-6 rounded-xl">
      <div className="text-gray-300 text-[12px] md:text-xs">Membership</div>
      <div className="md:flex md:space-x-4 space-y-[7px] md:space-y-0">
        <div className="flex-none border border-gray-500 flex items-center px-4.5 bg-[rgba(228,228,228,0.3)] rounded-xl h-[56px] w-72">
          {isPremium ? (
            <div className="text-black-100 opacity-50">
              <span className="font-semibold">Premium</span> ($79.99 / month)
            </div>
          ) : (
            <div className="text-black-100 opacity-50">
              <span className="font-semibold">Basic</span>
            </div>
          )}
        </div>
        <div className="w-full">
          {isPremium ? (
            <button
              onClick={cancelHandler}
              className="uppercase text-white font-bold text-sm bg-[#808191] px-8 py-4.5 rounded-xl flex items-center"
            >
              Cancel Membership
              {loading && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
            </button>
          ) : (
            <button
              onClick={upgradeHandler}
              className="w-full py-[11px] md:py-0 md:w-fit uppercase text-white font-bold text-[14px] md:text-sm bg-green-100 h-full md:px-8 rounded-xl flex items-center justify-center"
            >
              upgrade
              {loading && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
            </button>
          )}
        </div>
      </div>
      {isPremium && (
        <div className="text-gray-100 text-[12px] md:text-xs mt-[12px] md:mt-4">
          <span className="text-black-100">Next Payment:</span> &nbsp;
          {moment(profile?.last_upgrade).add(1, 'month').format('MMM DD, YYYY')}
          <br />
          Your subscription will automatically renew on{' '}
          {moment(profile?.last_upgrade).add(1, 'month').format('MMM DD, YYYY')}
        </div>
      )}
    </div>
  )
}
