//packages
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

//custom components
import Avatar from 'components/Avatar'
import { EarthIcon, LocationIcon, PencilIcon } from 'components/Icons'
import { getClickableLink } from 'app/utils/function'

//Redux & apis
import { getUser } from 'app/store/profileSlice'

export default function TopHeader({ profile }) {
  const navigate = useNavigate()
  const user = useSelector(getUser)

  const onEdit = () => {
    // Define a mapping for user type
    const typeMapping = {
      contractor: 'jobseeker',
    }

    // Fallback to original user type if there's no mapping
    const userType = typeMapping[user.type] || user.type
    navigate(`/${userType}/setting`)
  }
  return (
    <div className="w-full relative">
      <div
        className={clsx(
          'w-full h-22 hidden md:block bg-no-repeat rounded-2xl bg-[length:100%_400px] py-20 px-16 space-y-2 z-0 bg-center',
          profile.plan === 'basic'
            ? 'bg-job-detail-header'
            : 'bg-setting-premium-header'
        )}
      >
        {profile.plan === 'basic' ? (
          <div className="text-white leading-[60px] font-semibold text-4xl">
            Basic Employer
          </div>
        ) : (
          <div className="text-white leading-[60px] font-semibold text-4xl">
            Premium Employer
          </div>
        )}

        <div className="absolute right-[32px] top-[24px]">
          <div
            className="w-12 h-12 flex justify-center items-center bg-white rounded-full group cursor-pointer"
            onClick={onEdit}
          >
            <PencilIcon className="text-black-100 group-hover:text-green-100" />
          </div>
        </div>
      </div>
      <div className="md:mx-16 rounded-3xl bg-white py-[20px] md:py-6 px-[20px] md:px-6 xl:px-12 flex space-x-8 md:-mt-[85px] shadow-md items-center z-[50] truncate">
        <div className="flex-none w-[80px] md:w-28 h-[80px] md:h-28 flex justify-center items-center border-[4px] border-[#0049C6] rounded-full border-solid">
          <Avatar image={profile.avatar} className="w-20 h-20" />
        </div>
        <div className="flex-1 space-y-[11px]">
          <p className="text-black-100 leading-[24px] md:leading-10 text-[16px] md:text-3xl font-semibold">
            {profile.firstName} {profile.lastName}
          </p>
          <div className="text-black-100 opacity-60 font-medium text-lg leading-6 md:flex space-y-[5px] md:space-y-0 md:space-x-7">
            <div className="flex items-center space-x-2">
              <div className="flex-none w-[14px] md:w-6">
                <LocationIcon className="w-full" />
              </div>
              <p className="mb-0 leading-[18px] md:leading-6 font-medium opacity-50 text-[12px] md:text-lg">
                {profile.location}
              </p>
            </div>
            {profile.emType === 'company' && (
              <div className="flex items-center space-x-2">
                <div className="flex-none w-[14px] md:w-6">
                  <EarthIcon className="w-full" />
                </div>
                <a
                  href={getClickableLink(profile.website)}
                  target="_blank"
                  className="mb-0 leading-[18px] md:leading-6 font-medium opacity-50 text-[12px] md:text-lg"
                >
                  {profile.website}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
