import * as React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import AuthRoutes from './AuthRoutes'

import Login from 'views/auth/Login'
import Register from 'views/auth/Register'
import ResetPassword from 'views/auth/ResetPassword'
import Verification from 'views/auth/Verification'

import AuthLayout from 'components/Layout/AuthLayout'
// import ErrorPage from "views/error/Error";
import ForgotPassword from 'views/auth/ForgotPassword'
import ContractorRegisterForm from 'views/auth/contractor/RegisterForm'
import EmployeeRegisterForm from 'views/auth/employee/RegisterForm'

// import HomePage from 'views/home'
// import NewHomePage from 'views/NewHomePage'

//Employees
import FindCandidates from 'views/employer/candidates/index'
import EmployerLayout from 'views/employer/index'
import Settings from 'views/employer/setting/index'
import Upgrade from 'views/employer/setting/Upgrade'
// import YourJobPosts from "views/employer/your-job-posts";
import EmployerMessages from 'views/employer/messages'
import EmployerProfileContainer from 'views/employer/profile'
import EmployerProfile from 'views/employer/profile/EmployerProfile'
import CandidateProfile from 'views/employer/profile/CandidateProfile'
import YourJobPosts from 'views/employer/your-job-posts'
import JobsWithCandidates from 'views/employer/your-job-posts/JobsWithCandidates'
import YourJobPostsContainer from 'views/employer/your-job-posts/YourJobPostsContainer'
import YourApplicants from 'views/employer/your-job-posts/your-applicants'

//Contractor
import FindJobs from 'views/contractor/find-jobs/index'
import JobDetail from 'views/contractor/find-jobs/jobDetail'
import ContractorLayout from 'views/contractor/index'
import ContractorMessages from 'views/contractor/messages/index'
import ContractorProfileContainer from 'views/contractor/profile/index'
import ContractorProfile from 'views/contractor/profile/ContractorProfile'
import ContractorEmployerProfile from 'views/contractor/profile/ContractorEmployerProfile'
import ContractorSettings from 'views/contractor/setting/index'
import YourApplications from 'views/contractor/your-applications/index'
import DirectChatPage from 'views/employer/DirectChatTest'
import TermsOfService from 'views/terms'
import PrivacyPolicy from 'views/privacy'
import JobDetailNonAuth from 'views/contractor/find-jobs/JobDetailNonAuth'
import CandidateProfileNonAuth from 'views/employer/profile/CandidateProfileNonAuth'
import LatestHomePage from 'views/LatestHomePage'

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'employee/register',
        element: <ContractorRegisterForm />,
      },
      {
        path: 'employer/register',
        element: <EmployeeRegisterForm />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'verify',
        element: <Verification />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
    ],
  },
  {
    element: <AuthRoutes />,
    children: [
      {
        path: 'employer',
        element: <EmployerLayout />,
        children: [
          {
            path: 'find-candidates',
            element: <FindCandidates />,
          },
          {
            path: 'setting',
            element: <Settings />,
          },
          {
            path: 'direct-chat-test',
            element: <DirectChatPage />,
          },
          {
            path: 'job-posts',
            element: <YourJobPostsContainer />,
            children: [
              {
                index: true,
                element: <JobsWithCandidates />,
              },
              {
                path: ':id/edit',
                element: <YourJobPosts />,
              },
              {
                path: 'applicants/:id',
                element: <YourApplicants />,
              },
            ],
          },
          {
            path: 'profile',
            element: <EmployerProfileContainer />,
            children: [
              {
                index: true,
                element: <EmployerProfile />,
              },
              {
                path: ':id',
                element: <CandidateProfile />,
              },
            ],
          },
          {
            path: 'messages',
            element: <EmployerMessages />,
          },
          {
            path: 'upgrade',
            element: <Upgrade />,
          },
        ],
      },
      {
        path: 'jobseeker',
        element: <ContractorLayout />,
        children: [
          {
            element: <FindJobs />,
            path: 'jobs',
          },
          {
            element: <JobDetail />,
            path: 'jobs/:id',
          },
          {
            path: 'your-applications',
            element: <YourApplications />,
          },
          {
            path: 'setting',
            element: <ContractorSettings />,
          },
          {
            path: 'profile',
            element: <ContractorProfileContainer />,
            children: [
              {
                index: true,
                element: <ContractorProfile />,
              },
              {
                path: ':id',
                element: <ContractorEmployerProfile />,
              },
            ],
          },
          {
            path: 'messages',
            element: <ContractorMessages />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <LatestHomePage />,
  },
  // {
  //   path: '/new',
  //   element: <NewHomePage />,
  // },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  // {
  //   path: '/blog/:id',
  //   element: <BlogPost />,
  // },
  {
    path: '/home/job/:id',
    element: <JobDetailNonAuth />,
  },
  {
    path: '/home/candidate/:id',
    element: <CandidateProfileNonAuth />,
  },
  // {
  //   path: '/features',
  //   element: <Features />
  // },
  // {
  //   path: '/faq',
  //   element: <FAQ />
  // },
  // {
  //   path: '/pricing',
  //   element: <Pricing />
  // }
])

export default router
