import Button from 'components/Button'
import { Link, useNavigate } from 'react-router-dom'
import logo from 'assets/img/logo-home.png'

export function NavBar({ showHowItWorks = true }) {
  const navigate = useNavigate()

  const toSignUp = () => {
    navigate('/auth/register')
  }

  const toHome = () => {
    navigate('/')
  }

  const toSignIn = () => {
    navigate('/auth')
  }

  const handleClick = (event) => {
    event.preventDefault() // Prevent the default navigation behavior
    const targetElement = document.getElementById('getStarted')
    targetElement?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <nav className="fixed top-0 w-full z-50 bg-[#00113C]/90 backdrop-blur-sm">
      <div className="container mx-auto px-4 h-16 flex items-center justify-between">
        <div className="flex items-center gap-8">
          <Link href="/" className="flex items-center gap-2 h-full">
            <img
              src={logo}
              alt="RemoteRecruit"
              className="h-[1rem] w-auto"
              style={{ objectFit: 'contain' }}
              onClick={toHome}
            />
          </Link>
          <div className="flex gap-[10.06px] sm:gap-6 ml-[37.8px] sm:ml-[90px]">
            {/* <Link
              href="/"
              className="text-white hover:text-white/80 text-[10px] sm:text-base not-italic font-bold sm:font-normal font-sans leading-normal"
            >
              Home
            </Link> */}
            {showHowItWorks && (
              <Link
                href="/how-it-works"
                onClick={handleClick}
                className="text-white hover:text-white/80 text-[10px] sm:text-base not-italic font-bold sm:font-normal font-sans leading-normal"
              >
                How it Works?
              </Link>
            )}
          </div>
        </div>
        <div className="flex items-center gap-[7.6px] sm:gap-[14.676px]">
          <Button
            variant="ghost"
            className="text-white hover:text-white/80 py-[5.62px] px-[16.2px] sm:py-[11.741px] sm:px-[33.8px] text-base not-italic font-bold sm:font-normal font-sans leading-normal"
            onClick={toSignIn}
          >
            Sign In
          </Button>
          <Button
            className="bg-white text-[#062B7C] hover:bg-white/90 py-[5.62px] px-[16.2px] sm:py-[11.741px] sm:px-[33.8px] text-base not-italic font-bold sm:font-normal font-sans rounded-[2.108px] sm:rounded-[4.4px]"
            onClick={toSignUp}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </nav>
  )
}
