import React, { useEffect, useRef, useState } from 'react'
import CategoryCard from './CategoryCard'
import designCardImg from 'assets/img/DesignCardImg.png'
import EngineeringImg from 'assets/img/EngineeringImg.png'
import DevImg from 'assets/img/DevImg.png'
import WritingImg from 'assets/img/WritingImg.png'
import AdminImg from 'assets/img/adminImg.png'
import SalesImg from 'assets/img/salesImg.png'
import LegalImg from 'assets/img/legalImg.png'
import FinanceImg from 'assets/img/finance.png'
// import FinanceImg from 'assets/img/FinanceImg.png'

function CategorySection({
  filter,
  setFilter,
  searchKeywords,
  setSearchKeywords,
}) {
  const scrollContainerRef = useRef(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  // Check scroll position to determine arrow visibility
  const updateArrowsVisibility = () => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer

    // Show left arrow if not at the start
    setShowLeftArrow(scrollLeft > 0)

    // Show right arrow if not at the end
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth)
  }

  useEffect(() => {
    updateArrowsVisibility()
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateArrowsVisibility)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', updateArrowsVisibility)
      }
    }
  }, [])

  // Scroll handler
  const scroll = (direction) => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const scrollAmount = 300 // Adjust as needed for the scroll distance
    scrollContainer.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth',
    })
  }

  return (
    <div className="flex flex-col relative w-[calc(100%-10px)] sm:w-[calc(100%-128px)] sm:h-[548px] ml-[10px] sm:ml-[128px] mt-0 sm:mt-[31px] mb-[80px] sm:mb-[120.83px] overflow-hidden">
      <div className="flex flex-col items-start w-[334px] h-[138px] sm:w-[826px] sm:h-[175px]">
        <div className="flex justify-center items-center gap-[9.32px] px-[22px] py-[2.89px] rounded-[19.101px] border-[0.579px] sm:px-[35.417px] sm:py-[4.66px] sm:rounded-[30.757px] sm:border-[0.932px] border-white border-b-[#11378F] mb-[9.26px] sm:mb-[16px]">
          <p className="text-white text-center font-manrope text-[13.891px] sm:text-[18.64px] font-light leading-normal not-italic">
            Looking to Hire?
          </p>
        </div>
        <div className="mb-[9.26px] sm:mb-[16px] w-[334px] sm:w-auto">
          <p className="text-white font-manrope text-[32px] sm:text-[56px] not-italic leading-[38.4px]  sm:leading-[67.2px] font-light">
            Explore{' '}
            <span className="text-[#FFC628] font-semibold">Real Talent</span> By
            Category
          </p>
        </div>
        <div className="mb-[28px] sm:mb-[53px]">
          <p className="text-[#DBE6FF] font-manrope text-[13.891px] sm:text-2xl not-italic font-normal leading-normal">
            Looking for jobs?{' '}
            <span className="text-[#FFC628] font-semibold sm:underline decoration-solid cursor-pointer ">
              <a href='/auth/register'>Explore jobs now</a>
            </span>
          </p>
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className="flex relative items-center gap-[12px] sm:gap-[13px] overflow-x-auto no-scrollbar"
      >
        <CategoryCard
          color="#C161E6"
          image={designCardImg}
          text="Design & Creative"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={EngineeringImg}
          color="#BC9EC1"
          text="Engineering & Architecture"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={DevImg}
          color="#6DBF74"
          text="Development & IT"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={WritingImg}
          color="#FFC857"
          text="Writing and Translation"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={FinanceImg}
          color="#FACB96"
          text="Finance & Accounting"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={AdminImg}
          color="#C161E6"
          text="Admin & Customer Support"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={SalesImg}
          color="#BC9EC1"
          text="Sales & Marketing"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
        <CategoryCard
          image={LegalImg}
          color="#6DBF74"
          text="Legal"
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          filter={filter}
        />
      </div>
      {showLeftArrow && (
        <div
          className="absolute left-[5px] top-[250px] sm:top-[400px] transform -translate-y-1/2 flex justify-center items-center w-[45px] h-[45px] p-[16px] rounded-full bg-white cursor-pointer shadow-md"
          onClick={() => scroll('left')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M8.42559 15.897L1.6488 8.7814M1.6488 8.7814L8.42559 1.66577M1.6488 8.7814L17.9131 8.7814"
              stroke="#011137"
              strokeWidth="3.25286"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {showRightArrow && (
        <div
          className="absolute left-[-30px] sm:left-1 top-[250px] sm:top-[400px] transform -translate-y-1/2 flex justify-center items-center w-[45px] h-[45px] p-[16px] rounded-full bg-white cursor-pointer shadow-md"
          style={{
            marginLeft: '96%', // Or reduce this value if too much space
            boxShadow:
              '0px 9.939px 32.104px 63.611px rgba(255, 255, 255, 0.20)',
          }}
          onClick={() => scroll('right')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[390px] h-[39px]"
            viewBox="0 0 21 19"
            fill="none"
          >
            <path
              d="M11.8215 2.53987L18.5983 9.65549M18.5983 9.65549L11.8215 16.7711M18.5983 9.65549L2.33398 9.65549"
              stroke="#011137"
              strokeWidth="3.25286"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default CategorySection
