//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'

//custom components
import { ModalCloseIcon } from 'components/Icons'

export default function UserTypeConfirmModal({ isOpen, closeModal, onConfirm, onDecline }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pt-[30px] md:pt-14 pb-[30px] md:pb-12 px-[25px] md:px-8 bg-white shadow-[14px_41px_50px_0.07] w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle transition-all">
                {/* Body Section */}
                <div className='flex px-[30px] py-[14px] flex-col items-start gap-[10px] rounded-[8px] '>
                    <div>
                        <p className='text-[#11142D] text-center font-poppins sm:text-[32px] text-[15px] font-semibold '>Are you sure you are an Employer?</p>
                    </div>
                    <div>
                        <p className='text-[#5D5E69] text-center font-poppins text-[8px] sm:text-[16px] font-normal tracking-[-0.482px] sm:tracking-[-1px] '>Choose how you want to get started and unlock opportunities worldwide.</p>
                    </div>
                    <div className='flex w-full items-center justify-center gap-[21px] '>
                        <button onClick={onConfirm} className='flex sm:p-[22px] p-[11px] justify-center items-center gap-[5px] sm:gap-[10px] rounded-[4px] sm:rounded-[8px] bg-[#1E3E85] text-white text-center font-poppins text-[8px] sm:text-[16px] font-medium leading-[10px] sm:leading-[20px] '>Yes, I'm an Employer</button>
                        <button onClick={onDecline} className='flex sm:p-[22px] p-[11px] justify-center items-center gap-[5px] sm:gap-[10px] rounded-[4px] sm:rounded-[8px] border-[0.6px] border-[#11142D] text-[#11142D] text-center font-poppins text-[8px] sm:text-[16px] font-medium leading-[10px] sm:leading-[20px] '>No, I'm an Jobseeker</button>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
