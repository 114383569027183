import React from 'react'

function MessagesSearchBar() {
  return (
    <div className="flex w-[344px] sm:w-[373px] justify-between items-center px-[19.7px] py-[14.8px] mx-[24.68px] mt-[53px] mb-[4.93px] rounded-[9.871px] border-[1.234px] border-[#E5EAFF] ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[20px] h-[20px] sm:w-[26px] sm:h-[26px] "
        // width="26"
        // height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M24.0467 24.0706L16.6436 16.6675M19.1113 10.4983C19.1113 15.2683 15.2444 19.1352 10.4744 19.1352C5.7043 19.1352 1.8374 15.2683 1.8374 10.4983C1.8374 5.72822 5.7043 1.86133 10.4744 1.86133C15.2444 1.86133 19.1113 5.72822 19.1113 10.4983Z"
          stroke="#A3A3A3"
          stroke-width="2.4677"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <input
        type="text"
        placeholder="Search"
        className="flex w-full flex-col items-start gap-[29.612px] font-inter text-[14px] sm:text-[19.742px] not-italic font-medium ml-[10px] outline-none "
      />
    </div>
  )
}

export default MessagesSearchBar
